import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TYPEFORM_DOMAIN_ROOT } from '@app/web/src/helpers/consts';

import { useBanners } from '@lib/core/banners/hooks/banners';
import { selectLocale } from '@lib/core/service/selectors';
import { ExtendedAllProductCategoryCode } from '@lib/core/service/types/interface';
import { prependBasename } from '@lib/core/service/utils';
import { getQueryValue } from '@lib/tools/shared/helpers';
import {
  PRODUCT_ALL,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { PRODUCT_CATEGORY_QUERY_KEY } from '@components/web/src/foundations/consts';
import EdutainmentPage from '@components/web/src/templates/EdutainmentPages/EdutainmentPage/EdutainmentPage';

const EdutainmentContainer = () => {
  const navigate = useNavigate();
  const lang = useSelector(selectLocale);
  const { edutainmentBannersData } = useBanners();

  const [activeTab, setActiveTab] = useState<ExtendedAllProductCategoryCode>(PRODUCT_ALL);

  const handleRedirectToForm = (route, linkParams) => {
    const typeformLink = getQueryValue(linkParams, lang);
    const productCategory = getQueryValue(linkParams, PRODUCT_CATEGORY_QUERY_KEY);

    navigate(
      prependBasename(`/${route}/${productCategory}/${encodeURIComponent(`${TYPEFORM_DOMAIN_ROOT}/${typeformLink}`)}`),
    );
  };

  const filteredEdutainmentBanners = useMemo(() => {
    const productCategoriesToFilter = [PRODUCT_CATEGORY_BEER, PRODUCT_CATEGORY_COFFEE, PRODUCT_CATEGORY_WINE];

    if (productCategoriesToFilter.includes(activeTab)) {
      return edutainmentBannersData.filter(banner => {
        const productCategory = getQueryValue(banner.linkParams, PRODUCT_CATEGORY_QUERY_KEY);
        return productCategory === activeTab;
      });
    }
    return edutainmentBannersData;
  }, [activeTab, edutainmentBannersData]);

  return (
    <EdutainmentPage
      activeTab={activeTab}
      bannersList={filteredEdutainmentBanners}
      handleRedirectToForm={handleRedirectToForm}
      handleTabClick={setActiveTab}
    />
  );
};

export default EdutainmentContainer;
