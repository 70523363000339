import { FC } from 'react';

import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { MP_THIRD_PARTY_OPTION_SELECTED } from '@lib/tools/dat/mixpanel/consts';
import { TMPThirdPartyChoiceClick } from '@lib/tools/dat/mixpanel/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/ThirdPartyConsentPage/styles';

export const ThirdPartyConsentVariants = {
  Default: 'default',
  TastePath: 'tastePath',
  Train: 'train',
} as const;

export type TThirdPartyConsentVariant = (typeof ThirdPartyConsentVariants)[keyof typeof ThirdPartyConsentVariants];

interface IProps {
  thirdPartyData: IParsedServiceTerm;
  contentWrapperHeight: number;
  retailerName: string;
  variant?: TThirdPartyConsentVariant;
  handleThirdPartyClick: (optionSelected: TMPThirdPartyChoiceClick) => void;
}

export const ThirdPartyConnector: FC<IProps> = ({
  thirdPartyData,
  contentWrapperHeight,
  retailerName,
  variant = ThirdPartyConsentVariants.Default,
  handleThirdPartyClick,
}) => {
  const {
    connectorSubtitle,
    areYouReadyText,
    connectorTrainTitle,
    yesIdoBtn,
    thinkAboutItBtn,
    connectorDefaultTitle,
    noNeverBtn,
  } = localeWidget.thirdPartyConsentPage;

  const getConnectorTitle = (variantType: TThirdPartyConsentVariant) => {
    switch (variantType) {
      case ThirdPartyConsentVariants.TastePath:
        return areYouReadyText;
      case ThirdPartyConsentVariants.Train:
        return connectorTrainTitle;
      default:
        return connectorDefaultTitle;
    }
  };

  return (
    <S.ThirdPartyConnector $marginTop={contentWrapperHeight} direction="column" gap={16} padding="24px 16px 60px">
      <Text
        color={STATIC_COLORS.base.black}
        fontFamily="Fraunces"
        size="h5"
        text={getConnectorTitle(variant)}
        textAlign="center"
        weight="semibold"
      />
      <Text
        color={STATIC_COLORS.base.black}
        localeOptions={{ retailerName }}
        size="body1"
        text={connectorSubtitle}
        textAlign="center"
      />
      <Button
        fontSize="subtitle2"
        handleClick={() => handleThirdPartyClick({ optionSelected: MP_THIRD_PARTY_OPTION_SELECTED.YES })}
        size="md"
        text={yesIdoBtn}
        textWeight="medium"
      />
      <Flexbox align="center" gap={16} justify="space-evenly">
        <S.StyledLinkButton
          fontSize="body1"
          handleClick={() => handleThirdPartyClick({ optionSelected: MP_THIRD_PARTY_OPTION_SELECTED.NO })}
          size="md"
          text={noNeverBtn}
          textWeight="medium"
          variant={VH_VARIANTS.LINK}
        />
        <S.Divider />
        <S.StyledLinkButton
          fontSize="body1"
          handleClick={() => handleThirdPartyClick({ optionSelected: MP_THIRD_PARTY_OPTION_SELECTED.LATER })}
          size="md"
          text={thinkAboutItBtn}
          textWeight="medium"
          variant={VH_VARIANTS.LINK}
        />
      </Flexbox>
      <div>
        <Text color={STATIC_COLORS.base.black} size="body3" text={thirdPartyData.text} />
      </div>
    </S.ThirdPartyConnector>
  );
};
