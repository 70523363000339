import { FC } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import ProductDetails from '@components/web/src/organisms/ProductDetails/Web/ProductDetails';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

type Props = {
  tabsProps: { active: string; tabs: any[] };
  parsedProductInstanceData: TParsedProductInstance;
  isShowPromoLabel?: boolean;
  isUserHasAnyCharacter: boolean;
  shouldDisplayContactProducerBtn?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  shouldHideWishlist: boolean;
  shouldHideRatingButton: boolean;
  navigateToQuizSelectionPage: () => void;
  onBackButtonClick: () => void;
  recipesData: IRecipe[];
  isRecipesDataLoading?: boolean;
};

const AppProductPage: FC<Props> = ({
  parsedProductInstanceData,
  tabsProps,
  isShowPromoLabel = false,
  shouldDisplayContactProducerBtn,
  discoveryQuiz,
  isUserHasAnyCharacter,
  shouldHideRatingButton,
  shouldHideWishlist,
  onBackButtonClick,
  navigateToQuizSelectionPage,
  isRecipesDataLoading,
  recipesData,
}) => {
  return (
    <div className="app-product-page-container">
      <BackButton handleClick={onBackButtonClick} />
      <ProductDetails
        discoveryQuiz={discoveryQuiz}
        isRecipesDataLoading={isRecipesDataLoading}
        isShowPromoLabel={isShowPromoLabel}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        navigateToQuizSelectionPage={navigateToQuizSelectionPage}
        parsedProductInstanceData={parsedProductInstanceData}
        recipesData={recipesData}
        shouldDisplayContactProducerBtn={shouldDisplayContactProducerBtn}
        shouldHideRatingButton={shouldHideRatingButton}
        shouldHideWishlist={shouldHideWishlist}
      />
      <Tabs {...tabsProps} gradientVariant="default-down" />
    </div>
  );
};

export default AppProductPage;
