import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { currentYear } from '@lib/tools/shared/helpers/utils';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as GearIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_green50_gear.svg';
import * as S from '@components/web/src/atoms/Footers/FooterAppAnonymous/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IFooterLink {
  id: string;
  content: string | ReactNode;
  onClick: () => void;
}

interface IProps {
  showGearIcon?: boolean;
}

export const FooterAppAnonymous: FC<IProps> = ({ showGearIcon = true }) => {
  const navigate = useNavigate();

  const { termsAndConditionsTitle, privacyPolicyTitle, cookiePolicyTitle, copyrightText } = localeCommon.commonMessages;

  const footerLinks: IFooterLink[] = [
    {
      content: <Text color={STATIC_COLORS.green[50]} size="body1" text={termsAndConditionsTitle} textAlign="center" />,
      id: '1',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.termsConditions)),
    },
    {
      content: <Text color={STATIC_COLORS.green[50]} size="body1" text={privacyPolicyTitle} textAlign="center" />,
      id: '2',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.privacy)),
    },
    {
      content: <Text color={STATIC_COLORS.green[50]} size="body1" text={cookiePolicyTitle} textAlign="center" />,
      id: '3',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.cookies)),
    },
    {
      content: <GearIcon />,
      id: '4',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.generalServiceTerms)),
    },
  ];

  if (!showGearIcon) delete footerLinks[3];

  return (
    <S.FooterAppAnonymousContainer>
      <S.WavyImageContainer>
        <S.WavyImageElement />
      </S.WavyImageContainer>
      <S.FooterContent isFullWidth direction="column" gap={20} justify="center" padding="16px 24px 36px">
        <Text color={STATIC_COLORS.green[50]} localeOptions={{ currentYear }} text={copyrightText} textAlign="center" />
        <Flexbox align="center" gap={0} justify="space-between">
          {footerLinks.map(contentItem => (
            <S.LinkCta key={contentItem.id} onClick={contentItem.onClick}>
              {contentItem.content}
            </S.LinkCta>
          ))}
        </Flexbox>
      </S.FooterContent>
    </S.FooterAppAnonymousContainer>
  );
};
