import styled from 'styled-components';

import Btn from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ExperienceCardContainer = styled(Flexbox)<{ $isResponsive: boolean }>`
  width: ${({ $isResponsive }) => ($isResponsive ? '100%' : '343px')};
  height: 100%;
  overflow: hidden;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 180px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledProductName = styled(Text)`
  min-height: 60px;
  max-height: 60px;
`;

export const PriceRangeWrapper = styled.div`
  margin-top: 4px;
`;

export const Button = styled(Btn)`
  width: 100%;
  margin-top: auto;

  span {
    text-decoration: underline;
  }
`;
