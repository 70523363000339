import styled from 'styled-components';

export const ModalWrapper = styled.div<{ $width?: string; $height?: string }>`
  width: ${({ $width = '100%' }) => $width};
  height: ${({ $height = '100%' }) => $height};
  z-index: 1000;
  position: absolute;
`;

export const ModalContent = styled.div<{ $transparent?: boolean }>`
  background: ${({ $transparent }) => ($transparent ? '' : 'white')};
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
