import styled from 'styled-components';

import { TShareIconSizes } from '@components/web/src/atoms/Share/Share';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const shareIconSizeMap: Record<TShareIconSizes, number> = {
  large: 44,
  medium: 32,
  small: 24,
};

export const ShareContainer = styled(Flexbox)`
  width: 48px;
  height: 48px;
`;

export const SvgIcon = styled.svg<{ $size: TShareIconSizes }>`
  width: ${({ $size }) => shareIconSizeMap[$size]}px;
  height: ${({ $size }) => shareIconSizeMap[$size]}px;
`;
