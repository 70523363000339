/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectWishlistProductInstanceIds } from '@lib/core/users/selectors/productList';
import { selectProductRateData } from '@lib/core/users/selectors/productRate';
import {
  selectAppMaxPriceRangeValue,
  selectAppMinPriceRangeValue,
  selectIsFilterOpened,
  selectLastPriceRangeChangeWasManual,
  selectOrdering,
  selectSearchTextValue,
  selectShowOnlyIsWishlistToggleActive,
  selectSublistCharacteristics,
  selectSublistFormats,
  selectSublistMood,
  selectSublistOrigin,
  selectSublistProductCategory,
  selectSublistRating,
  selectSublistStyle,
  selectToggleIsCharacterToggleActive,
  selectToggleIsPromotionToggleActive,
  selectUserLowerPriceRangeValue,
  selectUserUpperPriceRangeValue,
} from '@lib/tools/filterManager/selectors';
import { TProductCatalogOrdering, actionApplyCatalogFilter } from '@lib/tools/filterManager/slices/productFilter';
import {
  ISetPriceRangeValues,
  resetPriceRange,
  setPriceRangeValues,
} from '@lib/tools/filterManager/slices/rangeFilterSlice';
import { changeSearchText } from '@lib/tools/filterManager/slices/searchTextFilterSlice';
import { updateShowOnlyFilterValue } from '@lib/tools/filterManager/slices/showOnlyFilterSlice';
import { IFilterChangeAction, updateFilterType } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import {
  updateCharactersToggleActive,
  updatePromotionToggleActive,
} from '@lib/tools/filterManager/slices/toggleFilterSlice';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ITEM_NAME,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORDERING,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE_MAX,
  FILTER_TYPE_PRICE_RANGE_MIN,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_PROMOTION_TOGGLE,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  FILTER_TYPE_WISHLIST,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

interface ICatalogFilter {
  [FILTER_TYPE_CHARACTERISTICS]: string;
  [FILTER_TYPE_FORMAT]: string;
  [FILTER_TYPE_MOOD]: string;
  [FILTER_TYPE_ORIGIN]: string;
  [FILTER_TYPE_PRODUCT_CATEGORY]: string;
  [FILTER_TYPE_SHOW_FAVORITES]: string;
  [FILTER_TYPE_STYLE]: string;
  [FILTER_TYPE_ITEM_NAME]?: string;
  [FILTER_TYPE_CHARACTER_TOGGLE]?: boolean;
  [FILTER_TYPE_PRICE_RANGE_MIN]?: number;
  [FILTER_TYPE_PRICE_RANGE_MAX]?: number;
  [FILTER_TYPE_ORDERING]: TProductCatalogOrdering;
  [FILTER_TYPE_PROMOTION_TOGGLE]?: boolean;
  [FILTER_TYPE_RATING]?: string;
  isFilterOpened: boolean;
  lastPriceRangeChangeWasManual: boolean;
  appMinPriceRangeValue: number;
  appMaxPriceRangeValue: number;
  userLowerPriceRangeValue: number;
  userUpperPriceRangeValue: number;
  handleUpdatePromotionToggleActive: (value: boolean) => void;
  handleUpdateCharactersToggleActive: (value: boolean) => void;
  handleUpdateShowOnlyFilterValue: (filterType: typeof FILTER_TYPE_WISHLIST) => void;
  handleChangeSearchText: (value: string) => void;
  handleSetPriceRangeValues: (value: ISetPriceRangeValues) => void;
  handleUpdateFilterType: (filterItem: IFilterChangeAction) => void;
  handleResetPriceRange: () => void;
  handleActionApplyCatalogFilter: () => void;
}

const useCatalogFilter = ({ activeJournalPanelFilter = '' } = {}): ICatalogFilter => {
  const { pathname, state: locationState } = useLocation();

  const from = locationState?.from || '';
  // ToDo [kukharets] check this variable flow isDifferentTypeCatalog
  const isDifferentTypeCatalog = from && from !== pathname;

  const dispatch = useDispatch();

  const wishlistProductInstanceIds = useTypedSelector(selectWishlistProductInstanceIds);
  const feedbackData = useTypedSelector(selectProductRateData);
  const selectedStylesFilterObject = useTypedSelector(selectSublistStyle);
  const selectedFormatsFilterObject = useTypedSelector(selectSublistFormats);
  const selectedCharacteristicsFilterObject = useTypedSelector(selectSublistCharacteristics);
  const selectedOriginsFilterObject = useTypedSelector(selectSublistOrigin);
  const selectedMoodFilterObject = useTypedSelector(selectSublistMood);
  const selectedRatingFilterObject = useTypedSelector(selectSublistRating);
  const selectedProductCategoriesFilterObject = useTypedSelector(selectSublistProductCategory);
  const isFilterOpened = useTypedSelector(selectIsFilterOpened);
  const ordering = useTypedSelector(selectOrdering);
  const searchTextValue = useTypedSelector(selectSearchTextValue);
  const isWishlistToggleActive = useTypedSelector(selectShowOnlyIsWishlistToggleActive);
  const userLowerPriceRangeValue = useTypedSelector(selectUserLowerPriceRangeValue);
  const userUpperPriceRangeValue = useTypedSelector(selectUserUpperPriceRangeValue);
  const appMinPriceRangeValue = useTypedSelector(selectAppMinPriceRangeValue);
  const appMaxPriceRangeValue = useTypedSelector(selectAppMaxPriceRangeValue);
  const lastPriceRangeChangeWasManual = useTypedSelector(selectLastPriceRangeChangeWasManual);
  const isCharacterToggleActive = useTypedSelector(selectToggleIsCharacterToggleActive);
  const isPromotionToggleActive = useTypedSelector(selectToggleIsPromotionToggleActive);

  const selectedOriginIdsQuery = useMemo(
    () =>
      Object.values(selectedOriginsFilterObject)
        .filter(({ isActive }) => isActive)
        .map(({ value }) => value)
        .join(','),
    [selectedOriginsFilterObject],
  );

  const selectedCharacteristicIdsQuery = useMemo(
    () =>
      Object.values(selectedCharacteristicsFilterObject)
        .filter(({ isActive }) => isActive)
        .map(({ value }) => value)
        .join(','),
    [selectedCharacteristicsFilterObject],
  );

  const selectedStyleIdsQuery = useMemo(
    () =>
      Object.values(selectedStylesFilterObject)
        .filter(({ isActive }) => isActive)
        .map(({ value }) => value)
        .join(','),
    [selectedStylesFilterObject],
  );

  const selectedFormatIdsQuery = useMemo(
    () =>
      Object.values(selectedFormatsFilterObject)
        .filter(({ isActive }) => isActive)
        .map(({ value }) => value)
        .join(','),
    [selectedFormatsFilterObject],
  );

  const selectedMoodIdsQuery = useMemo(
    () =>
      Object.values(selectedMoodFilterObject)
        .filter(({ isActive }) => isActive)
        .map(({ value }) => value)
        .join(','),
    [selectedMoodFilterObject],
  );

  const selectedProductCategoryIdsQuery = useMemo(
    () =>
      Object.values(selectedProductCategoriesFilterObject)
        .filter(({ isActive }) => isActive)
        .map(({ value }) => value)
        .join(','),
    [selectedProductCategoriesFilterObject],
  );

  const selectedWishlistTypesQuery = useMemo(() => {
    if (!isWishlistToggleActive && !activeJournalPanelFilter) return '';

    const paramsText = wishlistProductInstanceIds.map(identifier => `%${identifier}`).join('');

    return paramsText || 'hintForEmptyList';
  }, [isWishlistToggleActive, wishlistProductInstanceIds]);

  const selectedRatedProductsQuery = useMemo(() => {
    const ratingValues = Object.values(selectedRatingFilterObject)
      .filter(rating => rating.isActive)
      .map(item => Number(item.value));

    if ((!ratingValues.length && !activeJournalPanelFilter) || !feedbackData.length) return '';
    const paramsText = feedbackData
      .filter(feedback => ratingValues.includes(feedback.rate.rateValue) || activeJournalPanelFilter)
      .map(item => `%${item.product.product.identifier}`)
      .join('');

    return paramsText || 'hintForEmptyList';
  }, [feedbackData, selectedRatingFilterObject, activeJournalPanelFilter]);

  const handleUpdateCharactersToggleActive = (value: boolean) => {
    dispatch(updateCharactersToggleActive({ value }));
  };

  const handleUpdatePromotionToggleActive = (value: boolean) => {
    dispatch(updatePromotionToggleActive({ value }));
  };

  const handleUpdateShowOnlyFilterValue = (filterType: typeof FILTER_TYPE_WISHLIST) => {
    dispatch(updateShowOnlyFilterValue(filterType));
  };

  const handleChangeSearchText = (value: string) => {
    dispatch(changeSearchText(value));
  };

  const handleSetPriceRangeValues = (value: ISetPriceRangeValues) => {
    dispatch(setPriceRangeValues(value));
  };

  const handleUpdateFilterType = (filterItem: IFilterChangeAction) => {
    dispatch(updateFilterType(filterItem));
  };

  const handleResetPriceRange = () => {
    dispatch(resetPriceRange());
  };

  const handleActionApplyCatalogFilter = () => {
    dispatch(actionApplyCatalogFilter());
  };

  const filterData = {
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicIdsQuery,
    [FILTER_TYPE_CHARACTER_TOGGLE]: isCharacterToggleActive,
    [FILTER_TYPE_FORMAT]: selectedFormatIdsQuery,
    [FILTER_TYPE_ITEM_NAME]: searchTextValue,
    [FILTER_TYPE_MOOD]: selectedMoodIdsQuery,
    [FILTER_TYPE_ORDERING]: ordering as TProductCatalogOrdering,
    [FILTER_TYPE_ORIGIN]: selectedOriginIdsQuery,
    [FILTER_TYPE_RATING]: selectedRatedProductsQuery,
    [FILTER_TYPE_PRODUCT_CATEGORY]: selectedProductCategoryIdsQuery,
    [FILTER_TYPE_PROMOTION_TOGGLE]: isPromotionToggleActive,
    [FILTER_TYPE_SHOW_FAVORITES]: selectedWishlistTypesQuery,
    [FILTER_TYPE_STYLE]: selectedStyleIdsQuery,
    lastPriceRangeChangeWasManual,
    isFilterOpened,
    appMaxPriceRangeValue,
    appMinPriceRangeValue,
    userLowerPriceRangeValue,
    userUpperPriceRangeValue,
    handleUpdateCharactersToggleActive,
    handleUpdatePromotionToggleActive,
    handleUpdateShowOnlyFilterValue,
    handleChangeSearchText,
    handleSetPriceRangeValues,
    handleUpdateFilterType,
    handleResetPriceRange,
    handleActionApplyCatalogFilter,
  };

  if (userUpperPriceRangeValue) {
    filterData[FILTER_TYPE_PRICE_RANGE_MIN] = isDifferentTypeCatalog ? -1 : userLowerPriceRangeValue - 1;
    filterData[FILTER_TYPE_PRICE_RANGE_MAX] = userUpperPriceRangeValue + 1;
  }
  return filterData;
};

export { useCatalogFilter };
