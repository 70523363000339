import React from 'react';

import { isApplicationKiosk } from '@lib/core/service/consts';
import { isAppInIframe } from '@lib/core/service/utils';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const RootElementContainer = ({ children, className }: IProps) => (
  <div className={className} id="root-element">
    {children}
  </div>
);

const RootElement = ({ children }: IProps) => {
  if (isApplicationKiosk) {
    return <RootElementContainer className="root-element-kiosk">{children}</RootElementContainer>;
  }
  if (isAppInIframe) {
    return <RootElementContainer className="root-element-frame">{children}</RootElementContainer>;
  }
  return (
    <RootElementContainer className="root-element-experience">
      <div className="iframe-imitation-overlay" />
      <div className="iframe-imitation" id="iframe-imitation-root">
        {children}
      </div>
    </RootElementContainer>
  );
};

export default RootElement;
