import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { ReactComponent as ArrowIcon } from '@components/web/src/assets/legacy/b2c/icons/bannerdropdownwhite.svg';
import UserCharacterInfoItem from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterInfoItemSection/UserCharacterInfoItem';

type Props = {
  userBestMatchCharacterByProductCategory: Record<TProductCategory, IParsedCharacter>;
  onClick: () => void;
};

const UserCharacterInfoItemSection: FC<Props> = ({ userBestMatchCharacterByProductCategory, onClick }) => {
  return (
    <div aria-hidden className="user-character-info-item-section-container" onClick={onClick}>
      <div className="characters-container">
        <UserCharacterInfoItem
          characterData={userBestMatchCharacterByProductCategory?.wine}
          productCategory={PRODUCT_CATEGORY_WINE}
        />
        <UserCharacterInfoItem
          characterData={userBestMatchCharacterByProductCategory?.beer}
          productCategory={PRODUCT_CATEGORY_BEER}
        />
        <UserCharacterInfoItem
          characterData={userBestMatchCharacterByProductCategory?.coffee}
          productCategory={PRODUCT_CATEGORY_COFFEE}
        />
      </div>
      <div>
        <ArrowIcon />
      </div>
    </div>
  );
};

export default UserCharacterInfoItemSection;
