import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';

import { ICharacterAdjectiveItemData } from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import { TSwiperData } from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/ExtraInfoSwiper';
import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import CharacterAdjectivesGroup from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import CharacterInfoGroup from '@components/web/src/organisms/CharacterInfoGroup/CharacterInfoGroup';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/styles';

interface IProps {
  characterAdjectiveItemsData: ICharacterAdjectiveItemData[];
  characterAdjectiveModalData: TSwiperData[];
  characterData: IParsedCharacter;
  characterDetailCardsData: IReadMoreCardProps[];
  characterInfoCardsData: ICharacterInfoCardProps[];
  isUserAuthenticated: boolean;
  productCategory: string;
}

export const CharacterDetailsCommon: FC<IProps> = ({
  characterAdjectiveItemsData,
  characterAdjectiveModalData,
  characterData,
  characterDetailCardsData,
  characterInfoCardsData,
  isUserAuthenticated,
  productCategory,
}) => {
  return (
    <>
      <CharacterAdjectivesGroup
        characterAdjectiveItemsData={characterAdjectiveItemsData}
        characterAdjectiveModalData={characterAdjectiveModalData}
        characterData={characterData}
        isUserAuthenticated={isUserAuthenticated}
        productCategory={productCategory}
      />
      <S.CharacterDetailsSection>
        {characterDetailCardsData.map(({ description, cardTitle, title, minimumCharacters }, idx) => (
          <ReadMoreCard
            key={idx}
            cardTitle={cardTitle}
            cardTitleLocaleIndex={{ productCategory }}
            description={description}
            minimumCharacters={minimumCharacters}
            productCategory={productCategory}
            title={title}
          />
        ))}
      </S.CharacterDetailsSection>
      <CharacterInfoGroup characterInfoList={characterInfoCardsData} />
    </>
  );
};
