import styled from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const DetachFidelityCardButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px 14px;
  color: ${STATIC_COLORS.base.white};
  background: ${STATIC_GRADIENTS.primaryGradient};
  border: none;
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.xs};
`;
