import { FC } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { useUser } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import noteIcon from '@components/web/src/assets/icons/comment/NoteIcon.svg';
import noteIconFill from '@components/web/src/assets/icons/comment/NoteIconFill.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Comment/styles';
import CommentModal from '@components/web/src/templates/Modals/CommentModal/CommentModal';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  isFromSharedLink?: boolean;
}

export const CommentForProductPage: FC<IProps> = ({ parsedProductInstanceData, isFromSharedLink }) => {
  const { userFirstName } = useUser();
  const [isCommentModalOpen, { open: openModal, close: closeModal }] = useDisclosure();

  const { commentFromList, isCommentInList } = parsedProductInstanceData;

  const { btnTextNoComment, btnTextWithComment, myPersonalNote, userNote } = localeCommon.comment;

  return (
    <>
      <S.CommentContainer
        isFullWidth
        direction="column"
        gap={8}
        padding="12px"
        onClick={() => (!isFromSharedLink ? openModal() : null)}
      >
        <S.TitleWrapper>
          <Image alt="Comment icon" height="20px" src={isCommentInList ? noteIconFill : noteIcon} width="25px" />
          {isFromSharedLink ? (
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="subtitle2"
              text={userNote}
              weight="semibold"
              localeOptions={{
                userFirstName,
              }}
            />
          ) : (
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="subtitle2"
              text={myPersonalNote}
              weight="semibold"
            />
          )}
        </S.TitleWrapper>
        {commentFromList && (
          <Text color={STATIC_COLORS.base.black} linesLimit={4} size="body2" text={commentFromList?.comment} />
        )}
        {!isFromSharedLink && (
          <Flexbox isFullWidth justify="flex-end">
            <Text
              color={STATIC_COLORS.teal[600]}
              size="body2"
              text={isCommentInList ? btnTextWithComment : btnTextNoComment}
              textDecoration="underline"
              weight="semibold"
              onClick={openModal}
            />
          </Flexbox>
        )}
      </S.CommentContainer>

      {isCommentModalOpen && (
        <CommentModal
          isCommentModalOpen={isCommentModalOpen}
          parsedProductInstanceData={parsedProductInstanceData}
          onClose={closeModal}
        />
      )}
    </>
  );
};
