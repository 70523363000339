import { TokenResponse } from '@react-oauth/google';
import { FC } from 'react';
import AppleLogin, { AppleLoginProps } from 'react-apple-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { ENV_APPLE_APP_ID, ENV_APPLE_REDIRECT_URL, ENV_FACEBOOK_APP_ID } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { APPLE, EMAIL, FACEBOOK } from '@lib/tools/shared/helpers/consts';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

import AuthorizationButton from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import GoogleLoginButton from '@components/web/src/organisms/Authentication/GoogleLoginButton/GoogleLoginButton';
import * as S from '@components/web/src/organisms/Authentication/SocialConnector/styles';

interface IProps {
  isEmailAuthorization: boolean;
  isRegistrationPage?: boolean;
  isSocialAuthTesting?: boolean;
  isAutoSocialLoginPageVariant?: boolean;
  authErrors?: string;
  handleEmailAuthButtonClick: () => void;
  responseFacebook: (response: ISocialLoginFacebook) => void;
  responseApple: (response: AppleLoginProps) => void;
  responseGoogle: (response: TokenResponse) => void;
}

const SocialConnector: FC<IProps> = ({
  isEmailAuthorization,
  isRegistrationPage = false,
  authErrors,
  isSocialAuthTesting,
  responseFacebook,
  responseApple,
  responseGoogle,
  handleEmailAuthButtonClick,
}) => {
  const { authOptions } = localeCommon;
  const hideFacebookLogin = true;

  return (
    <S.AuthorizationGroup>
      <Flexbox direction="column" gap={4}>
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h6"
          text={authOptions.title}
          weight="semibold"
        />
        <Text color={STATIC_COLORS.base.black} size="body1" text={authOptions.description} weight="medium" />
      </Flexbox>
      <S.GroupWrapper $errorType={authErrors}>
        <GoogleLoginButton
          authOptions={authOptions}
          isDisabled={isEmailAuthorization}
          isHidden={isSocialAuthTesting}
          isRegistrationPage={isRegistrationPage}
          responseGoogle={responseGoogle}
        />

        {!hideFacebookLogin && (
          <FacebookLogin
            disableMobileRedirect
            appId={ENV_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={responseFacebook}
            data-use-continue-as="true"
            fields="name,email,picture"
            render={({ onClick }) => (
              <AuthorizationButton
                key={FACEBOOK}
                handleClick={onClick}
                isDisabled={isEmailAuthorization}
                text={isRegistrationPage ? authOptions.facebookSignup : authOptions.facebookLogIn}
                type={FACEBOOK}
                variant="secondary"
              />
            )}
          />
        )}

        <AppleLogin
          callback={responseApple}
          clientId={ENV_APPLE_APP_ID}
          redirectURI={ENV_APPLE_REDIRECT_URL}
          responseMode="query"
          scope="email"
          state="state"
          render={({ onClick }) => (
            <AuthorizationButton
              key={APPLE}
              handleClick={onClick}
              isHidden={!isSocialAuthTesting}
              type={APPLE}
              variant="secondary"
            />
          )}
        />
        <AuthorizationButton
          key={EMAIL}
          handleClick={handleEmailAuthButtonClick}
          text={isRegistrationPage ? authOptions.emailSignup : authOptions.emailLogIn}
          type={EMAIL}
          variant="secondary"
        />
      </S.GroupWrapper>
      {authErrors && <Text color={STATIC_COLORS.error[600]} size="body1" text={authErrors} />}
    </S.AuthorizationGroup>
  );
};

export default SocialConnector;
