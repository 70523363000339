import styled from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const CardFooterContainer = styled(Flexbox)`
  position: relative;
  min-height: 32px;
  background: ${STATIC_GRADIENTS.warmGradient};

  & > div {
    cursor: pointer;
  }
`;

export const CardFooterWrapper = styled(Flexbox)`
  position: relative;
`;

export const TagsTooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TagsToolTip = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50%;
  z-index: 100;
  padding: 8px 12px;
  white-space: nowrap;
  background: ${STATIC_COLORS.warmGray[100]};
  border-radius: 8px;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
  transform: translateX(-90%);
  // for triangle
  &::before {
    position: absolute;
    top: 100%;
    right: 0;
    left: 90%;
    width: 0;
    height: 0;
    margin-left: -6px;
    pointer-events: none;
    content: ' ';
    border: solid transparent;
    border-width: 6px;
    border-top-color: $vh-warm-gray-100;
    box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
  }
`;

export const ToolTipContent = styled.div`
  display: flex;
  flex-direction: column;
`;
