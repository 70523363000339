import { FC, Fragment, ReactNode } from 'react';

import { TBannerLink, TBannerPosition, TBannerTheme } from '@lib/core/banners/types';
import { useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { BANNER_ICONS } from '@lib/tools/shared/helpers/consts';
import { useDisclosure } from '@lib/tools/views/hooks';

import { ReactComponent as GreenArrowIcon } from '@components/web/src/assets/icons/banner_card_arrow.svg';
import { ReactComponent as WhiteArrowIcon } from '@components/web/src/assets/legacy/b2c/icons/bannerdropdownwhite.svg';
import * as S from '@components/web/src/atoms/Banner/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { HINT_TEXT } from '@components/web/src/foundations/consts';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import HintModal from '@components/web/src/templates/Modals/HintModal/HintModal';

export type IBannerIconType = keyof typeof BANNER_ICONS;

export type IBannerRightIconVariantType = 'whitearrow' | 'greenarrow' | 'none';

export type IBannerBackgroundType = 'white' | 'red' | 'green' | 'teal';

type RightIconVariants = Record<IBannerRightIconVariantType, JSX.Element | null>;

export type TBannerCard = {
  identifier?: string;
  onClickHandler?: (url?: string, linkParams?: string) => void;
  bgColor?: IBannerBackgroundType;
  className?: string;
  rightIcon?: IBannerRightIconVariantType;
  theme?: TBannerTheme;
  link?: TBannerLink;
  positions?: TBannerPosition[];
  currentPosition?: TBannerPosition;
  iconType?: IBannerIconType;
  image?: string;
  text?: string;
  linkParams?: string;
  disabled?: boolean;
  jsxText?: () => ReactNode;
};

// here Image link is used static to show on the Banner
/**
 * @todo Image static link must be removed later after api integration everywhere of banners
 */
export const BannerCard: FC<TBannerCard> = ({
  identifier,
  text,
  onClickHandler,
  bgColor,
  className,
  theme = 'lit',
  iconType,
  image,
  link,
  currentPosition = {},
  linkParams,
  disabled,
  jsxText,
}) => {
  const rightIcons: RightIconVariants = {
    greenarrow: <GreenArrowIcon />,
    none: null,
    whitearrow: <WhiteArrowIcon />,
  };
  const { isUserHasAnyCharacter, userBestMatchCharacterByProductCategory } = useUser();
  const [isHintModalOpen, { open: openHintModal, close: closeHintModal }] = useDisclosure();

  const renderedRightIcon = theme === 'drk' ? rightIcons.whitearrow : rightIcons.greenarrow;

  // As background color also may be 'red'
  const useBgColor = bgColor || (theme === 'drk' ? 'green' : 'white');

  const handleOpenHintModal = () => {
    openHintModal();
  };

  const handleBannerClick = () => {
    const { route } = link || {};
    if (route) {
      if (route === HINT_TEXT) {
        handleOpenHintModal();
      } else if (onClickHandler && route !== HINT_TEXT) {
        onClickHandler(link?.route, linkParams);
      }
    } else if (onClickHandler) {
      onClickHandler();
    }
    MixpanelTracker.events.bannerClick(identifier, text, link, currentPosition);
  };

  const imageUrl = iconType ? `https://media-staging.vinhood.com/media/banner/${BANNER_ICONS[iconType]}.svg` : image;

  return (
    <Fragment>
      <S.BannerCardContainer
        aria-hidden
        isFullWidth
        $bgColor={useBgColor}
        $disabled={disabled}
        align="center"
        className={className}
        gap={0}
        justify="space-between"
        padding="8px 16px"
        role="button"
        tabIndex={0}
        onClick={handleBannerClick}
      >
        {imageUrl && (
          <S.IconBlock>
            <Image alt="Banner Icon" height="24px" objectFit="contain" src={imageUrl} width="24px" />
          </S.IconBlock>
        )}
        {!!text && (
          <S.BannerCardTextWrapper>
            <Text
              color={useBgColor === 'white' ? STATIC_COLORS.base.black : STATIC_COLORS.base.white}
              size="body1"
              text={text}
              weight="semibold"
            />
          </S.BannerCardTextWrapper>
        )}
        {!!jsxText && jsxText()}
        <S.IconBlock>{renderedRightIcon}</S.IconBlock>
      </S.BannerCardContainer>
      {isHintModalOpen && (
        <HintModal
          bannerText={text}
          hideModal={closeHintModal}
          image={imageUrl}
          isModalOpen={isHintModalOpen}
          isUserHasAnyCharacter={isUserHasAnyCharacter}
          params={linkParams}
          userBestMatchCharacterByProductCategory={userBestMatchCharacterByProductCategory}
        />
      )}
    </Fragment>
  );
};
