import React, { FC, useState } from 'react';

import {
  IActionToggleOpenedFeedbackModalProductProps,
  IProductFeedbackLoaded,
} from '@lib/core/products/slices/productFeedback';
import { TParsedProductInstance } from '@lib/core/products/types';
import { useRetailer } from '@lib/core/retailers/hooks';
import { useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { ReactComponent as ArrowIcon } from '@components/web/src/assets/icons/training/icon-arrow.svg';
import checkIcon from '@components/web/src/assets/icons/training/icon-check.svg';
import { RatingButton } from '@components/web/src/atoms/ProductRating/ProductRating/RatingButton';
import { Share } from '@components/web/src/atoms/Share/Share';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { CommentForProductPage } from '@components/web/src/organisms/Comment/CommentForProductPage';
import { ProductFeedbackAnswerCard } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackAnswerCard/ProductFeedbackAnswerCard';
import { ProductFeedbackDoneProductSection } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackDoneProductSection/ProductFeedbackDoneProductSection';
import { ProducerFeedbackModal } from '@components/web/src/templates/Modals/ProducerFeedbackModal/ProducerFeedbackModal';
import * as S from '@components/web/src/templates/ProductFeedback/ProductFeedbackResultPage/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  answers: IProductFeedbackLoaded[];
  handleGoBack: (args?: IActionToggleOpenedFeedbackModalProductProps) => void;
  isFromSharedLink?: boolean;
  retailerLocationName: string;
}

export const ProductFeedbackResultPage: FC<IProps> = ({
  parsedProductInstanceData,
  answers,
  handleGoBack,
  isFromSharedLink,
  retailerLocationName,
}) => {
  const [isProducerFeedbackModalOpened, setIsProducerFeedbackModalOpened] = useState(false);
  const handleToggleModal = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsProducerFeedbackModalOpened(!isProducerFeedbackModalOpened);
  };

  const { userFirstName } = useUser();
  const { retailerTags } = useRetailer();

  const { titleText, btnText, sectionTitleText, sectionUserTitleText } = localeWidget.trainingResultPage;

  const {
    productName,
    productId,
    productImage,
    productCharacterName,
    productCharacterTechnicalCaption,
    productRate,
    productCategory,
    isProductInstanceInWishlist,
    isWishlistProductListLoading,
    handleUpdateWishlistProductList,
  } = parsedProductInstanceData;

  return (
    <S.ProductFeedbackResultPageContainer isFullWidth direction="column" gap={0}>
      <S.StyledCloseButton handleClick={handleGoBack} type="bold" variant="light" />
      <S.ProductContentWrapper $productCategory={productCategory} direction="column" gap={8} padding="16px">
        <Flexbox align="flex-start" gap={8}>
          <S.CheckIcon alt="check icon" src={checkIcon} />
          <Flexbox direction="column" gap={0}>
            <Text color={STATIC_COLORS.base.white} size="body2" text={titleText} weight="bold" />
            <Text
              color={STATIC_COLORS.base.white}
              fontFamily="Fraunces"
              linesLimit={2}
              size="h5"
              text={productName}
              weight="bold"
            />
          </Flexbox>
        </Flexbox>
        <Flexbox gap={8}>
          <ProductFeedbackDoneProductSection image={productImage} variant="sm" />
          <Flexbox direction="column" gap={8} justify="space-between" padding="15px 0 5px">
            <Flexbox direction="column">
              <Text color={STATIC_COLORS.base.white} size="body2" text={retailerLocationName} weight="bold" />
              <Text
                color={STATIC_COLORS.base.white}
                fontStyle="italic"
                size="body2"
                text={`**#${productCharacterName}** - ${productCharacterTechnicalCaption}`}
              />
            </Flexbox>
            <Flexbox align="center" gap={11} justify="space-between">
              {(!isFromSharedLink || (isFromSharedLink && productRate)) && (
                <RatingButton
                  discoveryQuiz={undefined}
                  parsedProductInstanceData={parsedProductInstanceData}
                  variant="light"
                />
              )}
              {!isFromSharedLink && (
                <Wishlist
                  isItemInWishlist={isProductInstanceInWishlist}
                  isItemListLoading={isWishlistProductListLoading}
                  variant="light"
                  handleUpdateWishlist={() => {
                    handleUpdateWishlistProductList(productId, productName);
                    MixpanelTracker.events.productBookmark(
                      parsedProductInstanceData,
                      null,
                      MP_POSITION_CONTEXT.TEST_RESULT_PAGE,
                    );
                  }}
                />
              )}
              <Share
                isFromSharedLink={isFromSharedLink}
                mixpanelPositionContext={MP_POSITION_CONTEXT.PRODUCT_FEEDBACK_RESULT_PAGE}
                parsedProductInstanceData={parsedProductInstanceData}
                variant="light"
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </S.ProductContentWrapper>
      <Flexbox direction="column" gap={24} padding="34px 16px 30px">
        <CommentForProductPage
          isFromSharedLink={isFromSharedLink}
          parsedProductInstanceData={parsedProductInstanceData}
        />
        {isProducerFeedbackModalOpened && (
          <ProducerFeedbackModal
            isModalOpened
            handleCloseModal={handleToggleModal}
            parsedProductInstanceData={parsedProductInstanceData}
            retailerLogo={retailerTags?.headerLogo}
          />
        )}

        {isFromSharedLink ? (
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h6"
            text={sectionUserTitleText}
            weight="semibold"
            localeOptions={{
              userFirstName,
            }}
          />
        ) : (
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h6"
            text={sectionTitleText}
            weight="semibold"
          />
        )}
        <S.VisualAnalysisWrapper direction="column" gap={16} padding="16px">
          {answers?.map((answer, idx) => {
            const isShowDivider = idx !== answers.length - 1;
            return (
              <Flexbox key={idx} direction="column" gap={16}>
                <Text color={STATIC_COLORS.base.black} size="body1" text={answer.question.title} weight="bold" />
                <ProductFeedbackAnswerCard key={answer.answer.name} answer={answer.answer} />
                {isShowDivider && <S.Divider />}
              </Flexbox>
            );
          })}
        </S.VisualAnalysisWrapper>
        {!isFromSharedLink && (
          <S.CommentButton
            isFullWidth
            handleClick={handleToggleModal}
            icon={<ArrowIcon />}
            iconPosition="right"
            iconVariant="arrow"
            size="sm"
            text={btnText}
            textDecoration="underline"
            textWeight="medium"
          />
        )}
      </Flexbox>
    </S.ProductFeedbackResultPageContainer>
  );
};
