import { FC } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { useProductRate } from '@lib/core/users/hooks/useProductRate';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { ProductRateListItem } from '@components/web/src/atoms/ProductRating/ProductRatingListItem/ProductRateListItem';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import * as S from '@components/web/src/templates/Modals/ProductRateModal/styles';

interface IProps {
  isModalOpen: boolean;
  parsedProductInstanceData: TParsedProductInstance;
  hideModal: () => void;
  handleOpenDiscoveryQuizModal: () => void;
}

export const ProductRateModal: FC<IProps> = ({
  isModalOpen,
  parsedProductInstanceData,
  hideModal,
  handleOpenDiscoveryQuizModal,
}) => {
  const { productRate, handleDiscoveryQuizProducts, productName, productProducerName } = parsedProductInstanceData;
  const { ratingModal } = localeCommon;
  const { titleText, descriptionText } = ratingModal;
  const { productRateOptions, handleUpdateProductRating } = useProductRate();
  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="feedback-modal-wrapper">
      <S.FeedbackModalContainer>
        <CloseButton handleClick={hideModal} />
        <S.ProductRateModalContentWrapper direction="column" gap={16}>
          <S.StyledTitleWrapper direction="column" gap={0}>
            {productName && <Text fontFamily="Fraunces" size="h6" text={productName} weight="semibold" />}
            {productProducerName && <Text size="body2" text={productProducerName} weight="semibold" />}
          </S.StyledTitleWrapper>
          <Flexbox direction="column" gap={16}>
            <Flexbox direction="column" gap={0}>
              <Text color={STATIC_COLORS.green[600]} size="body1" text={titleText} weight="bold" />
              <Text size="body3" text={descriptionText} weight="medium" />
            </Flexbox>
            <Flexbox direction="column" gap={8}>
              {productRateOptions.map((data, index) => (
                <ProductRateListItem
                  key={index}
                  isActive={data.rateValue === productRate?.rateValue}
                  productRate={data.rateValue}
                  onHandleClick={async () => {
                    await handleUpdateProductRating({
                      productId: parsedProductInstanceData.productId,
                      productName: parsedProductInstanceData.productName,
                      productRate: data.rateValue,
                      slug: data.slug,
                    });

                    if (!productRate) {
                      handleDiscoveryQuizProducts();
                      handleOpenDiscoveryQuizModal();
                    }
                    MixpanelTracker.events.productFeedback(parsedProductInstanceData, data.rateValue);

                    hideModal();
                  }}
                />
              ))}
            </Flexbox>
          </Flexbox>
        </S.ProductRateModalContentWrapper>
      </S.FeedbackModalContainer>
    </ModalWrapper>
  );
};
