import { FC, useMemo } from 'react';
import { ZodType, z } from 'zod';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import AuthHeader from '@components/web/src/organisms/Authentication/AuthHeader/AuthHeader';
import GenericAuthForm from '@components/web/src/organisms/Authentication/GenericAuthForm/GenericAuthForm';
import {
  AuthFormItemTypes,
  AvailableInputObjects,
  SetNewPasswordFormInputObject,
} from '@components/web/src/pages/Authentication/AuthFormInterface';

interface IProps {
  onSubmitHandler: (data: AvailableInputObjects) => void;
}

const SetNewPassword: FC<IProps> = ({ onSubmitHandler }) => {
  const { commonMessages, setNewPasswordPage } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const passwordTooShortValidationText = publishedTerms[commonMessages.passwordTooShortValidation?.id];
  const passwordTooLongValidationText = publishedTerms[commonMessages.passwordTooLongValidation?.id];
  const passwordUpperLowerNumberValidationText = publishedTerms[commonMessages.passwordUpperLowerNumberValidation?.id];
  const passwordHintText = publishedTerms[setNewPasswordPage.hintText?.id];

  const setNewPasswordSchema: ZodType<SetNewPasswordFormInputObject> = useMemo(
    () =>
      z.object({
        password: z
          .string()
          .min(8, { message: passwordTooShortValidationText })
          .max(30, { message: passwordTooLongValidationText })
          .regex(new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])'), passwordUpperLowerNumberValidationText),
      }),
    [],
  );

  const setNewPasswordFormFields: AuthFormItemTypes<keyof SetNewPasswordFormInputObject>[] = useMemo(
    () => [
      {
        hint: passwordHintText,
        label: commonMessages.passwordField,
        name: 'password',
        placeholder: '********',
        type: 'password',
      },
    ],
    [],
  );

  return (
    <div className="set-new-password-page-container">
      <AuthHeader
        headerIcon="key"
        mainHeader={setNewPasswordPage.headerText}
        subHeader={setNewPasswordPage.subHeaderText}
      />
      <GenericAuthForm
        formFields={setNewPasswordFormFields}
        submitBtnText={commonMessages.resetPassword}
        validationSchema={setNewPasswordSchema}
        onSubmitHandler={onSubmitHandler}
      />
    </div>
  );
};

export default SetNewPassword;
