import { styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ProductFeedbackQuestionsCardContainer = styled(Flexbox)<{ $productCategory: TProductCategory }>`
  border-radius: 8px;
  background: ${({ $productCategory }) => STATIC_GRADIENTS.characterGradients[$productCategory]};
`;
