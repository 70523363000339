import { TokenResponse } from '@react-oauth/google';
import { FC } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { animated, useTransition } from 'react-spring';

import { ENV_FACEBOOK_APP_ID } from '@lib/core/service/consts';
import { IParsedServiceTerm, IRegisterPageServiceTerms } from '@lib/core/serviceTerms/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { EMAIL, FACEBOOK } from '@lib/tools/shared/helpers/consts';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

import AuthorizationButton from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import EmailRegistrationForm from '@components/web/src/organisms/Authentication/EmailRegistration/RegistrationEmailForm';
import GoogleLoginButton from '@components/web/src/organisms/Authentication/GoogleLoginButton/GoogleLoginButton';
import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';

interface IProps {
  isEmailAuth: boolean;
  isSocialAuthTesting: boolean;
  registerPageServiceTerms: IRegisterPageServiceTerms;
  handleEmailAuthButtonClick: () => void;
  responseGoogle: (response: TokenResponse) => void;
  responseFacebook: (response: ISocialLoginFacebook) => void;
  handleFormSubmit: (data: RegistrationFormInputObject) => void;
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  navigateToLoginPage: () => void;
}

export const AuthOptions: FC<IProps> = ({
  isEmailAuth,
  isSocialAuthTesting,
  registerPageServiceTerms,
  handleEmailAuthButtonClick,
  responseGoogle,
  responseFacebook,
  handleFormSubmit,
  handleOnServiceTermToggle,
  navigateToLoginPage,
}) => {
  const { googleBtn, facebookBtn, emailBtn } = localeWidget.authenticationPage;

  const transitions = useTransition(isEmailAuth, {
    config: {
      duration: 300,
    },
    enter: {
      opacity: 1,
      y: '0%',
    },
    from: { opacity: 0, y: '-10%' },
    leave: { opacity: 0, y: '-8%' },
  });

  const isFacebookHidden = true;

  return (
    <>
      <Flexbox isFullWidth direction="column" gap={8}>
        <GoogleLoginButton
          isDisabled={isEmailAuth}
          isHidden={isSocialAuthTesting}
          responseGoogle={responseGoogle}
          text={googleBtn}
        />

        {!isFacebookHidden && (
          <FacebookLogin
            disableMobileRedirect
            appId={ENV_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={responseFacebook}
            data-use-continue-as="true"
            fields="name,email,picture"
            render={({ onClick }) => (
              <AuthorizationButton
                key={FACEBOOK}
                handleClick={onClick}
                isDisabled={isEmailAuth}
                text={facebookBtn}
                type={FACEBOOK}
                variant="secondary"
              />
            )}
          />
        )}

        <AuthorizationButton
          handleClick={handleEmailAuthButtonClick}
          text={emailBtn}
          type={EMAIL}
          variant="secondary"
        />
      </Flexbox>
      {transitions((style, isOpen) => (
        <>
          {isOpen && (
            <animated.div style={style}>
              <EmailRegistrationForm
                handleFormSubmit={handleFormSubmit}
                handleOnServiceTermToggle={handleOnServiceTermToggle}
                navigateToLoginPage={navigateToLoginPage}
                registerPageServiceTerms={registerPageServiceTerms}
              />
            </animated.div>
          )}
        </>
      ))}
    </>
  );
};
