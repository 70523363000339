import { css, styled } from 'styled-components';

import { IBannerBackgroundType } from '@components/web/src/atoms/Banner/BannerCard';
import { STATIC_COLORS, styleMatcher } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const getBackgroundStyles = (bgColor: IBannerBackgroundType = 'white') =>
  styleMatcher(bgColor, {
    green: () => css`
      background: ${STATIC_COLORS.green[600]};
    `,
    red: () => css`
      background: ${STATIC_COLORS.base.red};
    `,
    teal: () => css`
      background: ${STATIC_COLORS.teal[600]};
    `,
    white: () => css`
      background: ${STATIC_COLORS.base.white};
    `,
  });

export const BannerCardContainer = styled(Flexbox)<{ $disabled?: boolean; $bgColor: IBannerBackgroundType }>`
  border-radius: 8px;

  ${({ $bgColor }) => getBackgroundStyles($bgColor)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      position: relative;
      pointer-events: none;
      cursor: not-allowed;

      &::after {
        position: absolute;
        inset: 0;
        pointer-events: none;
        content: '';
        background-color: ${STATIC_COLORS.base.white};
        opacity: 0.5;
        border-radius: inherit;
      }
    `}
`;

export const BannerCardTextWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  margin: 0 16px;
`;

export const IconBlock = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;
