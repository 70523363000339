import { FC, useMemo } from 'react';

import {
  IAnswerFinishProps,
  IProductFeedbackAnswer,
  IProductFeedbackQuestion,
  QuestionContext,
} from '@lib/core/products/slices/productFeedback';
import { TParsedProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { getRandomItemFromArray } from '@lib/core/service/utils';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import mascotWiseIcon from '@components/web/src/assets/icons/mascot/maskot_cool.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { ProductFeedbackAnswerCard } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackAnswerCard/ProductFeedbackAnswerCard';
import * as S from '@components/web/src/templates/ProductFeedback/ProductFeedbackAnswerPage/styles';

interface IProps {
  productFeedbackCurrentQuestion: IProductFeedbackQuestion;
  questionId: string;
  answer: IProductFeedbackAnswer;
  parsedProductInstanceData: TParsedProductInstance;
  handleProductFeedbackAnswerDone: (args: IAnswerFinishProps) => void;
}

export const ProductFeedbackAnswerPage: FC<IProps> = ({
  answer,
  productFeedbackCurrentQuestion,
  handleProductFeedbackAnswerDone,
  questionId,
  parsedProductInstanceData,
}) => {
  const { locale } = useApp();
  const { attributes } = parsedProductInstanceData;
  const {
    answerPageResponse,
    answerProducerSays,
    randomTrainingQuote1Part1,
    randomTrainingQuote1Part2,
    randomTrainingQuote2Part1,
    randomTrainingQuote2Part2,
    randomTrainingQuote3Part1,
    randomTrainingQuote3Part2,
    randomTrainingQuote4Part1,
    randomTrainingQuote4Part2,
    randomTrainingQuote5Part1,
    randomTrainingQuote5Part2,
    randomTrainingQuote6Part1,
    randomTrainingQuote6Part2,
    randomTrainingQuote7Part1,
    randomTrainingQuote7Part2,
    randomTrainingQuote8Part1,
    randomTrainingQuote8Part2,
    randomTrainingQuote9Part1,
    randomTrainingQuote9Part2,
    randomTrainingQuote10Part1,
    randomTrainingQuote10Part2,
    continueBtn,
  } = localeWidget.productFeedback;

  const randomStaticText = useMemo(
    () =>
      getRandomItemFromArray([
        [randomTrainingQuote1Part1, randomTrainingQuote1Part2],
        [randomTrainingQuote2Part1, randomTrainingQuote2Part2],
        [randomTrainingQuote3Part1, randomTrainingQuote3Part2],
        [randomTrainingQuote4Part1, randomTrainingQuote4Part2],
        [randomTrainingQuote5Part1, randomTrainingQuote5Part2],
        [randomTrainingQuote6Part1, randomTrainingQuote6Part2],
        [randomTrainingQuote7Part1, randomTrainingQuote7Part2],
        [randomTrainingQuote8Part1, randomTrainingQuote8Part2],
        [randomTrainingQuote9Part1, randomTrainingQuote9Part2],
        [randomTrainingQuote10Part1, randomTrainingQuote10Part2],
      ]),
    [],
  );
  const producerTextFromProductAttributes = useMemo(() => {
    switch (productFeedbackCurrentQuestion.context) {
      case QuestionContext.color:
        return attributes?.[`color_${locale}`];
      case QuestionContext.aroma:
        return attributes?.[`aromas_${locale}`];
      case QuestionContext.taste:
        return attributes?.[`taste_${locale}`];
      default:
        return '';
    }
  }, [parsedProductInstanceData, locale, productFeedbackCurrentQuestion]);

  return (
    <S.AnswerContainer align="center" direction="column" gap={0}>
      <Flexbox align="center" direction="column" gap={26} padding="54px 16px 0">
        <Flexbox align="center" direction="column">
          <Text color={STATIC_COLORS.base.white} size="body1" text={answerPageResponse} weight="bold" />
          <ProductFeedbackAnswerCard isOpenedAnswer answer={answer} />
        </Flexbox>
        <Flexbox align="center" direction="column" gap={8}>
          {producerTextFromProductAttributes && (
            <>
              <Text
                color={STATIC_COLORS.base.white}
                size="body1"
                text={answerProducerSays}
                textAlign="center"
                weight="bold"
              />
              <Text
                color={STATIC_COLORS.base.white}
                size="body1"
                text={producerTextFromProductAttributes}
                textAlign="center"
              />
            </>
          )}
        </Flexbox>
        <Flexbox align="center" direction="column" padding="32px 0">
          <div style={{ paddingTop: '13px' }}>
            <Image alt="mascot" height="186px" src={mascotWiseIcon} />
          </div>
          <Flexbox direction="column" gap={8}>
            <Text
              color={STATIC_COLORS.base.white}
              size="body1"
              text={randomStaticText[0]}
              textAlign="center"
              weight="bold"
            />
            <Text color={STATIC_COLORS.base.white} size="body1" text={randomStaticText[1]} textAlign="center" />
          </Flexbox>
        </Flexbox>
      </Flexbox>
      <S.ButtonSection isFullWidth justify="center" padding="8px 16px">
        <Button
          className="full-width-button"
          fontSize="subtitle2"
          text={continueBtn}
          handleClick={() =>
            handleProductFeedbackAnswerDone({
              questionId,
            })
          }
        />
      </S.ButtonSection>
    </S.AnswerContainer>
  );
};
