import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useApp } from '@lib/core/service/hooks';
import { useProductRate } from '@lib/core/users/hooks';
import { TProductRate } from '@lib/core/users/slices/productRate';
import { STYLE_NAME, TASTE_PATH_PRODUCT_STYLES } from '@lib/tools/shared/helpers/consts';
import { useTastePathProducts } from '@lib/tools/tastePathProducts/hooks/useTastePathProducts';
import { fetchTastePathProducts, setShouldSkipCategory } from '@lib/tools/tastePathProducts/slices/index';
import { ITastePathData } from '@lib/tools/tastePathProducts/types/types';
import { useAddons } from '@lib/tools/views/hooks';

const useTastePathData = () => {
  const dispatch = useDispatch();

  const { isEnableTastePathAddon } = useAddons();
  const { locale, productCategory } = useApp();
  const { productRateData } = useProductRate();

  const { isTastePathProductsLoading, tastePathData, currentTastePathCategoryId, nextTastePathCategoryId } =
    useTastePathProducts();

  const nextTastePathCategory =
    (nextTastePathCategoryId &&
      TASTE_PATH_PRODUCT_STYLES[productCategory][nextTastePathCategoryId]?.[`${STYLE_NAME}${locale}`]) ||
    '';

  const currentTastePathProduct = tastePathData?.[currentTastePathCategoryId]?.products.find(
    product => !productRateData.some(feedback => product.identifier === feedback.product.identifier),
  );

  const currentTastePathCategoryIdProductsLength = tastePathData?.[currentTastePathCategoryId]?.products.length;

  const shouldChangeTastePathCategory =
    productRateData.filter(product => product?.product?.character?.type?.identifier === currentTastePathCategoryId)
      .length >= currentTastePathCategoryIdProductsLength;

  const shouldDisplaySkipCategoryBtn = tastePathData
    ? Object.values(tastePathData).filter(category => category).length -
        Object.values(tastePathData).filter(category => category.shouldSkipCategory).length !==
      1
    : false;

  const isPathDone = (feedbacks: TProductRate[], pathData: Record<string, ITastePathData>) => {
    const countMap = {};

    feedbacks.forEach(item => {
      const identifier = item?.product?.character?.type?.identifier || '';
      countMap[identifier] = (countMap[identifier] || 0) + 1;
    });

    return Object.keys(pathData).every(key => {
      const tastePathDataLength = pathData[key].products.length;
      return countMap[key] >= tastePathDataLength;
    });
  };

  const isTastePathDone =
    tastePathData &&
    (Object.values(tastePathData).every(item => item.shouldSkipCategory) || isPathDone(productRateData, tastePathData));

  useEffect(() => {
    if (isEnableTastePathAddon && !tastePathData) {
      /** Fetch products data and create a new Taste Path Data */
      dispatch(fetchTastePathProducts());
    }
  }, [tastePathData]);

  useEffect(() => {
    if (shouldChangeTastePathCategory && tastePathData) {
      dispatch(setShouldSkipCategory({ currentTastePathCategoryId, shouldChangeTastePathCategory }));
    }
  }, [shouldChangeTastePathCategory]);

  return {
    currentTastePathCategoryId,
    currentTastePathProduct,
    isTastePathDone,
    isTastePathProductsLoading,
    nextTastePathCategory,
    shouldChangeTastePathCategory,
    shouldDisplaySkipCategoryBtn,
    tastePathData,
  };
};

export default useTastePathData;
