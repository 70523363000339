import styled, { css } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const RecipesResultContainer = styled(Flexbox)`
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      z-index: 2;
      width: 600px;
      margin: 0 auto;
      height: 900px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-thumb {
        height: 614px;
        background: #336ca2;
        border: 3px solid #e0e2da;
        border-radius: 14px;
      }

      &::-webkit-scrollbar-track {
        background: #e0e2da;
        border-radius: 14px;
      }
    `}
`;

export const RecipeItemContainer = styled(Flexbox)`
  height: 66px;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 100px;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
`;
