import { FC, ReactNode } from 'react';

import { TLanguage } from '@lib/core/retailers/types';
import { TToastMessages } from '@lib/tools/toastMessage/types/interfaces';

import { FooterWidgetAnonymous, FooterWidgetLogged } from '@components/web/src/atoms/Footers';
import { FooterWidgetAnonymousWave } from '@components/web/src/atoms/HeaderFooter/HeaderFooterWidget/components/FooterWidgetAnonymousWave';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterWidget/styles';
import { HeaderWidget } from '@components/web/src/atoms/Headers/HeaderWidget/HeaderWidget';
import ToastMessage from '@components/web/src/atoms/Toast/ToastMessage';
import DownloadAppCard from '@components/web/src/organisms/Cards/DownloadAppCard/DownloadAppCard';
import { TJourneyStep } from '@components/web/src/organisms/TastePath/TastePathProgress/TastePathProgress';
import { TastePathOnboarding } from '@components/web/src/templates/Tutorials/TastePathOnboarding/TastePathOnboarding';

interface IProps {
  children: ReactNode;
  isHideGearIcon: boolean;
  shouldShowHeaderDropDownMenu: boolean;
  locale: string;
  isEnableExitNavigation: boolean;
  isEnableLanguageSwitcher: boolean;
  isEnableSettingsNavigation: boolean;
  isHomePage: boolean;
  retailerLanguages: TLanguage[];
  profileImageUrl?: string;
  producerName?: string;
  retailerName: string;
  shouldHideHomeLink: boolean;
  isMultipleProducer?: boolean;
  shouldHideHeader?: boolean;
  shouldHideCatalogLink: boolean;
  shouldHideTasteIdLink: boolean;
  shouldHideExploreLink: boolean;
  shouldShowTasteIdGuide?: boolean;
  shouldShowDownloadAppCard: boolean;
  shouldHideAnonymousFooter: boolean;
  shouldShowAnonymousFooterWave: boolean;
  shouldHideLoggedFooter: boolean;
  isFixedBody: boolean;
  toastMessages: TToastMessages;
  shouldShowTastePathOnboarding: boolean;
  productCategory: string;
  tastePathJourneyData: TJourneyStep[];
  handleLogoClick: () => void;
  handleExitNavigation: () => void;
  handleSettingPageNavigation: () => void;
  handleClearToastMessage: () => void;
  handleSelectLanguage: (selectedLanguageCode: string) => void;
  handleSetTasteIdPageShown?: () => void;
  handleDownloadAppClick?: () => void;
  handleHideDownloadAppClick?: () => void;
  handleHideTastePathOnboarding: () => void;
}

const HeaderFooterWidget: FC<IProps> = ({
  children,
  isHideGearIcon,
  isEnableSettingsNavigation,
  shouldShowHeaderDropDownMenu,
  isEnableExitNavigation,
  isEnableLanguageSwitcher,
  isMultipleProducer,
  locale,
  retailerLanguages,
  retailerName,
  profileImageUrl,
  producerName,
  shouldHideHeader = false,
  shouldHideCatalogLink,
  shouldHideTasteIdLink,
  shouldHideHomeLink,
  shouldShowDownloadAppCard,
  shouldHideExploreLink,
  shouldShowTasteIdGuide,
  shouldHideAnonymousFooter,
  shouldHideLoggedFooter,
  shouldShowAnonymousFooterWave,
  isFixedBody,
  toastMessages,
  shouldShowTastePathOnboarding,
  productCategory,
  tastePathJourneyData,
  handleLogoClick,
  handleExitNavigation,
  handleSettingPageNavigation,
  handleClearToastMessage,
  handleSelectLanguage,
  handleSetTasteIdPageShown,
  handleDownloadAppClick,
  handleHideDownloadAppClick,
  handleHideTastePathOnboarding,
}) => {
  return (
    <S.HeaderFooterWidgetContainer>
      {toastMessages && (
        <ToastMessage
          handleClearToastMessage={handleClearToastMessage}
          message={toastMessages.message}
          retailerName={retailerName}
          title={toastMessages.title}
          type={toastMessages.type}
        />
      )}
      {shouldShowDownloadAppCard && (
        <DownloadAppCard handleClick={handleDownloadAppClick} handleCloseClick={handleHideDownloadAppClick} />
      )}
      {shouldShowTastePathOnboarding && (
        <TastePathOnboarding
          handleClose={handleHideTastePathOnboarding}
          productCategory={productCategory}
          tastePathJourneyData={tastePathJourneyData}
        />
      )}

      {!shouldHideHeader && (
        <HeaderWidget
          handleExitNavigation={handleExitNavigation}
          handleLogoClick={handleLogoClick}
          handleSelectLanguage={handleSelectLanguage}
          handleSettingPageNavigation={handleSettingPageNavigation}
          isEnableExitNavigation={isEnableExitNavigation}
          isEnableLanguageSwitcher={isEnableLanguageSwitcher}
          isEnableSettingsNavigation={isEnableSettingsNavigation}
          locale={locale}
          producerName={producerName}
          retailerLanguages={retailerLanguages}
          shouldShowHeaderDropDownMenu={shouldShowHeaderDropDownMenu}
        />
      )}
      <S.BodyContainer
        $isFixedBody={isFixedBody}
        direction="column"
        gap={0}
        id="root-scroll-element"
        justify="space-between"
      >
        {children}
        {!shouldHideAnonymousFooter && (
          <S.FooterWidgetAnonymousWrapper>
            {shouldShowAnonymousFooterWave && <FooterWidgetAnonymousWave />}
            <FooterWidgetAnonymous isHideGearIcon={isHideGearIcon} />
          </S.FooterWidgetAnonymousWrapper>
        )}
      </S.BodyContainer>
      {!shouldHideLoggedFooter && (
        <FooterWidgetLogged
          handleSetTasteIdPageShown={handleSetTasteIdPageShown}
          isMultipleProducer={isMultipleProducer}
          profileImageUrl={profileImageUrl}
          shouldHideCatalogLink={shouldHideCatalogLink}
          shouldHideExploreLink={shouldHideExploreLink}
          shouldHideHomeLink={shouldHideHomeLink}
          shouldHideTasteIdLink={shouldHideTasteIdLink}
          shouldShowTasteIdGuide={shouldShowTasteIdGuide}
        />
      )}
    </S.HeaderFooterWidgetContainer>
  );
};

export default HeaderFooterWidget;
