import { FC, useEffect, useState } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import AuthHeader from '@components/web/src/organisms/Authentication/AuthHeader/AuthHeader';

interface IProps {
  email: string;
  handleGoBack: () => void;
  handlePasswordResend: () => void;
}
const CheckResetPasswordPage: FC<IProps> = ({ handleGoBack, handlePasswordResend, email }) => {
  const [time, setTime] = useState(60);
  const [showTimer, setShowTimer] = useState(true);
  const { checkResetPasswordPage } = localeCommon;

  useEffect(() => {
    let timer;
    if (showTimer && time > 0) {
      timer = setInterval(() => setTime(prevTime => prevTime - 1), 1000);
    } else {
      setShowTimer(false);
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [time, showTimer]);

  return (
    <div className="check-reset-password-page-container">
      <AuthHeader
        email={email}
        headerIcon="mail"
        mainHeader={checkResetPasswordPage.title}
        subHeader={checkResetPasswordPage.subtitleFirst}
        subHeaderSecond={checkResetPasswordPage.subtitleSecond}
      />
      <div className="password-resend">
        <p>
          <LocaleFragment message={checkResetPasswordPage.retryTitleText} />
        </p>
        {showTimer ? (
          <p>
            <LocaleFragment message={checkResetPasswordPage.retryIn} /> {time}
          </p>
        ) : (
          <Button
            className="reset-button"
            disabled={showTimer}
            size="sm"
            text={checkResetPasswordPage.resendBtnText}
            type="button"
            variant={VH_VARIANTS.LINK}
            handleClick={() => {
              handlePasswordResend();
              setShowTimer(true);
              setTime(60);
            }}
          />
        )}
      </div>
      <BackButton handleClick={handleGoBack} variant="long" />
    </div>
  );
};

export default CheckResetPasswordPage;
