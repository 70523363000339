import { FC } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';

import { RatingButton } from '@components/web/src/atoms/ProductRating/ProductRating/RatingButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { FlexboxContainer } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/TastePath/TastePathProductItem/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  isUpdatedItem: boolean;
  isFeedbackModalDisabled: boolean;
}

const TastePathProductItem: FC<IProps> = ({ parsedProductInstanceData, isUpdatedItem, isFeedbackModalDisabled }) => {
  const { productCharacterName, productCharacterTechnicalCaption, productName, productProducerName } =
    parsedProductInstanceData;

  const characterText = `#${productCharacterName} • ${productCharacterTechnicalCaption}`;

  return (
    <S.TastePathProductItem $isUpdatedItem={isUpdatedItem}>
      <FlexboxContainer $direction="column" $gap={4}>
        <Text color={STATIC_COLORS.green[600]} linesLimit={1} size="body3" text={characterText} weight="medium" />
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          linesLimit={2}
          size="subtitle2"
          text={productName}
          weight="semibold"
        />
        <Text color={STATIC_COLORS.base.black} size="body2" text={productProducerName} />
      </FlexboxContainer>
      <RatingButton
        discoveryQuiz={undefined}
        isFeedbackModalDisabled={isFeedbackModalDisabled}
        parsedProductInstanceData={parsedProductInstanceData}
      />
    </S.TastePathProductItem>
  );
};

export default TastePathProductItem;
