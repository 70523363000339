import { FC } from 'react';

import { isApplicationKiosk } from '@lib/core/service/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { Text } from '@components/web/src/foundations/Text/Text';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import * as S from '@components/web/src/templates/LegalPages/TermsConditionsPage/styles';

interface IProps {
  isApplicationKioskProp?: boolean;
}
const TermsConditionsPage: FC<IProps> = ({ isApplicationKioskProp = false }) => {
  const { termsConditionsPage } = localeWidget;
  const actualIsApplicationKiosk = isApplicationKiosk || isApplicationKioskProp;
  const {
    mainTitle,
    mainDescription,
    platformDescription,
    platformDescriptionParagraph1,
    platformDescriptionParagraph2,
    platformDescriptionParagraph3,
    platformDescriptionParagraph4,
    platformDescriptionParagraph5,
    platformDescriptionPrint,
    // condition
    conditionTitle1,
    condition1Paragraph1,
    condition1Paragraph2,
    condition1Paragraph3,
    condition1Paragraph4,
    condition1Paragraph5,
    condition1Paragraph6,
    conditionTitle2,
    condition2Paragraph1,
    condition2Paragraph2,
    condition2Paragraph3,
    condition2Paragraph4,
    condition2Paragraph5,
    condition2Paragraph6,
    condition2Paragraph7,
    condition2Paragraph8,
    conditionTitle3,
    condition3Paragraph1,
    condition3Paragraph2,
    conditionTitle4,
    condition4Paragraph1,
    condition4Paragraph2,
    condition4Paragraph3,
    condition4Paragraph4,
    conditionTitle5,
    condition5Paragraph1,
    condition5Paragraph2,
    condition5Paragraph3,
    condition5Paragraph4,
    condition5Paragraph5,
    condition5Paragraph6,
    conditionTitle6,
    condition6Paragraph1,
    condition6Paragraph2,
    condition6Paragraph3,
  } = termsConditionsPage;

  const conditionsData = [
    {
      paragraphs: [
        condition1Paragraph1,
        condition1Paragraph2,
        condition1Paragraph3,
        condition1Paragraph4,
        condition1Paragraph5,
        condition1Paragraph6,
      ],
      title: conditionTitle1,
    },
    {
      paragraphs: [
        condition2Paragraph1,
        condition2Paragraph2,
        condition2Paragraph3,
        condition2Paragraph4,
        condition2Paragraph5,
        condition2Paragraph6,
        condition2Paragraph7,
        condition2Paragraph8,
      ],
      title: conditionTitle2,
    },
    {
      paragraphs: [condition3Paragraph1, condition3Paragraph2],
      title: conditionTitle3,
    },
    {
      paragraphs: [condition4Paragraph1, condition4Paragraph2, condition4Paragraph3, condition4Paragraph4],
      title: conditionTitle4,
    },
    {
      paragraphs: [
        condition5Paragraph1,
        condition5Paragraph2,
        condition5Paragraph3,
        condition5Paragraph4,
        condition5Paragraph5,
        condition5Paragraph6,
      ],
      title: conditionTitle5,
    },
    {
      paragraphs: [condition6Paragraph1, condition6Paragraph2, condition6Paragraph3],
      title: conditionTitle6,
    },
  ];

  return (
    <S.TermsConditionsWrapper $isApplicationKiosk={actualIsApplicationKiosk}>
      {isApplicationKiosk && <KioskCirclesBackground />}
      <S.MainTitle $isApplicationKiosk={actualIsApplicationKiosk}>
        <Text fontFamily="Fraunces" size="h5" text={mainTitle} weight="semibold" />
      </S.MainTitle>
      <Text size="body2" text={mainDescription} />
      <S.Section $isApplicationKiosk={actualIsApplicationKiosk}>
        <Text size="body2" text={platformDescription} />

        <ul>
          {[
            platformDescriptionParagraph1,
            platformDescriptionParagraph2,
            platformDescriptionParagraph3,
            platformDescriptionParagraph4,
            platformDescriptionParagraph5,
          ].map((paragraph, paragraphIdx) => (
            <li key={paragraphIdx}>
              <Text size="body2" text={paragraph} />
            </li>
          ))}
        </ul>
        <Text size="body2" text={platformDescriptionPrint} />
      </S.Section>
      {conditionsData.map((condition, conditionIdx) => {
        const condIdx = conditionIdx + 1;
        return (
          <S.Section key={conditionIdx} $isApplicationKiosk={actualIsApplicationKiosk}>
            <S.SectionTitle>
              {`${condIdx}.`} <Text size="body2" text={condition.title} weight="bold" />
            </S.SectionTitle>
            {condition.paragraphs.map((paragraph, paragraphIdx) => {
              const parIdx = paragraphIdx + 1;
              return (
                <p key={paragraphIdx}>
                  <S.StyledText size="body2" text={`${condIdx}.${parIdx}`} weight="bold" />
                  <Text size="body2" text={paragraph} />
                </p>
              );
            })}
          </S.Section>
        );
      })}
    </S.TermsConditionsWrapper>
  );
};

export default TermsConditionsPage;
