import { FC, useMemo, useState } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { HARDCODED_PRODUCT_RATE_DATA } from '@lib/core/service/consts';
import { IHandleUpdateProductRatingProps } from '@lib/core/users/hooks/useProductRate';
import { IProductRatingOptions, TProductRateValue } from '@lib/core/users/slices/productRate';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';

import ratingHeader from '@components/web/src/assets/icons/training/ratingHeader.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import { ProductFeedbackRateListItem } from '@components/web/src/atoms/ProductFeedback/ProductFeedbackRateListItem/ProductFeedbackRateListItem';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ProductFeedback/ProductFeedbackRatingCard/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  handleSkip: () => void;
  productCategory: TProductCategory;
  handleUpdateProductRating: (args: IHandleUpdateProductRatingProps) => Promise<void>;
  productRate: IProductRatingOptions;
  isProductRateUpdateLoading: boolean;
  productRateOptions: IProductRatingOptions[];
}

export const ProductFeedbackRatingCard: FC<IProps> = ({
  parsedProductInstanceData,
  handleSkip,
  productCategory,
  productRate,
  isProductRateUpdateLoading,
  productRateOptions,
  handleUpdateProductRating,
}) => {
  const [isFeedbackPendingForRate, setIsFeedbackPendingForRate] = useState(null);
  const { productCategories } = localeCommon;
  const { rateTitle, skipBtn, veryGoodRateLabel, veryBadRateLabel } = localeWidget.productFeedback;
  const { publishedTerms } = LocaleUtils;

  const productFeedbackOptionsReversed = useMemo(() => productRateOptions?.reverse(), productRateOptions);

  const productCategoriesText = publishedTerms[productCategories[productCategory].id];

  const rateValue = isFeedbackPendingForRate || productRate?.rateValue;

  const getFeedbackIcon = (rate: TProductRateValue) => {
    return !rateValue || rate === rateValue
      ? HARDCODED_PRODUCT_RATE_DATA[rate].icon.activeIcon
      : HARDCODED_PRODUCT_RATE_DATA[rate].icon.inactiveIcon;
  };
  const allIcons = Object.values(HARDCODED_PRODUCT_RATE_DATA).flatMap(item => [
    item.icon.activeIcon,
    item.icon.inactiveIcon,
  ]);
  return (
    <S.ProductRateCardContainer
      $productCategory={productCategory}
      align="center"
      direction="column"
      gap={16}
      padding="16px"
    >
      <S.ProductRateImagesPreloadContainer>
        {allIcons.map((icon, idx) => (
          <img key={idx} alt="icon" src={icon} />
        ))}
      </S.ProductRateImagesPreloadContainer>
      <Image alt="image" height="124px" src={ratingHeader} />
      <Text
        color={STATIC_COLORS.base.black}
        size="h6"
        text={rateTitle}
        weight="bold"
        localeOptions={{
          productCategoriesText,
          productCategory,
        }}
      />
      <Flexbox direction="column" gap={16}>
        <Flexbox isFullWidth gap={16} justify="space-between">
          {productFeedbackOptionsReversed?.map(rate => (
            <ProductFeedbackRateListItem
              key={`rating ${getFeedbackIcon(rate.rateValue)}`}
              feedbackIcon={getFeedbackIcon(rate.rateValue)}
              onHandleClick={() => {
                setIsFeedbackPendingForRate(rate.rateValue);
                handleUpdateProductRating({
                  productId: parsedProductInstanceData.productId,
                  productName: parsedProductInstanceData.productName,
                  productRate: rate.rateValue,
                  slug: rate.slug,
                }).then(() => handleSkip());

                MixpanelTracker.events.productFeedback(parsedProductInstanceData, rate.rateValue);
              }}
            />
          ))}
        </Flexbox>
        <Flexbox isFullWidth gap={0} justify="space-between">
          <Text color={STATIC_COLORS.warmGray[900]} size="body3" text={veryBadRateLabel} weight="semibold" />
          <Text color={STATIC_COLORS.warmGray[900]} size="body3" text={veryGoodRateLabel} weight="semibold" />
        </Flexbox>
      </Flexbox>

      <Button
        disabled={isProductRateUpdateLoading}
        fontSize="subtitle2"
        handleClick={handleSkip}
        size="md"
        text={skipBtn}
        textDecoration="underline"
        textWeight="bold"
        variant="transparent"
      />
    </S.ProductRateCardContainer>
  );
};
