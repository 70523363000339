import cn from 'classnames';
import { FC } from 'react';

import { IB2CArticle } from '@app/native/src/interfaces/article';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { ExperienceCardSkeleton } from '@components/web/src/app/Skeleton/ExperienceCardSkeleton';
import SkeletonWrapper from '@components/web/src/app/Skeleton/SkeletonWrapper';
import Button from '@components/web/src/atoms/Buttons/Button';
import { CardFooter } from '@components/web/src/organisms/CardFooter/CardFooter';

interface IProps {
  articleData?: IB2CArticle;
  isLoading?: boolean;
  isResponsive?: boolean;
  navigateToArticlePage?: (articleId: string) => void;
}

const ArticleCard: FC<IProps> = ({ articleData, isLoading = false, isResponsive = false, navigateToArticlePage }) => {
  const {
    identifier = '',
    image = '',
    article_type: { name: articleType = '' },
    title = '',
    description = '',
    tags = [],
  } = articleData;

  return (
    <SkeletonWrapper showSkeleton={isLoading} skeleton={ExperienceCardSkeleton}>
      <div
        aria-hidden
        className={cn('article-card-container', { isResponsive })}
        onClick={() => navigateToArticlePage(identifier)}
      >
        <div className="image-container">
          <img alt="product mage" src={image} />
        </div>
        <div className="content-container">
          <p className="article-type">
            <LocaleFragment message={articleType} />
          </p>
          <p className="title">
            <LocaleFragment message={title.toLowerCase()} />
          </p>
          <p className="description">
            <LocaleFragment message={description} />
          </p>
        </div>
        <Button
          className="give-it-button"
          size="sm"
          text={localeApp.articleCard.ctaBtn}
          textWeight="semibold"
          variant={VH_VARIANTS.LINK}
        />
        <CardFooter badges={tags} />
      </div>
    </SkeletonWrapper>
  );
};

export default ArticleCard;
