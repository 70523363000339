import { useLocation } from 'react-router-dom';

import { IAuthFeatureParams } from '@app/web/src/hooks/useAuthFeatureClick';

import { useCharacters } from '@lib/core/characters/hooks';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { useApp } from '@lib/core/service/hooks';
import { useUser } from '@lib/core/users/hooks';
import { parseCharacterAdjectiveModalData, sortCharacterAdjectiveModalData } from '@lib/tools/characterAdjective';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { AUTH_BANNERS, TAuthBanner } from '@lib/tools/shared/helpers/consts';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';

import { TSwiperData } from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/ExtraInfoSwiper';

export interface IRegistrationBannersData {
  registrationBannerVariant: TAuthBanner;
  bannerDescription?: ILocaleText | string;
  bannerExtraDescription?: ILocaleText | string;
  bannerTitle?: ILocaleText | string;
  bannerSwiperData?: TSwiperData[] | null;
}

export const useRegistrationBannersData = (): IRegistrationBannersData => {
  const location = useLocation();

  const bannerParams: IAuthFeatureParams = location?.state?.bannerParams;
  const registrationBannerVariant = bannerParams?.registrationBannerVariant;
  const adjectiveCharacterIdToMove = bannerParams?.adjectiveCharacterIdToMove;
  const correctQuizAnswer = bannerParams?.correctQuizAnswer;
  const productCategories = [bannerParams?.productCategory];

  const { characters } = useCharacters();
  const { userBestMatchCharacterByProductCategory } = useUser();
  const { locale, productCategory } = useApp();
  const { isDesignSetVinhoodExperience } = useRetailerDesignSet();

  const { registrationWithIcon } = localeApp;
  const { registerPage } = localeCommon;
  const { testResultPage } = localeWidget;

  if (registrationBannerVariant === AUTH_BANNERS.character) {
    const parseCharactersSwiperData = (
      userCharacters: Record<string, any>,
      allCharacters: IParsedCharacter[],
      allProductCategories: string[],
    ): Record<string, TSwiperData[]> | null => {
      if (!userCharacters) return null;

      const parseData = (category: string): TSwiperData[] => {
        const identifierToMove = userCharacters[category].identifier;
        const characterToMove = allCharacters.find(character => character.characterId === identifierToMove);
        const filteredCharacters = allCharacters.filter(
          character =>
            character.characterProductCategory.name === category && character.characterId !== identifierToMove,
        );

        return [
          {
            description: characterToMove?.characterDescription,
            image: characterToMove?.characterImage,
            productCategory: characterToMove?.characterProductCategory.name,
            title: characterToMove?.characterName,
          },
          ...filteredCharacters.map(filteredCharacter => ({
            description: filteredCharacter.characterDescription,
            image: filteredCharacter.characterImage,
            productCategory: filteredCharacter.characterProductCategory.name,
            title: filteredCharacter.characterName,
          })),
        ];
      };

      return allProductCategories.reduce(
        (acc, category) => {
          acc[category] = parseData(category);
          return acc;
        },
        {} as Record<string, TSwiperData[]>,
      );
    };

    return {
      bannerSwiperData: parseCharactersSwiperData(
        userBestMatchCharacterByProductCategory,
        characters,
        productCategories,
      )?.[productCategory],
      registrationBannerVariant,
    };
  }

  if (registrationBannerVariant === AUTH_BANNERS.adjective) {
    const characterAdjectiveSwiperData = parseCharacterAdjectiveModalData(
      userBestMatchCharacterByProductCategory,
      locale,
      productCategories,
    )?.[productCategory];

    return {
      bannerSwiperData: sortCharacterAdjectiveModalData(characterAdjectiveSwiperData, adjectiveCharacterIdToMove),
      registrationBannerVariant,
    };
  }

  if (registrationBannerVariant === AUTH_BANNERS.authFeature) {
    return {
      bannerDescription: registrationWithIcon.description,
      bannerExtraDescription: registrationWithIcon.extraDescription,
      bannerTitle: registrationWithIcon.title,
      registrationBannerVariant,
    };
  }

  if (registrationBannerVariant === AUTH_BANNERS.quizTrue) {
    return {
      bannerDescription: correctQuizAnswer,
      bannerTitle: testResultPage.yesRight,
      registrationBannerVariant,
    };
  }

  if (registrationBannerVariant === AUTH_BANNERS.quizFalse) {
    return {
      bannerDescription: correctQuizAnswer,
      bannerTitle: testResultPage.ohNo,
      registrationBannerVariant,
    };
  }

  const defaultRegistrationBannerVariant = isDesignSetVinhoodExperience ? AUTH_BANNERS.experience : AUTH_BANNERS.app;
  const defaultBannerTitle = isDesignSetVinhoodExperience ? registerPage.headerTitle : registerPage.headerTitleApp;
  const defaultBannerDescription = isDesignSetVinhoodExperience
    ? registerPage.headerSubTitle
    : registerPage.headerSubTitleApp;

  return {
    bannerDescription: defaultBannerDescription,
    bannerTitle: defaultBannerTitle,
    registrationBannerVariant: defaultRegistrationBannerVariant,
  };
};
