import { css, styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ProductCardWrapper = styled(Flexbox)<{
  $productCategory: TProductCategory;
  $isTasteBoostStatusEnabled: boolean;
  $isCardClickDisabled: boolean;
}>`
  min-width: 164px;
  max-width: 164px;
  padding: 8px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  pointer-events: ${({ $isCardClickDisabled }) => ($isCardClickDisabled ? 'none' : 'auto')};
  background: ${({ $isTasteBoostStatusEnabled, $productCategory }) =>
    $isTasteBoostStatusEnabled ? STATIC_GRADIENTS.characterGradients[$productCategory] : STATIC_COLORS.warmGray[100]};
`;

export const ProductImageWrapper = styled(Flexbox)`
  position: relative;
`;

export const ProductImage = styled(Image)<{ $isTasteBoostStatusDisabled: boolean }>`
  ${({ $isTasteBoostStatusDisabled }) =>
    $isTasteBoostStatusDisabled &&
    css`
      background: transparent;
      filter: grayscale(1) opacity(0.2);
    `}
`;

export const ProductCtaContainer = styled(Flexbox)`
  position: absolute;
  right: -8px;
  top: -8px;
  height: 100%;
`;

export const ProductCardCharacter = styled(Text)<{ $productCategory: TProductCategory }>`
  strong {
    color: ${({ $productCategory }) => STATIC_COLORS.productColors[$productCategory]['500'] ?? 'inherit'};
  }
`;

export const ProductCardCTA = styled(Button)`
  text-decoration: underline;
  padding: 0;
`;

export const StyledProductDashedPriceText = styled(Text)`
  color: ${STATIC_COLORS.gray['400']};
  text-decoration-line: line-through;
  white-space: nowrap;
`;

export const StyledProductPriceText = styled(Text)`
  white-space: nowrap;
  color: ${STATIC_COLORS.base.black};
`;

export const PromoLabel = styled.div`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: -30px;
  width: 100px;
  height: 20px;
  padding: 3px 0;
  background-color: ${STATIC_COLORS.base.red};
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const TasteBoostIcon = styled(Image)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  height: 100%;

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;

export const FindProductWrapper = styled.div`
  width: 100%;
  min-height: 28px;

  button {
    width: 100%;
    padding: 4px 14px;
  }
`;
