import classNames from 'classnames';
import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { useDisclosure } from '@lib/tools/views/hooks';

import { ReactComponent as CollapseIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_character_collapse.svg';
import { ReactComponent as ExpandIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_character_expand.svg';
import ProfileProgressBar from '@components/web/src/organisms/Cards/MyTasteProfileCard/ProfileProgressBar/ProfileProgressBar';
import UserCharacterInfoImage from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterInfoImage';
import UserCharacterInfoItemSection from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterInfoItemSection/UserCharacterInfoItemSection';

type Props = {
  progress: number;
  userBestMatchCharacterByProductCategory: Record<TProductCategory, IParsedCharacter>;
  onCharacterItemSectionClick: () => void;
  onPersonalizeSectionClick: () => void;
};

const MyTasteProfileCard: FC<Props> = ({
  progress,
  userBestMatchCharacterByProductCategory,
  onCharacterItemSectionClick,
  onPersonalizeSectionClick,
}) => {
  const [isOpen, { toggle }] = useDisclosure({ defaultIsOpen: true });
  const ToggleIcon = isOpen ? CollapseIcon : ExpandIcon;

  const { userCharacterInfo, userCharacterPersonalizeSection } = localeApp;

  return (
    <div className={classNames('my-taste-profile-card-container', { isOpen })}>
      <div aria-hidden className="header-container" onClick={toggle}>
        <p className="title">
          <LocaleFragment message={userCharacterInfo.tasteIdentityText} />
        </p>
        <div className="header-character-images">
          <UserCharacterInfoImage
            image={userBestMatchCharacterByProductCategory?.wine?.characterImage}
            productCategory={PRODUCT_CATEGORY_WINE}
          />
          <UserCharacterInfoImage
            image={userBestMatchCharacterByProductCategory?.beer?.characterImage}
            productCategory={PRODUCT_CATEGORY_BEER}
          />
          <UserCharacterInfoImage
            image={userBestMatchCharacterByProductCategory?.coffee?.characterImage}
            productCategory={PRODUCT_CATEGORY_COFFEE}
          />
        </div>
        <ToggleIcon />
      </div>
      <div className="divider" />
      <div className="content">
        <UserCharacterInfoItemSection
          userBestMatchCharacterByProductCategory={userBestMatchCharacterByProductCategory}
          onClick={onCharacterItemSectionClick}
        />
        {progress < 100 ? (
          <>
            <div className="divider" />
            <ProfileProgressBar
              description={userCharacterPersonalizeSection.personalizeExperienceDesc}
              progress={progress}
              title={userCharacterPersonalizeSection.personalizeExperienceText}
              onClick={onPersonalizeSectionClick}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MyTasteProfileCard;
