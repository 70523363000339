export const ROOT_ELEMENT_FRAME = 'root-element-frame';
export const ROOT_ELEMENT_EXPERIENCE = 'root-element-experience';
export const ROOT_ELEMENT_KIOSK = 'root-element-kiosk';

// Essence total indicator amount
export const TOTAL_INDICATOR_AMOUNT = 5;

// Skeleton Blocks Constants
export const NUM_SEARCH_SKELETONS = 6;
// Queries
export const PRODUCT_CATEGORY_QUERY_KEY = 'product_type';
export const IS_FROM_TASTE_PATH = 'isFromTastePath';
// Articles Catalog
export const ARTICLES_TYPES = {
  experiences: 'ART0003',
  interestingFactsEducation: 'ART0002',
  pairings: 'ART0001',
  producerStories: 'ART0006',
  reviews: 'ART0004',
  tasteStudy: 'ART0005',
  tutorials: 'ART0007',
};
export const URL_PARAMS_HINT_TYPE = 'type';
export const HINT_TEXT = 'hint';

// B2C Image Files Errors
export const IMAGE_ERRORS = {
  415: 'Unsupported Media',
  500: 'Could not Upload',
  513: 'Payload Too Large',
};

export const DESKTOP_RESOLUTION_MIN_VALUE = 500;
