import { FC } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import mascotHelloIcon from '@components/web/src/assets/icons/mascot/mascot_hello_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { ProductCard } from '@components/web/src/organisms/Cards/ProductCard/ProductCard/ProductCard';
import * as S from '@components/web/src/templates/ProductFeedback/ProductFeedbackTutorial/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  handleClose: () => void;
}

export const ProductFeedbackTutorial: FC<IProps> = ({ parsedProductInstanceData, handleClose }) => {
  const { titleText, btnText } = localeWidget.productFeedbackTutorialModal;
  const { productCategories } = localeCommon;
  const { productCategory } = parsedProductInstanceData;
  return (
    <S.ProductFeedbackTutorialContainer isFullWidth direction="column" gap={32} justify="center" padding="32px 16px">
      <Flexbox gap={6} justify="space-between">
        <ProductCard
          isCardClickDisabled
          shouldHideRatingButton
          parsedProductInstanceData={parsedProductInstanceData}
          tasteBoostStatus="disabled"
        />
        <S.MascotImage alt="mascot icon" src={mascotHelloIcon} />
      </Flexbox>
      <Text
        color={STATIC_COLORS.base.white}
        localeIndex={{ productCategory }}
        localeVariables={{ productCategoryText: productCategories[productCategory] }}
        size="subtitle2"
        text={titleText}
        textAlign="center"
        weight="bold"
      />
      <Button
        isFullWidth
        fontSize="subtitle2"
        handleClick={handleClose}
        size="md"
        text={btnText}
        variant={VH_VARIANTS.LIGHT}
      />
    </S.ProductFeedbackTutorialContainer>
  );
};
