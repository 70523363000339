import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

export const selectExperiences = (state: RootState) => state?.experiences;

export const selectExperiencesData = createSelector([selectExperiences], experiences => experiences.data);

export const selectIsExperiencesLoading = createSelector([selectExperiences], experiences => experiences.isLoading);

export const selectExperiencesError = createSelector([selectExperiences], experiences => experiences.error);
