import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import TastePathContainer from '@app/web/src/containers/TastePathContainer';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { ALL_PRODUCTS_TAB, TAB_URL_PARAM, TASTE_PATH_TAB } from '@lib/tools/shared/helpers/consts';

import { ITab } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { IS_FROM_TASTE_PATH } from '@components/web/src/foundations/consts';

interface ICatalogTabsProps {
  catalogContainer: JSX.Element;
}

export const ProductCatalogTabsPage: FC<ICatalogTabsProps> = ({ catalogContainer }) => {
  const { tabs } = localeWidget;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get(TAB_URL_PARAM);

  const onTabClick = (tabCode: string) => {
    const hasUserSeenProductTab = currentTab === ALL_PRODUCTS_TAB || searchParams.get(IS_FROM_TASTE_PATH);
    const params = { [TAB_URL_PARAM]: tabCode };

    if (hasUserSeenProductTab) {
      params[IS_FROM_TASTE_PATH] = 'true';
    }

    setSearchParams(params);
  };

  const tabsData: ITab[] = [
    {
      children: <TastePathContainer />,
      isHiddenTab: false,
      tabCode: TASTE_PATH_TAB,
      tabText: tabs.yourTastePathTab,
    },
    {
      children: catalogContainer,
      isHiddenTab: false,
      tabCode: ALL_PRODUCTS_TAB,
      tabText: tabs.allProductsTab,
    },
  ];

  return (
    <>
      <div className="rounded-corners" />
      <Tabs
        active={currentTab || TASTE_PATH_TAB}
        gradientVariant="custom-down"
        tabs={tabsData}
        onTabClick={onTabClick}
      />
    </>
  );
};
