/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useSelector } from 'react-redux';

import { actionResetProductFeedbackSlice } from '@lib/core/products/slices/productFeedback';
import { actionResetUserQuiz } from '@lib/core/quizzes/slices/userQuiz';
import { useRetailer } from '@lib/core/retailers/hooks';
import { actionGetRetailerDetail } from '@lib/core/retailers/slices';
import { actionResetServiceTermsList } from '@lib/core/serviceTerms/slices';
import { selectAuthError, selectAuthToken, selectIsAuthDataLoading } from '@lib/core/users/selectors/auth';
import {
  selectBestMatchCharacterForServiceProductCategory,
  selectIsCharactersAreLoading,
  selectIsUserHasAnyCharacter,
  selectIsUserHasCharacterForCurrentProductCategory,
  selectUserBestMatchCharacterByProductCategory,
  selectUserBestMatchCharacterByProductCategoryIdentifiers,
  selectUserCharacters,
  selectUserCharactersByProductCategory,
  selectUserCharactersForServiceProductCategoryArray,
  selectUserCharactersIds,
} from '@lib/core/users/selectors/characters';
import {
  selectIsProfileLoading,
  selectProfileBirthYear,
  selectProfileCountry,
  selectProfileCountryOptions,
  selectProfileGenderOptions,
  selectProfileGenderSlug,
  selectProfileId,
  selectProfileLanguage,
} from '@lib/core/users/selectors/profile';
import {
  selectIsUploadingProfilePicture,
  selectIsUserDataLoading,
  selectIsUserFullUserDataLoadedTemporaryHint,
  selectMixpanelUserDidIdentify,
  selectUserDataRaw,
  selectUserEmail,
  selectUserFirstName,
  selectUserGroups,
  selectUserId,
  selectUserImage,
  selectUserLastName,
  selectUserRole,
  selectUserSessionId,
} from '@lib/core/users/selectors/user';
import { actionResetAuthSlice } from '@lib/core/users/slices/auth';
import { actionResetUserCharactersSlice } from '@lib/core/users/slices/characters';
import { actionResetDietaryPreferenceListData } from '@lib/core/users/slices/dietaryPreference';
import { actionResetLocationLog } from '@lib/core/users/slices/locationLog';
import { actionResetProductList } from '@lib/core/users/slices/productList';
import { actionResetUserProfile } from '@lib/core/users/slices/profile';
import { actionGenerateUserSession, actionResetUserSlice } from '@lib/core/users/slices/user';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useUser = () => {
  const dispatch = useTypedDispatch();

  const { retailerSlug } = useRetailer();

  const handleResetUserData = async () => {
    dispatch(actionResetUserSlice());
    dispatch(actionResetAuthSlice());
    dispatch(actionResetUserQuiz());
    dispatch(actionResetServiceTermsList());
    dispatch(actionGenerateUserSession());
    dispatch(actionResetDietaryPreferenceListData());
    dispatch(actionResetProductList());
    dispatch(actionResetUserProfile());
    dispatch(actionResetUserCharactersSlice());
    dispatch(actionGetRetailerDetail({ retailerSlug }));
    dispatch(actionResetLocationLog());
    dispatch(actionResetProductFeedbackSlice());
  };

  return {
    handleResetUserData,
    isUserDataLoading: useSelector(selectIsUserDataLoading),
    isUploadingProfilePicture: useSelector(selectIsUploadingProfilePicture),
    isUserFullUserDataLoadedTemporaryHint: useSelector(selectIsUserFullUserDataLoadedTemporaryHint),
    isUserHasAnyCharacter: useSelector(selectIsUserHasAnyCharacter),
    isUserHasCharacterForCurrentPC: useSelector(selectIsUserHasCharacterForCurrentProductCategory),
    mixpanelUserDidIdentify: useSelector(selectMixpanelUserDidIdentify),
    userCharacters: useSelector(selectUserCharacters),
    userCharactersIds: useSelector(selectUserCharactersIds),
    userCharactersByProductCategory: useSelector(selectUserCharactersByProductCategory),
    userBestMatchCharacterByProductCategory: useSelector(selectUserBestMatchCharacterByProductCategory),
    userBestMatchCharacterByProductCategoryIdentifiers: useSelector(
      selectUserBestMatchCharacterByProductCategoryIdentifiers,
    ),
    userBestMatchCharacterForCurrentServiceProductCategory: useSelector(
      selectBestMatchCharacterForServiceProductCategory,
    ),
    userCharactersForServiceProductCategoryArray: useSelector(selectUserCharactersForServiceProductCategoryArray),
    isUserCharactersLoading: useSelector(selectIsCharactersAreLoading),
    userDataRaw: useSelector(selectUserDataRaw),
    userImage: useSelector(selectUserImage),
    userEmail: useSelector(selectUserEmail),
    userFirstName: useSelector(selectUserFirstName),
    userGroups: useSelector(selectUserGroups),
    userId: useSelector(selectUserId),
    userLastName: useSelector(selectUserLastName),
    userRole: useSelector(selectUserRole),
    userSessionId: useSelector(selectUserSessionId),
    // auth
    authToken: useSelector(selectAuthToken),
    authError: useSelector(selectAuthError),
    isAuthDataLoading: useSelector(selectIsAuthDataLoading),
    isUserAuthenticated: !!useSelector(selectAuthToken),
    // profile
    isProfileLoading: useSelector(selectIsProfileLoading),
    profileGenderSlug: useSelector(selectProfileGenderSlug),
    profileBirthYear: useSelector(selectProfileBirthYear),
    profileCountry: useSelector(selectProfileCountry),
    profileLanguage: useSelector(selectProfileLanguage),
    profileId: useSelector(selectProfileId),
    // profile options
    profileGenderOptions: useSelector(selectProfileGenderOptions),
    profileCountryOptions: useSelector(selectProfileCountryOptions),
  };
};
