import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const ProductFeedbackAnswerCardContainer = styled.button<{ $withGap: boolean }>`
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 46px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${STATIC_COLORS.base.white};
  gap: ${({ $withGap }) => ($withGap ? '16px' : '0')};
  cursor: pointer;
`;

export const AnswerColor = styled.div<{ $hexColor?: string }>`
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 4px;
  background: ${({ $hexColor }) => $hexColor};
  box-shadow: ${STATIC_SHADOWS.md};
`;

export const AnswerImage = styled.div<{ $image?: string }>`
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 4px;
  background: ${({ $image }) => $image};
  box-shadow: ${STATIC_SHADOWS.md};
`;
