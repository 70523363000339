import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TProductInstance } from '@lib/core/products/types';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  FORMAT_QUERY,
  GET_PRODUCTS_REQUEST_LIMIT,
  GPRL_CHARACTER_QUERY,
  IS_FROM_PRODUCT_DETAILS_PAGE,
  ORIGINS_QUERY,
  PRODUCTS_MAX_PRICE_QUERY,
  PRODUCTS_MIN_PRICE_QUERY,
  PRODUCT_CHARACTERISTICS_QUERY,
  PRODUCT_NAME_QUERY,
  SEARCH_BY_NAME_FIELDS_QUERY,
  STYLE_QUERY,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import {
  TProductCatalogOrdering,
  actionApplyCatalogOrdering,
  actionResetCatalogFilters,
} from '@lib/tools/filterManager/slices/productFilter';
import { updateCharactersToggleActive } from '@lib/tools/filterManager/slices/toggleFilterSlice';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import {
  actionFetchProductsListsExtended,
  actionFetchProductsListsFeatured,
  resetProductCatalogState,
  setStaticMatchedProducts,
} from '@lib/tools/productCatalog/slices';
import RouteUtils from '@lib/tools/routes';
import { removeUrlParams } from '@lib/tools/shared/helpers';
import {
  CHARACTERS_URL_PARAM,
  EXTENSIVE_CATALOG,
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ITEM_NAME,
  FILTER_TYPE_ORDERING,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE_MAX,
  FILTER_TYPE_PRICE_RANGE_MIN,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  ORDERING_TYPE_PRICE,
  ORDERING_TYPE_RANK,
  OS_PRODUCT_NAME_QUERY,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_IDENTIFIER_QUERY,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';
import { useAddons, useEffectSkipFirst, useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { IS_FROM_TASTE_PATH } from '@components/web/src/foundations/consts';
import { ExtendedProductCatalogPage } from '@components/web/src/templates/Catalogs/Web/ProductCatalogPage/ExtendedProductCatalogPage';

const ExtendedCatalogContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isEnableTastePathAddon, isFeatureProductsAddon, isEnableAppBanner } = useAddons();

  const { handleThirdPartyAcceptance, isShowThirdPartyAcceptance, thirdPartyNewsletterTexts } = useThirdParty();

  const { productCategory, shouldHideDownloadAppCard } = useApp();
  const { retailerName } = useRetailer();
  const { retailerStoreType } = useRetailerLocation();
  const characterStringFromUrl = searchParams.get(CHARACTERS_URL_PARAM);
  const isFromProductDetailsPage = !!searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE);
  const isFromTastePath = !!searchParams.get(IS_FROM_TASTE_PATH);

  const [featuredProductsOutOfSwiper, setFeaturedProductsOutOfSwiper] = useState<TProductInstance[]>([]);
  const { characters } = useCharacters();
  const { userBestMatchCharacterForCurrentServiceProductCategory, isUserAuthenticated } = useUser();

  const characterIds = (
    characterStringFromUrl || userBestMatchCharacterForCurrentServiceProductCategory?.characterId
  )?.split('-');
  const characterIdsParam = characterIds?.join(',');

  const charactersData = characters.filter(character => isCharacterByIdentifiers(character, characterIds));

  const {
    filterManager: { isFilterOpened },
  } = useTypedSelector(state => state.productFilters);
  const {
    isProductsRequestLoading,
    lastDataLoadedTime,
    featuredProducts,
    lastFeaturedProductsLoadedTime,
    staticMatchedProducts,
    products,
    itemsCount,
  } = useProductCatalog();
  const {
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicsQuery,
    [FILTER_TYPE_FORMAT]: selectedFormatsQuery,
    [FILTER_TYPE_ORIGIN]: selectedOriginsQuery,
    [FILTER_TYPE_STYLE]: selectedStylesQuery,
    [FILTER_TYPE_PRICE_RANGE_MAX]: priceRangeMaxQuery,
    [FILTER_TYPE_PRICE_RANGE_MIN]: priceRangeMinQuery,
    [FILTER_TYPE_ITEM_NAME]: searchTextQuery,
    [FILTER_TYPE_SHOW_FAVORITES]: selectedWishlistProductsQuery,
    [FILTER_TYPE_CHARACTER_TOGGLE]: isUseMyCharacterToggleEnabled,
    [FILTER_TYPE_ORDERING]: activeOrdering,
    [FILTER_TYPE_RATING]: selectedRatedProductsQuery,
    lastPriceRangeChangeWasManual,
  } = useCatalogFilter();
  const [paginationOccurrence, setPaginationOccurrence] = useState(false);

  const handleProductsRequest = ({ isPagination = false }: IHandleProductsRequestParams) => {
    const paginationParams = {
      limit: GET_PRODUCTS_REQUEST_LIMIT,
      offset: isPagination ? products.length : 0,
    };

    const filterParams = {};
    filterParams[PRODUCT_CATEGORY_QUERY] = productCategory;

    if (lastPriceRangeChangeWasManual) {
      filterParams[PRODUCTS_MAX_PRICE_QUERY] = priceRangeMaxQuery;
      filterParams[PRODUCTS_MIN_PRICE_QUERY] = priceRangeMinQuery;
    }

    if (searchTextQuery) {
      filterParams[PRODUCT_NAME_QUERY] = `*${searchTextQuery}*`;
      filterParams[SEARCH_BY_NAME_FIELDS_QUERY] = OS_PRODUCT_NAME_QUERY;
    }

    if (selectedOriginsQuery) {
      filterParams[ORIGINS_QUERY] = selectedOriginsQuery;
    }

    if (selectedFormatsQuery) {
      filterParams[FORMAT_QUERY] = selectedFormatsQuery;
    }

    if (selectedStylesQuery) {
      filterParams[STYLE_QUERY] = selectedStylesQuery;
    }

    if (selectedCharacteristicsQuery) {
      filterParams[PRODUCT_CHARACTERISTICS_QUERY] = selectedCharacteristicsQuery;
    }

    if (characterIdsParam && isUseMyCharacterToggleEnabled) {
      filterParams[GPRL_CHARACTER_QUERY] = characterIdsParam;
    }

    if (activeOrdering) {
      filterParams[FILTER_TYPE_ORDERING] = activeOrdering;
    }

    if (selectedWishlistProductsQuery) {
      filterParams[PRODUCT_IDENTIFIER_QUERY] = selectedWishlistProductsQuery;
    }

    if (selectedRatedProductsQuery) {
      filterParams[PRODUCT_IDENTIFIER_QUERY] = selectedRatedProductsQuery;
    }

    if (selectedWishlistProductsQuery && selectedRatedProductsQuery) {
      /** Both filters use the same PRODUCT_IDENTIFIER_QUERY parameter.
       * If both filters are applied we need to find common Ids between them .
       */

      const findCommonProductIds = (wishlist, feedback) =>
        wishlist
          .split('%')
          .filter(item => feedback.includes(item))
          .map(identifier => `%${identifier}`)
          .join('');

      filterParams[PRODUCT_IDENTIFIER_QUERY] = findCommonProductIds(
        selectedWishlistProductsQuery,
        selectedRatedProductsQuery,
      );
    }

    setPaginationOccurrence(isPagination);

    if (!isPagination && featuredProductsOutOfSwiper.length) {
      setFeaturedProductsOutOfSwiper([]);
    }

    return dispatch(
      actionFetchProductsListsExtended({
        isPagination,
        params: {
          ...paginationParams,
          ...filterParams,
        },
        shouldShuffleResponse: activeOrdering !== ORDERING_TYPE_PRICE,
      }),
    );
  };

  useEffect(() => {
    if (!isFromProductDetailsPage && !isFromTastePath) {
      handleProductsRequest({});
    }
  }, []);

  useEffect(() => {
    // We reset catalog/filter when catalog is unmounted and a new page ISN'T a product page or Taste Path tab
    // We don't reset it if a new page IS a product page or Taste Path tab, to have smooth and correct UI after pressing
    // back button or switch back to "All products" tab from "Taste Path" tab
    return () => {
      if (
        !RouteUtils.getPage().includes(PAGES.vinhood.product) &&
        !RouteUtils.getPage().includes(PAGES.vinhood.catalog)
      ) {
        dispatch(actionResetCatalogFilters());
        dispatch(resetProductCatalogState());
        dispatch(setStaticMatchedProducts([]));
      }
    };
  }, []);

  const handleCancelCharacter = () => {
    dispatch(updateCharactersToggleActive({ value: false }));
  };

  useEffect(() => {
    if (isFeatureProductsAddon && !isFromProductDetailsPage && !isFromTastePath) {
      const params = {
        [FILTER_TYPE_ORDERING]: ORDERING_TYPE_RANK,
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        ...(characterIdsParam && { [GPRL_CHARACTER_QUERY]: characterIdsParam }),
      };
      dispatch(actionFetchProductsListsFeatured({ params }));
    }
  }, [isFeatureProductsAddon]);

  useEffect(() => {
    MixpanelTracker.events.productCatalogView(productCategory);

    if (isFromProductDetailsPage) {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE],
      });
    }
  }, []);

  const generateSwiperProducts = () => {
    let swiperProducts: TProductInstance[] = [];

    const getFilteredProducts = (productsToFilter: TProductInstance[], productsToExclude: TProductInstance[]) =>
      productsToFilter.filter(product => !productsToExclude.some(ex => ex.identifier === product.identifier));

    if (isFeatureProductsAddon) {
      if (featuredProducts.length > 3) {
        const filteredFeatureProducts = getFilteredProducts(featuredProducts, staticMatchedProducts);

        swiperProducts = [...staticMatchedProducts, ...filteredFeatureProducts].slice(0, 3);
        setFeaturedProductsOutOfSwiper(filteredFeatureProducts.slice(3));
      } else {
        const filteredFeatureProducts = getFilteredProducts(featuredProducts, staticMatchedProducts);
        const filteredProducts = getFilteredProducts(products, [...staticMatchedProducts, ...featuredProducts]);

        swiperProducts = [...staticMatchedProducts, ...filteredFeatureProducts, ...filteredProducts].slice(0, 3);
      }
    } else {
      const filteredProducts = getFilteredProducts(products, staticMatchedProducts);

      swiperProducts = [...staticMatchedProducts, ...filteredProducts].slice(0, 3);
    }
    dispatch(setStaticMatchedProducts(swiperProducts));
  };

  useEffectSkipFirst(() => {
    if (lastDataLoadedTime && (!isFeatureProductsAddon || lastFeaturedProductsLoadedTime)) {
      generateSwiperProducts();
    }
  }, [lastFeaturedProductsLoadedTime, lastDataLoadedTime, isFeatureProductsAddon]);

  const calculatedProducts = useMemo(
    () => [
      ...featuredProductsOutOfSwiper,
      ...products.filter(
        product => !staticMatchedProducts.some(featureProduct => featureProduct.identifier === product.identifier),
      ),
    ],
    [products, featuredProducts, staticMatchedProducts],
  );
  const charactersFilterTagsData = useMemo(
    () =>
      charactersData.map(character => ({
        filterType: FILTER_TYPE_CHARACTER_TOGGLE,
        isActive: isUseMyCharacterToggleEnabled,
        name: character?.characterName,
        value: character?.characterId,
      })),
    [charactersData, isUseMyCharacterToggleEnabled],
  );

  const filterProps = {
    additionalCharacters: isUseMyCharacterToggleEnabled ? charactersFilterTagsData : [],
    additionalFiltersEnabled: {
      [FILTER_TYPE_CHARACTER_TOGGLE]: true,
      ...(isUserAuthenticated && { [FILTER_TYPE_RATING]: true }),
      ...(isUserAuthenticated && { [FILTER_TYPE_SHOW_FAVORITES]: true }),
    },
    handleCancelCharacter,
    isSearchEnabled: false,
  };

  const excludedProductsCount = products.length - calculatedProducts.length;
  const fixedItemsCount = typeof itemsCount === 'number' ? itemsCount - excludedProductsCount : undefined;

  useEffect(() => {
    const container = document.querySelector('.see-all-products-button');
    if (container && !paginationOccurrence) {
      container.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isFilterOpened]);

  const { discoveryQuiz } = useDiscoveryQuizData();

  const handleChangeOrdering = (newValue: TProductCatalogOrdering) => dispatch(actionApplyCatalogOrdering(newValue));

  const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner;

  const { parseProductsArray } = useParsedProducts();

  return (
    <ExtendedProductCatalogPage
      activeOrdering={activeOrdering}
      catalogType={EXTENSIVE_CATALOG}
      discoveryQuiz={discoveryQuiz}
      filterProps={filterProps}
      handleChangeOrdering={handleChangeOrdering}
      handleProductsRequest={handleProductsRequest}
      handleThirdPartyAcceptance={handleThirdPartyAcceptance}
      isChangeOrderingEnabled={!!priceRangeMaxQuery}
      isProductsLoaded={!!lastDataLoadedTime}
      isProductsRequestLoading={isProductsRequestLoading}
      isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
      itemsCount={fixedItemsCount}
      matchedProducts={parseProductsArray(staticMatchedProducts) || []}
      productCategory={productCategory}
      products={parseProductsArray(calculatedProducts)}
      retailerName={retailerName}
      shouldHideRatingButton={!isUserAuthenticated && !isEnableTastePathAddon}
      shouldHideWishlist={!isUserAuthenticated}
      shouldShowDownloadAppCard={shouldShowDownloadAppCard}
      storeType={retailerStoreType}
      thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
      withOpenedProductList={isFromProductDetailsPage}
    />
  );
};

export default ExtendedCatalogContainer;
