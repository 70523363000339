import styled, { css } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { PRODUCT_CARD_WIDTH } from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/styles';
import { skeletonLoadingAnimation, skeletonLoadingKeyframes } from '@components/web/src/organisms/Skeleton/styles';

export const ProductCardSkeletonContainer = styled.div<{ $isResponsive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ $isResponsive }) => ($isResponsive ? `100%` : PRODUCT_CARD_WIDTH.WEB)};
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};

  ${({ theme, $isResponsive }) =>
    theme?.isKioskRootElementType &&
    css`
      max-width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH.KIOSK};
      width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH.KIOSK};
    `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  min-height: 216px;
  padding: 0 0 12px 16px;
  background: ${STATIC_GRADIENTS.warmGradientReversed()};
  border-radius: 8px 8px 0 0;
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
`;
