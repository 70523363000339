import { FC, useEffect } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { GPRL_CHARACTER_QUERY, PREFERENCES_QUERY } from '@lib/core/service/consts';
import { useDietaryPreferences, useUser } from '@lib/core/users/hooks';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { IFetchProductsOptions } from '@lib/tools/productsSwiper/types/interface';
import { FILTER_TYPE_ORDERING, ORDERING_TYPE_RANK, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';

import MissedCharacterCard from '@components/web/src/organisms/Cards/MissedCharacterCard/MissedCharacterCard';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import SeeLaterCard from '@components/web/src/organisms/Cards/SeeLaterCard/SeeLaterCard';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type IProductsSwiperProps = {
  storeType: TRetailerLocationStoreType;
  discoveryQuiz: IDiscoveryQuiz;
  handleFetchSwiperProducts: (requestParams: IFetchProductsOptions) => void;
  allProducts: TParsedProductInstance[];
  isProductsLoading: boolean;
  navigateToFoodHabitsPage: () => void;
};

const ProductsSwiper: FC<IProductsSwiperProps> = ({
  storeType,
  discoveryQuiz,
  handleFetchSwiperProducts,
  allProducts,
  isProductsLoading,
  navigateToFoodHabitsPage,
}) => {
  const { userDietaryPreferenceTagsSlugsString, isUserDietaryPreferenceDataLoading } = useDietaryPreferences();

  const { userBestMatchCharacterByProductCategory } = useUser();

  useEffect(() => {
    Object.values(userBestMatchCharacterByProductCategory).forEach(character => {
      if (character && !isUserDietaryPreferenceDataLoading) {
        const requestParams = {
          [FILTER_TYPE_ORDERING]: ORDERING_TYPE_RANK,
          [GPRL_CHARACTER_QUERY]: character.characterId,
          [PRODUCT_CATEGORY_QUERY]: character.characterProductCategory.name,
          offset: 0,
        };

        if (userDietaryPreferenceTagsSlugsString) {
          requestParams[PREFERENCES_QUERY] = userDietaryPreferenceTagsSlugsString;
        }

        handleFetchSwiperProducts(requestParams);
      }
    });
  }, [userBestMatchCharacterByProductCategory, userDietaryPreferenceTagsSlugsString]);

  return (
    <SwiperHOC>
      {isProductsLoading
        ? [
            <ProductCard key={1} isLoading={isProductsLoading} storeType={storeType} />,
            <ProductCard key={2} isLoading={isProductsLoading} storeType={storeType} />,
            <ProductCard key={3} isLoading={isProductsLoading} storeType={storeType} />,
          ]
        : allProducts?.map((parsedProductInstanceData, index) =>
            parsedProductInstanceData.productInstanceId ? (
              <ProductCard
                key={parsedProductInstanceData.productInstanceId}
                discoveryQuiz={discoveryQuiz}
                isLoading={false}
                mixpanelIndex={index}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                parsedProductInstanceData={parsedProductInstanceData}
                storeType={storeType}
                variant="topBanner"
              />
            ) : (
              <MissedCharacterCard
                key={parsedProductInstanceData.productCategory}
                handleButtonClick={navigateToFoodHabitsPage}
                productCategory={parsedProductInstanceData.productCategory}
              />
            ),
          )}
      <SeeLaterCard heightBox="100%" widthBox={312} />
    </SwiperHOC>
  );
};

export default ProductsSwiper;
