import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as QuestionMarkIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_questionmark.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  title?: ILocaleText;
  subTitle: ILocaleText;
  onHintBtnClick?: () => void;
}

export const ProfileHintHeader: FC<IProps> = ({ title, subTitle, onHintBtnClick }) => (
  <Flexbox gap={0} justify="space-between">
    <Flexbox direction="column" gap={4}>
      {title && <Text color={STATIC_COLORS.base.black} size="body1" text={title} weight="semibold" />}
      {subTitle && <Text color={STATIC_COLORS.base.black} size="body1" text={subTitle} />}
    </Flexbox>
    {onHintBtnClick && <QuestionMarkIcon className="cursor-pointer" onClick={onHintBtnClick} />}
  </Flexbox>
);
