import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import CharacterMapCard from '@components/web/src/organisms/CharactersMapScroller/CharactersMap/CharacterMapCard';
import * as S from '@components/web/src/organisms/CharactersMapScroller/CharactersMap/styles';

interface IProps {
  locale?: string;
  characterTypeText: string;
  characters: IParsedCharacter[];
}

const CharactersMap: FC<IProps> = ({ characterTypeText, characters, locale }) => {
  return (
    <S.CharacterMapContainer isFullWidth direction="column" gap={8} justify="flex-start">
      <Text
        color={STATIC_COLORS.green[600]}
        fontFamily="Fraunces"
        size="h3"
        text={characterTypeText}
        weight="semibold"
      />
      <S.CharactersWrapper isFullWidth align="flex-start" className="characters-wrapper" justify="flex-start">
        {characters?.map((item, idx) => <CharacterMapCard key={idx} character={item} locale={locale} />)}
      </S.CharactersWrapper>
    </S.CharacterMapContainer>
  );
};

export default CharactersMap;
