import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import { ReactComponent as CompassIcon } from '@components/web/src/assets/icons/compass-icon.svg';
import { ReactComponent as SearchIcon } from '@components/web/src/assets/legacy/icons/icon_search.svg';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import InputField from '@components/web/src/organisms/Input/InputFields';
import { TRecipe } from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/components/RecipeItem';
import { RecipesResult } from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/components/RecipesResult';
import * as S from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/Kiosk/styles';

interface IProps {
  productCategory: TProductCategory;
  isRecipesLoading: boolean;
  recipes: TRecipe[];
  value: string;
  isNoRecipesFound: boolean;
  redirectToRecipeTest?: () => void;
  searchHandler: (searchText: string) => void;
  redirectToPreviousPage: () => void;
}

export const FoodPairingPage: FC<IProps> = ({
  productCategory,
  isRecipesLoading,
  recipes,
  value,
  isNoRecipesFound,
  redirectToRecipeTest,
  searchHandler,
  redirectToPreviousPage,
}) => {
  const {
    recipeSearchHeader,
    recipeSearchHeader: { title, noRecipesFound },
    recipePage: { searchPlaceholder },
  } = localeCommon;
  const placeholderText = LocaleUtils.publishedTerms[searchPlaceholder?.id];

  return (
    <S.FoodPairingPageContainer isFullWidth direction="column" gap={60} padding="200px 90px">
      <KioskCirclesBackground />
      <S.SearchHeaderWrapper direction="column" gap={60}>
        <Flexbox direction="column" gap={8}>
          <Text
            color={STATIC_COLORS.green[600]}
            size="body1"
            text={recipeSearchHeader[productCategory]}
            textAlign="center"
            weight="medium"
          />
          <Text color={STATIC_COLORS.base.black} size="h6" text={title} textAlign="center" weight="medium" />
        </Flexbox>
        <InputField
          icon={<SearchIcon />}
          name="search-recipe"
          placeholder={placeholderText}
          value={value}
          onChange={e => searchHandler(e.target.value)}
        />
        {isNoRecipesFound && (
          <Text color={STATIC_COLORS.base.red} size="body1" text={noRecipesFound} textAlign="center" weight="medium" />
        )}
      </S.SearchHeaderWrapper>
      <RecipesResult
        isApplicationKiosk
        isRecipesLoading={isRecipesLoading}
        productCategory={productCategory}
        recipes={recipes}
      />
      <S.BannerWrapper align="center" gap={16} padding="8px 16px" onClick={redirectToRecipeTest}>
        <CompassIcon />
        <Text
          color={STATIC_COLORS.base.black}
          size="body1"
          text={localeApp.useB2cBannersText.recipeBannerText}
          weight="semibold"
        />
        <S.GreenArrowIconStyled />
      </S.BannerWrapper>
      <BackButton handleClick={redirectToPreviousPage} variant="long" />
    </S.FoodPairingPageContainer>
  );
};
