import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';

export const ProductFeedbackResultPageContainer = styled(Flexbox)`
  flex: 1;
  background: ${STATIC_COLORS.base.white};
`;

export const StyledBackButton = styled(BackButton)`
  width: auto;
  padding: 16px;
`;

export const ProductContentWrapper = styled(Flexbox)<{
  $productCategory: string;
}>`
  background: ${({ $productCategory }) =>
    STATIC_GRADIENTS.productGradients[$productCategory] || STATIC_GRADIENTS.productGradients.wine};
  border-radius: 0px 0px 16px 16px;
  padding-top: 60px;
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 2px solid #fff;
`;

export const CheckIcon = styled(Image)`
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
  margin-top: 20px;
`;

export const VisualAnalysisWrapper = styled(Flexbox)`
  background: ${STATIC_COLORS.warmGray[100]};
  border-radius: 8px;
`;

export const Divider = styled.div`
  border-bottom: 0.5px solid ${STATIC_COLORS.base.black};
`;

export const CommentButton = styled(Button)`
  padding: 10px 12px;
  align-items: center;
  z-index: 99999;
`;
