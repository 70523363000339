import { FC, useState } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { ModalTypes } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/ReadMoreCard/styles';
import EventsModal from '@components/web/src/templates/Modals/Events/EventsModal/EventsModal';

export interface IReadMoreCardProps {
  description: string;
  title?: ILocaleText;
  minimumCharacters?: number;
  cardTitle: ILocaleText;
  productCategory?: TProductCategory;
  cardTitleColor?: string;
  cardTitleLocaleIndex?: Record<string, string>;
  isTransparentVariant?: boolean;
  isLargeVariant?: boolean;
  handleClick?: () => void;
}

const ReadMoreCard: FC<IReadMoreCardProps> = ({
  description,
  cardTitle,
  title,
  minimumCharacters = 150,
  productCategory,
  cardTitleColor,
  cardTitleLocaleIndex,
  isTransparentVariant = false,
  isLargeVariant = false,
  handleClick,
}) => {
  const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState(false);

  const { more } = localeCommon.commonMessages;
  const shouldDisplayMoreText = description?.length > minimumCharacters;

  return (
    <S.ExtendedFlexbox
      $isTransparentVariant={isTransparentVariant}
      $productCategory={productCategory}
      direction="column"
      gap={8}
    >
      <Text
        color={productCategory ? STATIC_COLORS.base.white : cardTitleColor || STATIC_COLORS.base.black}
        localeIndex={cardTitleLocaleIndex}
        size={isLargeVariant ? 'h5' : 'body1'}
        text={cardTitle}
        weight="semibold"
      />
      <Text
        color={productCategory ? STATIC_COLORS.base.white : STATIC_COLORS.base.black}
        fontFamily="Fraunces"
        size="h6"
        text={title}
        weight="semibold"
      />
      <Text
        color={productCategory ? STATIC_COLORS.base.white : STATIC_COLORS.base.black}
        size={isLargeVariant ? 'h6' : 'body2'}
        text={description?.slice(0, minimumCharacters)}
      >
        {shouldDisplayMoreText && (
          <>
            {'... '}
            <S.MoreText
              color={productCategory ? STATIC_COLORS.base.white : STATIC_COLORS.teal[600]}
              size={isLargeVariant ? 'h6' : 'body2'}
              text={more}
              weight="bold"
              handleClick={event => {
                setIsReadMoreModalOpen(prevState => !prevState);
                if (handleClick) handleClick();
                event.stopPropagation();
              }}
            />
          </>
        )}
      </Text>
      {isReadMoreModalOpen && (
        <EventsModal
          descriptionTextLeftAlign
          titleTextLeftAlignGreen
          withButton
          description={description}
          hideModal={() => setIsReadMoreModalOpen(false)}
          icon="none"
          isOpen={isReadMoreModalOpen}
          modalType={ModalTypes.InformationModal}
          title={cardTitle}
          titleLocaleIndex={cardTitleLocaleIndex}
        />
      )}
    </S.ExtendedFlexbox>
  );
};

export default ReadMoreCard;
