import styled, { keyframes } from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const scaleAnimation = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

export const ThirdPartyConsentPageContainer = styled(Flexbox)`
  background: ${STATIC_GRADIENTS.primaryGradient};
`;

export const ContentWrapper = styled.div<{ $isShowThirdPartyAcceptance: boolean }>`
  ${({ $isShowThirdPartyAcceptance }) =>
    $isShowThirdPartyAcceptance
      ? `
        & > div {
          position: fixed;
          max-width: 500px;
        }
      `
      : `
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      `}
`;

export const ProductImageWrapper = styled(Flexbox)`
  width: 210px;
  height: 251px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 15px;
  animation: ${scaleAnimation} 0.5s ease-out;

  img {
    height: 95%;
  }
`;

export const ThirdPartyConnector = styled(Flexbox)<{ $marginTop: number }>`
  flex: 1;
  z-index: 1;
  margin-top: ${({ $marginTop }) => `${$marginTop || 0}px`};
  border-radius: 15px 15px 0px 0px;
  background: ${STATIC_COLORS.base.white};
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.25);
`;

export const StyledLinkButton = styled(Button)`
  width: 100%;
  padding: 10px 14px;
  border-radius: 0;
  span {
    color: ${STATIC_COLORS.base.black};
  }
`;

export const ContinueSectionWrapper = styled(Flexbox)`
  background: ${STATIC_COLORS.base.white};
`;

export const Divider = styled.div`
  border-right: 1px solid ${STATIC_COLORS.warmGray['600']};
  height: 100%;
  width: 1px;
`;

export const Ballon = styled(Flexbox)`
  position: relative;
  border-radius: 45px 35px;
  background: ${STATIC_COLORS.teal[800]};

  img {
    position: absolute;
    bottom: -9px;
    right: 60px;
  }
`;

export const MascotIcon = styled.img`
  width: 200px;
  height: 200px;
  margin: 0 auto;
  padding: 12px 0;
  transform: scaleX(-1);
`;
