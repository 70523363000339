import styled, { DefaultTheme } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';

const BADGE_STYLES = {
  default: { gap: '9px', height: '36px', width: '60px' },
  large: { gap: '12px', height: '48px', width: '80px' },
  medium: { gap: '10px', height: '42px', width: '70px' },
  small: { gap: '10px', height: '20px', width: '46px' },
};

// TODO update this condition after using the mobile Product Card
const getSize = (variant: keyof typeof BADGE_STYLES, theme: DefaultTheme, styleProp: 'width' | 'height' | 'gap') =>
  variant === 'default' && theme.isKioskRootElementType
    ? BADGE_STYLES.medium[styleProp]
    : BADGE_STYLES[variant][styleProp];

export const PromotionBadgesContainer = styled(Flexbox)<{ $variant: keyof typeof BADGE_STYLES }>`
  gap: ${({ $variant, theme }) => getSize($variant, theme, 'gap')};
  width: 100%;
`;

export const PromotionIconWrapper = styled.div`
  height: fit-content;
`;

export const DefaultPromotionIcon = styled(Flexbox)<{ $color: string; $variant: keyof typeof BADGE_STYLES }>`
  width: ${({ $variant, theme }) => getSize($variant, theme, 'width')};
  height: ${({ $variant, theme }) => getSize($variant, theme, 'height')};
  border-radius: 4px;
  text-align: center;
  background: ${({ $color }) => $color && `${$color}`};

  span {
    line-height: normal;
    font-size: ${({ $variant }) => ($variant === 'small' ? '6px' : '8px')};
  }
`;

export const DefaultPromotionBadge = styled(Image)<{ $variant: keyof typeof BADGE_STYLES }>`
  width: ${({ $variant, theme }) => ($variant === 'small' ? '32px' : getSize($variant, theme, 'width'))};
  height: ${({ $variant, theme }) => getSize($variant, theme, 'height')};
`;

export const Badge = styled(Image)<{ $variant: keyof typeof BADGE_STYLES }>`
  width: ${({ $variant, theme }) => getSize($variant, theme, 'width')};
  height: ${({ $variant, theme }) => getSize($variant, theme, 'height')};
  border-radius: 4px;
`;
