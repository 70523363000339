import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProductFeedbackModalContainer } from '@app/web/src/containers/ProductFeedback/ProductFeedbackModalContainer';

import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { isAppInIframe, prependBasename } from '@lib/core/service/utils';
import { SERVICE_TERMS_CATEGORIES } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { useModals } from '@lib/tools/modals/hooks';
import RouteUtils from '@lib/tools/routes';
import {
  LEGAL_PAGE_COOKIE_POLICY_PAGE,
  LEGAL_PAGE_PRIVACY_POLICY_PAGE,
  LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE,
  MODALS,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { BasicServiceTermsModal } from '@components/web/src/templates/Modals/BasicServiceTermsModal/BasicServiceTermsModal';

const HocStart = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { closeAllModals, openModal, isBasicServiceTermsModalOpened } = useModals();

  const { productFeedbackModalOpenedForCurrentRtl } = useProductFeedback();
  const {
    updateServiceTerms,
    serviceTermsListUpdateIsLoading,
    isTermsInitialDataLoading,
    serviceTermsByCategory: { cookies },
  } = useServiceTerms();

  const isLegalPage = [
    LEGAL_PAGE_COOKIE_POLICY_PAGE,
    LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE,
    LEGAL_PAGE_PRIVACY_POLICY_PAGE,
    PAGES.vinhood.legal.generalServiceTerms,
  ].some(legalPage => RouteUtils.getPage().includes(legalPage));
  const isRedirectPage = RouteUtils.getPage().includes(PAGES.vinhood.redirect);

  useEffect(() => {
    if (!isAppInIframe && !cookies?.is_selected && !isLegalPage) {
      openModal(MODALS.TERMS_OF_SERVICE_BASIC_MODAL);
    }
  }, [location, isTermsInitialDataLoading, cookies]);
  const handleOpenGeneralServiceTermsPage = () => {
    closeAllModals();
    navigate(prependBasename(PAGES.vinhood.legal.generalServiceTerms));
  };
  const handleAcceptAllBasicServiceTerms = () => {
    updateServiceTerms([
      { category: SERVICE_TERMS_CATEGORIES.cookies, is_selected: true },
      { category: SERVICE_TERMS_CATEGORIES.profiling, is_selected: true },
    ]).then(() => closeAllModals());
  };
  const handleRejectOptionalBasicServiceTerms = () => {
    updateServiceTerms([
      { category: SERVICE_TERMS_CATEGORIES.cookies, is_selected: true },
      { category: SERVICE_TERMS_CATEGORIES.profiling, is_selected: false },
    ]).then(() => closeAllModals());
  };

  return (
    <>
      {children}
      {!isAppInIframe && isBasicServiceTermsModalOpened && !isLegalPage && !isRedirectPage && (
        <BasicServiceTermsModal
          handleAcceptAllBasicServiceTerms={handleAcceptAllBasicServiceTerms}
          handleOpenGeneralServiceTermsPage={handleOpenGeneralServiceTermsPage}
          handleRejectOptionalBasicServiceTerms={handleRejectOptionalBasicServiceTerms}
          isDisabled={isTermsInitialDataLoading}
          isLoading={serviceTermsListUpdateIsLoading || isTermsInitialDataLoading}
          isModalOpen={isBasicServiceTermsModalOpened}
        />
      )}
      {productFeedbackModalOpenedForCurrentRtl && <ProductFeedbackModalContainer />}
    </>
  );
};

export default HocStart;
