import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import {
  ALL_PRODUCTS_TAB,
  CHARACTERS_URL_PARAM,
  PRODUCT_CATEGORY_URL_PARAM,
  TAB_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import SkeletonWrapper from '@components/web/src/app/Skeleton/SkeletonWrapper';
import RecipePlaceholder from '@components/web/src/assets/legacy/backgrounds/recipe_placeholder.jpg';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { RecipeItemSkeleton } from '@components/web/src/organisms/Skeleton/RecipeItemSkeleton/RecipeItemSkeleton';
import * as S from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/components/styles';

export type TRecipe = {
  name?: string;
  image?: string;
  identifier: string;
  isLoading?: boolean;
  characters?: TCharacter[];
  slug: string;
};

interface IProps {
  data?: TRecipe;
  productCategory: TProductCategory;
  isLoading?: boolean;
  isDesignSetVinhoodApp?: boolean;
  isApplicationKiosk?: boolean;
}

export const RecipeItem: FC<IProps> = ({
  data,
  productCategory,
  isLoading,
  isDesignSetVinhoodApp = false,
  isApplicationKiosk = false,
}) => {
  const { name = '', image, characters = [] } = data || {};
  const navigate = useNavigate();
  const charactersUrlQuery = useMemo(() => {
    return characters.map(characterData => characterData.identifier).join(',');
  }, [characters]);

  const imageUrl = image || RecipePlaceholder;

  const handleRecipeClick = () => {
    if (isLoading) return;

    if (isDesignSetVinhoodApp) {
      navigate(
        prependBasename(PAGES.vinhood.catalog, {
          [CHARACTERS_URL_PARAM]: charactersUrlQuery,
          [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    } else if (isApplicationKiosk) {
      navigate(
        prependBasename(PAGES.vinhood.quizResults.situational, {
          [CHARACTERS_URL_PARAM]: charactersUrlQuery,
          [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
        }),
      );
    } else {
      navigate(
        prependBasename(PAGES.vinhood.catalog, {
          [CHARACTERS_URL_PARAM]: charactersUrlQuery,
          [TAB_URL_PARAM]: ALL_PRODUCTS_TAB,
        }),
      );
    }

    MixpanelTracker.events.recipeClick(data);
  };

  return (
    <SkeletonWrapper showSkeleton={isLoading} skeleton={RecipeItemSkeleton}>
      <S.RecipeItemContainer
        align="center"
        gap={16}
        role="button"
        tabIndex={-1}
        onClick={handleRecipeClick}
        onKeyDown={handleRecipeClick}
      >
        <S.ImageWrapper>
          <Image
            alt={`recipe ${name}`}
            src={imageUrl}
            onError={e => {
              e.currentTarget.src = RecipePlaceholder;
            }}
          />
        </S.ImageWrapper>
        <Text linesLimit={3} size="body1" text={name} weight="medium" />
      </S.RecipeItemContainer>
    </SkeletonWrapper>
  );
};
