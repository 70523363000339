import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBanners } from '@lib/core/banners/hooks/banners';
import { actionGetBanners } from '@lib/core/banners/slices/banners';
import { TBanner } from '@lib/core/banners/types';
import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import {
  selectAllFeedbacksByProductId,
  selectIsAnyProductFeedbackCompleted,
  selectIsProductFeedbackAnswersSubmitLoading,
  selectIsProductFeedbackDataOrOptionsLoading,
  selectOpenedFeedbackModalProductInstanceData,
  selectProductFeedbackCurrentQuestion,
  selectProductFeedbackCurrentQuestionAllAnswersAreLoaded,
  selectProductFeedbackDataLocale,
  selectProductFeedbackModalProductIsOpenedForCurrentRtl,
  selectProductFeedbackModalType,
  selectProductFeedbackQuestions,
  selectProductFeedbackQuestionsData,
  selectProductFeedbackTutorialProduct,
} from '@lib/core/products/selectors/productFeedback';
import {
  IProductFeedbackAnswer,
  ProductFeedbackSteps,
  actionDoneProductFeedbackAnswer,
  actionForwardProductFeedbackStep,
  actionGetExistingProductFeedbacks,
  actionGetProductFeedbackQuestions,
  actionResetProductFeedbackFlow,
  actionSaveProductFeedbackAnswer,
  actionSubmitProductFeedbackAnswers,
  actionToggleOpenedFeedbackModal,
  actionToggleProductFeedbackTutorial,
} from '@lib/core/products/slices/productFeedback';
import { TParsedProductInstance, TProductInstance } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import RouteUtils from '@lib/tools/routes';
import { PAGES } from '@lib/tools/views/urls';

export type TToggleOpenedFeedbackModalProductProps = {
  productFeedbackModalStep?: ProductFeedbackSteps;
  productInstanceData?: TProductInstance;
};

export const useProductFeedback = () => {
  const dispatch = useDispatch();
  const currentFeedbackProductInstanceData = useSelector(selectOpenedFeedbackModalProductInstanceData);
  const productFeedbackModalOpenedForCurrentRtl = useSelector(selectProductFeedbackModalProductIsOpenedForCurrentRtl);
  const productFeedbackQuestionsData = useSelector(selectProductFeedbackQuestionsData);
  const productFeedbackCurrentQuestion = useSelector(selectProductFeedbackCurrentQuestion);
  const serviceProductCategory = useSelector(selectServiceProductCategory);
  const isProductFeedbackAnswersSubmitLoading = useSelector(selectIsProductFeedbackAnswersSubmitLoading);
  const isProductFeedbackDataOrOptionsLoading = useSelector(selectIsProductFeedbackDataOrOptionsLoading);
  const productFeedbackStep = useSelector(selectProductFeedbackModalType);
  const productFeedbackQuestions = useSelector(selectProductFeedbackQuestions);
  const productFeedbackDataLocale = useSelector(selectProductFeedbackDataLocale);
  const finishedProductFeedbacksByProductId = useSelector(selectAllFeedbacksByProductId);
  const productFeedbackTutorialProduct = useSelector(selectProductFeedbackTutorialProduct);
  const isAnyProductFeedbackCompleted = useSelector(selectIsAnyProductFeedbackCompleted);
  const isProductFeedbackCurrentQuestionAllAnswersAreLoaded = useSelector(
    selectProductFeedbackCurrentQuestionAllAnswersAreLoaded,
  );

  const { parseProduct } = useParsedProducts();
  const { retailerLocationId } = useRetailerLocation();
  const { productFeedbackBannersData } = useBanners();
  const { characters } = useCharacters();
  const { locale } = useApp();

  const isCatalogPage = RouteUtils.getPage().includes(PAGES.vinhood.catalog);
  const isProductPage = RouteUtils.getPage().includes(PAGES.vinhood.product);
  const isTasteIdPage =
    RouteUtils.getPage().includes(PAGES.vinhood.tasteId.tasteId) ||
    RouteUtils.getPage().includes(PAGES.vinhood.tasteId.tasteIdProductsJournal);

  const parsedProductInstanceData = parseProduct(currentFeedbackProductInstanceData);
  const finishedFeedbacksForProduct = finishedProductFeedbacksByProductId[parsedProductInstanceData?.productId];

  const { productCharacterId, productCategory, productId } = parsedProductInstanceData || {};

  const productCharacterTypeSlug = useMemo(
    () => characters.find(character => isCharacterByIdentifiers(character, [productCharacterId]))?.characterType?.slug,
    [characters, productId],
  );

  const getProductFeedbackQuestions = () =>
    dispatch(
      actionGetProductFeedbackQuestions({
        characterTypeSlug: productCharacterTypeSlug,
        locale,
        productCategory,
        productId,
      }),
    );
  const getProductFeedbackBanners = () => dispatch(actionGetBanners({ characterTypeSlug: productCharacterTypeSlug }));

  const handleToggleOpenedFeedbackModal = ({
    productInstanceData,
    productFeedbackModalStep,
  }: TToggleOpenedFeedbackModalProductProps = {}) => {
    dispatch(
      actionToggleOpenedFeedbackModal({
        productFeedbackModalStep,
        productInstanceData,
        retailerLocationId,
        serviceProductCategory,
      }),
    );
  };

  const handleProductFeedbackAnswerDone = ({ questionId }: { questionId: string }) =>
    dispatch(actionDoneProductFeedbackAnswer({ questionId }));

  const handleSaveProductFeedbackAnswer = ({ answer }: { answer: IProductFeedbackAnswer }) =>
    dispatch(
      actionSaveProductFeedbackAnswer({
        answer,
        context: productFeedbackCurrentQuestion.context,
        product: currentFeedbackProductInstanceData.product.identifier,
        question: productFeedbackCurrentQuestion.identifier,
      }),
    );

  const handleForwardProductFeedbackStep = () => dispatch(actionForwardProductFeedbackStep());
  const handleResetProductFeedbackFlow = () => dispatch(actionResetProductFeedbackFlow());
  const handleSubmitProductFeedbackAnswers = async () => {
    await dispatch(actionSubmitProductFeedbackAnswers());
  };

  const getAllSubmittedProductFeedbacks = (recordLocale?: boolean) =>
    dispatch(actionGetExistingProductFeedbacks(recordLocale ? { locale } : {}));

  const handleToggleProductFeedbackTutorial = ({
    parsedProductInstance,
  }: {
    parsedProductInstance?: TParsedProductInstance;
  }) => dispatch(actionToggleProductFeedbackTutorial({ parsedProductInstance }));

  const productFeedbackBanners: TBanner[] = useMemo(
    () =>
      getMultipleUniqueRandomItemsFromArray(
        productFeedbackBannersData,
        productFeedbackQuestionsData?.choices?.questions?.length,
      ),
    [productFeedbackBannersData, productFeedbackQuestionsData?.name],
  );

  const isRouteToResetProductFeedbackFlow = !isProductPage && !isCatalogPage && !isTasteIdPage;

  return {
    finishedFeedbacksForProduct,
    getAllSubmittedProductFeedbacks,
    getProductFeedbackBanners,
    getProductFeedbackQuestions,
    handleForwardProductFeedbackStep,
    handleProductFeedbackAnswerDone,
    handleResetProductFeedbackFlow,
    handleSaveProductFeedbackAnswer,
    handleSubmitProductFeedbackAnswers,
    handleToggleOpenedFeedbackModal,
    handleToggleProductFeedbackTutorial,
    isAnyProductFeedbackCompleted,
    isProductFeedbackAnswersSubmitLoading,
    isProductFeedbackCurrentQuestionAllAnswersAreLoaded,
    isProductFeedbackDataOrOptionsLoading,
    isRouteToResetProductFeedbackFlow,
    parsedProductInstanceData,
    productFeedbackBanners,
    productFeedbackCurrentQuestion,
    productFeedbackDataLocale,
    productFeedbackModalOpenedForCurrentRtl,
    productFeedbackQuestions,
    productFeedbackQuestionsData,
    productFeedbackStep,
    productFeedbackTutorialProduct,
  };
};
