import styled from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';
import { TAuthBanner } from '@lib/tools/shared/helpers/consts';

import { STATIC_COLORS } from '@components/web/src/foundations/index';
import { Text } from '@components/web/src/foundations/Text/Text';

interface ButtonProps {
  orientation?: 'Left' | 'Right';
}

export const RegisterExtraInfoContainer = styled.div`
  width: 100%;
`;

export const InfoCard = styled.div<{ $productCategory: TProductCategory; $withRoundedCorners: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  width: 100%;
  border-radius: ${({ $withRoundedCorners }) => ($withRoundedCorners ? '16px' : '0px')};
  background-color: ${({ $productCategory }) => STATIC_COLORS.productColors[$productCategory][500]};
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 24px;
`;

export const LowercaseText = styled(Text)`
  text-transform: lowercase;
`;

export const SwiperButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  img {
    width: 24px;
    height: 24px;
    box-shadow: none;
  }
  transform: ${props => (props.orientation === 'Left' ? 'rotate(180deg)' : 'none')};
`;

export const Background = styled.div<{ $productCategory: TProductCategory }>`
  display: flex;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
  ${({ $productCategory }) => `background-color: ${STATIC_COLORS.productColors[$productCategory][50]};`};
`;

export const ImageWrapper = styled.div<{ variant: TAuthBanner }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;

  img {
    width: ${({ variant }) => (variant === 'adjective' ? '85px' : '140px')};
    height: ${({ variant }) => (variant === 'adjective' ? '85px' : '140px')};
    z-index: 2;
  }
`;

export const DescriptionText = styled(Text)<{ variant: TAuthBanner }>`
  width: 100%;
  min-height: 72px;
  overflow: hidden;
  margin-bottom: ${({ variant }) => (variant === 'adjective' ? '20px' : 0)};

  ${({ variant }) =>
    variant === 'character' &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
   
    

    &:after {
      content: '';
      width: 100%;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      backdrop-filter: blur(1.5px);
    }
  `}
`;
