import styled from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ProductDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${STATIC_COLORS.base.white};
`;

export const ProductImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 290px;
  overflow: hidden;
  background: ${STATIC_GRADIENTS.warmGradientReversed()};
`;

export const TopPromoIcon = styled.div`
  position: absolute;
  top: 10px;
  left: -47px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 20px;
  padding: 3px 0;
  background-color: ${STATIC_COLORS.base.red};
  transform: rotate(-45deg);

  span {
    text-transform: uppercase;
  }
`;

export const PromoText = styled(Text)`
  color: ${STATIC_COLORS.base.white};
`;

export const TagsWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: flex-end;

  img {
    width: 30px;
    height: 30px;
  }
`;

export const ImageCtaWrapper = styled(Flexbox)`
  position: absolute;
  right: 0;
  height: 100%;
  padding: 8px 4px 0;
`;

export const TastedBannerWrapper = styled.div`
  padding: 10px 0 18px 0;
  margin: 0 -16px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 0;

  & > div {
    width: 100%;
    padding: 0;
  }
`;

export const PriceAndFormatWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const PriceText = styled(Text)`
  white-space: nowrap;
  color: ${STATIC_COLORS.base.black};
`;

export const PriceDashText = styled(Text)`
  text-decoration-line: line-through;
  white-space: nowrap;
  color: ${STATIC_COLORS.gray['400']};
`;

export const CtaWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -6px;

  a,
  button {
    width: 100%;
    text-decoration: none;
  }

  button {
    margin-top: 24px;
  }
`;
