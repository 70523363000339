import { useMemo } from 'react';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByProductCategory } from '@lib/core/characters/utils/filters';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { useQuizzes } from '@lib/core/quizzes/hooks';
import { useApp } from '@lib/core/service/hooks';
import { useUser } from '@lib/core/users/hooks';
import { useAddons } from '@lib/tools/views/hooks';

import {
  HOME_PAGE_VARIANTS,
  THomePageVariants,
} from '@components/web/src/templates/Start&HomePages/Web/HomePage/HomePage';

export const useHomeData = () => {
  const { locale, productCategory } = useApp();
  const { isDisableRecipeCTAHomeAddon } = useAddons();
  const { isQuizzesFetching } = useQuizzes();
  const { characters, isCharactersLoading } = useCharacters();
  const { userBestMatchCharacterByProductCategory, isUserDataLoading, isUserAuthenticated } = useUser();
  const productCategories = [productCategory];

  const charactersData: {
    [category: string]: IParsedCharacter[];
  } | null = useMemo(() => {
    if (!characters.length || !locale) return null;

    return productCategories.reduce((acc, category) => {
      const userCharacterForPC = userBestMatchCharacterByProductCategory[category];

      const defaultAnonCharactersByPC = characters.filter(character =>
        isCharacterByProductCategory(character, category),
      );

      acc[category] = userCharacterForPC ? [userCharacterForPC] : Array(2).fill(defaultAnonCharactersByPC).flat();
      return acc;
    }, {});
  }, [characters, userBestMatchCharacterByProductCategory, locale]);

  const pageVariants: Record<string, THomePageVariants> = useMemo(() => {
    const getPageVariant = isUserHasCharacter => {
      if (isUserAuthenticated) {
        return isUserHasCharacter ? HOME_PAGE_VARIANTS.LOGGED_WITH_CHARACTER : HOME_PAGE_VARIANTS.LOGGED;
      }

      return isUserHasCharacter ? HOME_PAGE_VARIANTS.ANON_WITH_CHARACTER : HOME_PAGE_VARIANTS.ANON;
    };

    return productCategories.reduce((acc, category) => {
      const userCharacter = userBestMatchCharacterByProductCategory[category];

      acc[category] = getPageVariant(userCharacter);
      return acc;
    }, {});
  }, [userBestMatchCharacterByProductCategory, isUserAuthenticated]);

  const isRecipeQuizAvailable = !isDisableRecipeCTAHomeAddon;

  return {
    charactersData,
    isLoading: isUserDataLoading || isQuizzesFetching || isCharactersLoading,
    isRecipeQuizAvailable,
    pageVariants,
    productCategories,
  };
};
