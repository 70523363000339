import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';

import mascotMeltingIcon from '@components/web/src/assets/icons/mascot/mascot_melting_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import * as S from '@components/web/src/templates/ProductFeedback/ProductFeedbackExitAwarenessModal/styles';

interface IProps {
  isOpen: boolean;
  hideModal: () => void;
  handleContinueTasteClick: () => void;
  handleCancelTasteClick: () => void;
}

export const ProductFeedbackExitAwarenessModal: FC<IProps> = ({
  handleContinueTasteClick,
  handleCancelTasteClick,
  hideModal,
  isOpen,
}) => {
  const { heading, info, continueTasteBtn, cancelTasteBtn } = localeWidget.exitAwareness;

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isOpen} modalClassName="exit-awareness-modal">
      <Flexbox align="center" direction="column" gap={32}>
        <Text
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          size="h4"
          text={heading}
          textAlign="center"
          weight="semibold"
        />
        <Image alt="mascot" height="162px" objectFit="contain" src={mascotMeltingIcon} />
        <Text color={STATIC_COLORS.base.white} size="body1" text={info} textAlign="center" />
        <Flexbox isFullWidth direction="column" gap={8}>
          <Button isFullWidth size="md" text={continueTasteBtn} variant="light" onClick={handleContinueTasteClick} />
          <S.ExitButton isFullWidth size="md" text={cancelTasteBtn} variant="link" onClick={handleCancelTasteClick} />
        </Flexbox>
      </Flexbox>
    </ModalWrapper>
  );
};
