import { FC } from 'react';

import { HARDCODED_PRODUCT_RATE_DATA } from '@lib/core/service/consts';
import { IFilterItem } from '@lib/tools/filterManager/slices/sublistFilterSlice';

import * as S from '@components/web/src/atoms/Filters/RatingFilter/styles';
import * as SublistStyle from '@components/web/src/atoms/Filters/SublistItem/styles';
import { TSublistItemProps } from '@components/web/src/atoms/Filters/types';

interface Props {
  listContent: TSublistItemProps[];
  selectedNamesObj: Record<string, IFilterItem>;
  onItemClick: (item: TSublistItemProps) => void;
}

const RatingFilter: FC<Props> = ({ listContent, onItemClick, selectedNamesObj }) => {
  return (
    <S.RatingFilterContainer isFullWidth justify="space-between">
      {listContent?.map(item => {
        const { name } = item;

        const ratingIcon = selectedNamesObj?.[name]?.isActive
          ? HARDCODED_PRODUCT_RATE_DATA[name].icon?.activeIcon
          : HARDCODED_PRODUCT_RATE_DATA[name].icon?.inactiveIcon;

        return (
          <SublistStyle.SublistItemContainer key={name} $isRatingItem aria-hidden onClick={() => onItemClick(item)}>
            <img alt={`${name}_icon`} src={ratingIcon} />
          </SublistStyle.SublistItemContainer>
        );
      })}
    </S.RatingFilterContainer>
  );
};

export default RatingFilter;
