import { FC } from 'react';

import * as S from '@components/web/src/atoms/ProductFeedback/ProductFeedbackRateListItem/styles';
import { Image } from '@components/web/src/foundations/Image/Image';

interface IItem {
  feedbackIcon: string;
}

interface IProps extends IItem {
  onHandleClick: () => void;
}

export const ProductFeedbackRateListItem: FC<IProps> = ({ feedbackIcon, onHandleClick }) => (
  <S.ProductFeedbackListItemContainer onClick={onHandleClick}>
    <S.ProductRateIcon>
      <Image alt="feedback" maxHeight="36px" maxWidth="36px" src={feedbackIcon} />
    </S.ProductRateIcon>
  </S.ProductFeedbackListItemContainer>
);
