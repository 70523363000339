import styled, { css } from 'styled-components';

import {
  IButtonIconPosition,
  IButtonSize,
  IButtonVariants,
  IStylesProps,
} from '@components/web/src/atoms/Buttons/types';
import { STATIC_COLORS, STATIC_SHADOWS, styleMatcher } from '@components/web/src/foundations';

export const getVariantStyles = (theme, variant: IButtonVariants = 'primary') =>
  styleMatcher(variant, {
    light: () => css`
      color: ${theme?.colors?.primaryColor['-700']};
      border-width: 1px;
      border-color: ${theme?.colors?.primaryColor['-300']};
      background-color: ${STATIC_COLORS.base.white};
      box-shadow: 2px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;

      &:focus {
        box-shadow:
          0 0 0 4px #f2f4f7,
          0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }

      &:disabled {
        border-width: 1px;
        border-color: ${theme?.colors?.primaryColor['-300']};
        color: ${theme?.colors?.primaryColor['-300']};
        box-shadow: ${STATIC_SHADOWS.xs};
        background-color: ${STATIC_COLORS.base.white};

        &:hover {
          background-color: ${theme?.colors?.primaryColor['-50']};
        }
      }

      &:hover {
        background-color: ${theme?.colors?.primaryColor['-50']};
      }
    `,
    link: () => css`
      color: ${theme?.colors?.primaryColor['-700']} !important;
      background-color: transparent;
      border: none;

      &:hover {
        background-color: ${theme?.colors?.primaryColor['-50']};
      }

      &:disabled {
        color: ${STATIC_COLORS.gray[300]} !important;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }
    `,
    primary: () => css`
      background-color: ${theme?.colors?.primaryColor['-600']};
      color: ${STATIC_COLORS.base.white};
      box-shadow: 2px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
      border-width: 1px;
      border-color: ${theme?.colors?.primaryColor['-600']};

      &:focus {
        box-shadow: none;
        border-color: ${STATIC_COLORS.teal[100]};
        outline: none !important;
      }

      &:hover {
        background-color: ${theme?.colors?.primaryColor['-700']};
        box-shadow: 2px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
      }

      &:disabled {
        background-color: ${theme?.colors?.primaryColor['-200']};
        color: ${STATIC_COLORS.base.white};
        border-color: ${theme?.colors?.primaryColor['-200']};
        box-shadow: 2px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
      }
    `,
    secondary: () => css`
      color: ${theme?.colors?.secondaryColor['-900']};
      background-color: ${theme?.colors?.secondaryColor['-500']};
      border: none;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

      &:hover {
        color: ${theme?.colors?.secondaryColor['-900']};
        background-color: ${theme?.colors?.secondaryColor['-600']};
      }

      &:focus {
        box-shadow:
          0 0 0 4px #f2f4f7,
          0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }

      &:disabled {
        color: ${theme?.colors?.secondaryColor['-700']};
        background-color: ${theme?.colors?.secondaryColor['-500']};
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
    `,
    transparent: () => css`
      color: ${STATIC_COLORS.teal[700]};
      background: transparent;
      border: none;
    `,
  });

export const getSizeStyles = (size: IButtonSize = 'sm', hasText = true) =>
  styleMatcher(size, {
    lg: () => css`
      padding: 16px 28px;

      svg {
        width: 24px !important;
        height: 24px !important;
      }

      ${!hasText &&
      css`
        padding: 16px 18px;
      `}
    `,

    md: () => css`
      padding: 14px 18px;

      ${!hasText &&
      css`
        padding: 14px 12px;
      `}
    `,

    sm: () => css`
      padding: 10px 14px;

      ${!hasText &&
      css`
        min-width: 38px;
        max-width: 38px;
        padding: 10px 8px;
      `}
    `,
  });

export const Button = styled.button<IStylesProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  appearance: none;
  border-radius: 8px;
  transition: 0.3s ease-out;
  border-width: 0;
  border-color: transparent;

  &:focus {
    outline: none;
  }

  &:disabled,
  &[disabled]:hover,
  &:focus,
  &:hover {
    text-decoration: none !important;
  }

  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `}

  ${({ theme, $variant }) => getVariantStyles(theme, $variant)}
  ${({ $size, $hasText }) => getSizeStyles($size, $hasText)}
`;

export const Icon = styled.span<{ $iconPosition?: IButtonIconPosition }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  ${({ $iconPosition }) => ($iconPosition === 'right' ? `order: 1` : `order: 0`)}
`;
