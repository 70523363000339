import { FC, SyntheticEvent } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';

export type IGlobalSearchResultCardProps = {
  parsedProductInstanceData: TParsedProductInstance;
  mixpanelIndex?: number;
  mixpanelPositionContext?: MixpanelPositionContext;
  handleCTAClick: () => void;
};

const GlobalSearchResultCard: FC<IGlobalSearchResultCardProps> = ({
  parsedProductInstanceData,
  mixpanelIndex,
  mixpanelPositionContext,
  handleCTAClick,
}) => {
  const { resultCardBtn } = localeApp.searchResult;

  const {
    productCharacterName,
    productName,
    productProducerName,
    productId,
    handleUpdateWishlistProductList,
    isProductInstanceInWishlist: isLiked,
  } = parsedProductInstanceData || {};

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    // Handle all the actions on click of CTA Here after confirmation
    handleCTAClick();
  };

  return (
    <div className="global-search-result-card-container">
      <div
        className="content-wrapper"
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
        onKeyUp={handleClick}
      >
        <p className="label">{`#${productCharacterName}`}</p>
        <p className="product-name">{productName}</p>
        <p className="producer-name">{productProducerName}</p>
      </div>
      <div className="cta-wrapper">
        <Wishlist
          isItemInWishlist={isLiked}
          handleUpdateWishlist={() => {
            handleUpdateWishlistProductList(productId, productName);
            MixpanelTracker.events.productBookmark(parsedProductInstanceData, mixpanelIndex, mixpanelPositionContext);
          }}
        />
        <Button
          className="cta-button"
          size="sm"
          text={resultCardBtn}
          variant={VH_VARIANTS.LINK}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default GlobalSearchResultCard;
