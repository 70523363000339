import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ProductFeedback/ProductFeedbackStartProductSection/styles';

interface IProps {
  productImage: string;
  productName: string;
  handleClick: () => void;
}

export const ProductFeedbackStartProductSection: FC<IProps> = ({
  productImage = '',
  productName = '',
  handleClick,
}) => {
  const { startTastingBtn } = localeWidget.ProductFeedbackStartPage;

  return (
    <S.ProductFeedbackStartCardContainer>
      <S.ProductImageWrapper>
        <S.ProductImage alt="Product Image" src={productImage} />
      </S.ProductImageWrapper>
      <Text
        color={STATIC_COLORS.base.black}
        fontFamily="Fraunces"
        linesLimit={2}
        size="h4"
        text={productName}
        weight="semibold"
      />
      <Button isFullWidth fontSize="subtitle2" handleClick={handleClick} size="md" text={startTastingBtn} />
    </S.ProductFeedbackStartCardContainer>
  );
};
