import { FC, useMemo } from 'react';
import { ZodType, z } from 'zod';

import { IParsedServiceTerm, IRegisterPageServiceTerms } from '@lib/core/serviceTerms/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Authentication/EmailRegistration/styles';
import GenericAuthForm from '@components/web/src/organisms/Authentication/GenericAuthForm/GenericAuthForm';
import {
  AuthFormItemTypes,
  RegistrationFormInputObject,
} from '@components/web/src/pages/Authentication/AuthFormInterface';

interface IProps {
  registerPageServiceTerms: IRegisterPageServiceTerms;
  navigateToLoginPage?: () => void;
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  handleFormSubmit: (data: RegistrationFormInputObject) => void;
}

const EmailRegistrationForm: FC<IProps> = ({
  registerPageServiceTerms,
  navigateToLoginPage,
  handleOnServiceTermToggle,
  handleFormSubmit,
}) => {
  const {
    emailRegistrationForm,
    registerPage: { loginTitle, loginLinkTitle },
  } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const nameFieldPlaceholderText = publishedTerms[emailRegistrationForm.nameFieldPlaceholder?.id];
  const emailFieldPlaceholderText = publishedTerms[emailRegistrationForm.emailFieldPlaceholder?.id];
  const passwordFieldPlaceholderText = publishedTerms[emailRegistrationForm.passwordFieldPlaceholder?.id];
  const emailValidationText = publishedTerms[emailRegistrationForm.emailValidation?.id];
  const validEmailValidationText = publishedTerms[emailRegistrationForm.validEmailValidation?.id];
  const nameValidationText = publishedTerms[emailRegistrationForm.nameValidation?.id];
  const passwordHintText = publishedTerms[emailRegistrationForm.passwordValidation?.id];
  const passwordTooShortValidationText = publishedTerms[emailRegistrationForm.passwordTooShortValidation?.id];
  const passwordTooLongValidationText = publishedTerms[emailRegistrationForm.passwordTooLongValidation?.id];
  const passwordUpperLowerNumberValidationText =
    publishedTerms[emailRegistrationForm.passwordUpperLowerNumberValidation?.id];

  const registrationFormFields: AuthFormItemTypes<keyof RegistrationFormInputObject>[] = useMemo(
    () => [
      {
        label: emailRegistrationForm.nameField,
        name: 'name',
        placeholder: nameFieldPlaceholderText,
        type: 'text',
      },
      {
        label: emailRegistrationForm.emailField,
        name: 'email',
        placeholder: emailFieldPlaceholderText,
        type: 'email',
      },
      {
        hint: passwordHintText,
        label: emailRegistrationForm.passwordField,
        name: 'password',
        placeholder: passwordFieldPlaceholderText,
        type: 'password',
      },
    ],
    [],
  );

  const registrationSchema: ZodType<RegistrationFormInputObject> = useMemo(
    () =>
      z.object({
        email: z.string().min(1, { message: emailValidationText }).email({ message: validEmailValidationText }),
        name: z.string().min(2, { message: nameValidationText }),
        password: z
          .string()
          .min(8, { message: passwordTooShortValidationText })
          .max(30, { message: passwordTooLongValidationText })
          .regex(new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])'), passwordUpperLowerNumberValidationText),
      }),
    [],
  );

  return (
    <S.EmailRegistrationFormContainer isFullWidth direction="column" gap={32}>
      <GenericAuthForm
        isRegistrationPage
        formFields={registrationFormFields}
        handleOnServiceTermToggle={handleOnServiceTermToggle}
        registerPageServiceTerms={registerPageServiceTerms}
        submitBtnText={emailRegistrationForm.submitBtn}
        validationSchema={registrationSchema}
        onSubmitHandler={handleFormSubmit}
      />
      {navigateToLoginPage && (
        <S.LinkButton onClick={navigateToLoginPage}>
          <Text color={STATIC_COLORS.base.black} size="body1" text={loginTitle} />
          <S.LinkText size="body1" text={loginLinkTitle} weight="semibold" />
        </S.LinkButton>
      )}
    </S.EmailRegistrationFormContainer>
  );
};

export default EmailRegistrationForm;
