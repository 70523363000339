import { ReactNode, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';

import * as S from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew.styles';

interface IModalProps {
  children: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  width?: string;
  height?: string;
  isTransparent?: boolean;
}

export const ModalWrapperNew = ({ children, isOpen, onClose, width, height, isTransparent }: IModalProps) => {
  useLayoutEffect(() => {
    const rootElement = document.querySelector('#root') as HTMLDivElement;
    if (isOpen && rootElement) {
      rootElement.style.overflowY = 'hidden';
      rootElement.style.height = '100svh';
    }
    return () => {
      if (rootElement) {
        rootElement.style.overflowY = 'unset';
        rootElement.style.height = '100%';
      }
    };
  }, [isOpen]);

  if (!isOpen) return null;
  const modalRootElement =
    document.getElementById('storybook-root') ||
    document.getElementById('iframe-imitation-root') ||
    document.getElementById('root-element') ||
    document.getElementById('root');

  return ReactDOM.createPortal(
    <S.ModalWrapper $height={height} $width={width} onClick={onClose}>
      <S.ModalContent $transparent={isTransparent} onClick={e => e.stopPropagation()}>
        {children}
      </S.ModalContent>
    </S.ModalWrapper>,
    modalRootElement,
  );
};
