import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Skeleton } from '@components/web/src/organisms/Skeleton/Skeleton';

export const RecipeItemSkeleton = () => {
  return (
    <Flexbox isFullWidth>
      <Skeleton minHeight="66px" width="100px" />
      <Skeleton margin="auto 0" />
    </Flexbox>
  );
};
