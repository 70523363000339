import styled from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import {
  TProductFeedbackResultProductSectionVariant,
  TRAINING_DONE_PRODUCT_SECTION_VARIANT,
} from '@components/web/src/organisms/ProductFeedback/ProductFeedbackDoneProductSection/ProductFeedbackDoneProductSection';

export const ProductFeedbackDoneCardContainer = styled(Flexbox)<{
  $variant: TProductFeedbackResultProductSectionVariant;
}>`
  position: relative;
  width: ${({ $variant }) => ($variant === TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL ? '168px' : '293px')};
  height: ${({ $variant }) => ($variant === TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL ? '191px' : '332px')};
`;

export const Background = styled(Image)<{ $variant: TProductFeedbackResultProductSectionVariant }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ $variant }) => ($variant === TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL ? '159.68px' : '278.49px')};
  height: ${({ $variant }) => ($variant === TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL ? '182.13px' : '319.06px')};
`;

export const ProductImage = styled(Image)<{ $variant: TProductFeedbackResultProductSectionVariant }>`
  position: relative;
  z-index: 1;
  width: ${({ $variant }) => ($variant === TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL ? '168px' : '293px')};
  height: ${({ $variant }) => ($variant === TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL ? '168px' : '293px')};
  bottom: ${({ $variant }) => ($variant === TRAINING_DONE_PRODUCT_SECTION_VARIANT.SMALL ? '11px' : '20px')};
`;
