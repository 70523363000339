import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const CommentModalContainer = styled(Flexbox)`
  border-radius: 16px;
  background-color: ${STATIC_COLORS.base.white};
`;

export const ImageWrapper = styled.div`
  min-width: 25px;
`;

export const CloseIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 9px;
  padding-left: 2px;
`;

export const CommentForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 16px;

  textarea::-webkit-scrollbar {
    width: 0;
  }
`;
