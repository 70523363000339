import styled from 'styled-components';

import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ProducerFeedbackModalContainer = styled(Flexbox)`
  border-radius: 16px;
  background-color: ${STATIC_COLORS.base.white};
`;

export const CloseIcon = styled(CloseButton)`
  margin-left: auto;
`;

export const RetailerLogo = styled.img`
  width: 250px;
  height: auto;
`;

export const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 16px;

  textarea::-webkit-scrollbar {
    width: 0;
  }
`;
