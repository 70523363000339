import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';

import { STATIC_COLORS } from '@components/web/src/foundations';
import * as S from '@components/web/src/organisms/CharacterScroller/styles';

interface IProps {
  character: IParsedCharacter;
  isActive: boolean;
}

export const CharacterScrollerItem: FC<IProps> = ({ character, isActive }) => {
  const {
    characterImage,
    characterProductCategory: { name: characterProductCategory },
  } = character;
  return (
    <S.CharacterScrollerItemContainer $isActive={isActive}>
      <img alt="icon" src={characterImage} />
      <S.BackgroundIcon
        $backgroundColor={STATIC_COLORS.productColors[characterProductCategory][50]}
        $isActive={isActive}
      />
    </S.CharacterScrollerItemContainer>
  );
};
