import { FC } from 'react';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import * as S from '@components/web/src/organisms/Skeleton/ProductCardSkeleton/styles';
import { Skeleton } from '@components/web/src/organisms/Skeleton/Skeleton';

interface IProps {
  isResponsive?: boolean;
}

export const ProductCardSkeleton: FC<IProps> = ({ isResponsive = false }) => (
  <S.ProductCardSkeletonContainer $isResponsive={isResponsive}>
    <S.ImageWrapper>
      <Skeleton borderRadius="50%" minHeight="32px" width="32px" />
      <Skeleton borderRadius="50%" minHeight="32px" width="32px" />
    </S.ImageWrapper>
    <Flexbox isFullWidth align="flex-start" direction="column" gap={6} padding="16px">
      <Skeleton minHeight="16px" width="65%" />
      <Skeleton minHeight="60px" width="100%" />
      <Skeleton minHeight="18px" width="45%" />
      <Skeleton margin="8px 0" minHeight="32px" width="100%" />
      <Flexbox isFullWidth align="center" gap={0} justify="space-between">
        <Skeleton minHeight="16px" width="45%" />
        <Skeleton minHeight="24px" width="35%" />
      </Flexbox>
    </Flexbox>
    <Skeleton margin="10px auto 14px" minHeight="18px" width="30%" />
  </S.ProductCardSkeletonContainer>
);
