import { styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ProductFeedbackCommentCardContainer = styled(Flexbox)<{ $productCategory: TProductCategory }>`
  width: 100%;
  border-radius: 8px;
  background: ${({ $productCategory }) => STATIC_GRADIENTS.characterGradients[$productCategory]};
`;

export const CommentWrapper = styled(Flexbox)`
  textarea::-webkit-scrollbar {
    width: 0;
  }
`;
