import { FC } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';

import { CharacterScrollerItem } from '@components/web/src/organisms/CharacterScroller/CharacterScrollerItem';
import * as S from '@components/web/src/organisms/CharacterScroller/styles';

interface IProps {
  charactersData: IParsedCharacter[];
}

export const CharacterScroller: FC<IProps> = ({ charactersData }) => {
  const isSingleCardView = charactersData?.length === 1;
  const slidesPerView = isSingleCardView ? 1 : 3;

  return charactersData?.length ? (
    <S.CharacterScrollerContainer>
      <Swiper
        centeredSlides
        modules={[Autoplay]}
        slidesPerView={slidesPerView}
        spaceBetween={140}
        autoplay={{
          delay: 3000,
        }}
      >
        {charactersData.map((character, idx) => (
          <SwiperSlide key={idx}>
            {({ isActive }) => <CharacterScrollerItem character={character} isActive={isActive} />}
          </SwiperSlide>
        ))}
      </Swiper>
    </S.CharacterScrollerContainer>
  ) : null;
};
