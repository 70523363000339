import { FC, SyntheticEvent, useEffect, useState } from 'react';

import * as S from '@components/web/src/atoms/Wishlist/styles';
import WishlistAnimation from '@components/web/src/atoms/Wishlist/WishlistAnimation/WishlistAnimation';
import { TWishlistIconSizes, TWishlistVariant } from '@components/web/src/atoms/Wishlist/WishlistIcon/WishlistIcon';

interface IProps {
  size?: TWishlistIconSizes;
  variant?: TWishlistVariant;
  isItemInWishlist?: boolean;
  isItemListLoading?: boolean;
  handleUpdateWishlist: () => void;
}

const Wishlist: FC<IProps> = ({
  size = 'small',
  variant = 'default',
  isItemInWishlist = false,
  isItemListLoading = false,
  handleUpdateWishlist,
}) => {
  const [isStartAnimation, setIsStartAnimation] = useState(false);

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    handleUpdateWishlist();

    if (!isItemInWishlist) {
      setIsStartAnimation(true);
    }
  };

  useEffect(() => {
    if (!isItemInWishlist) return () => null;

    const timer = setTimeout(() => {
      setIsStartAnimation(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isItemInWishlist, isStartAnimation]);

  return (
    <S.WishlistContainer disabled={isItemListLoading} onClick={handleClick}>
      <WishlistAnimation
        isActive={isItemInWishlist && isStartAnimation}
        isAnimationEnded={isItemInWishlist && !isStartAnimation}
        isAnimationNotStarted={!isItemInWishlist}
        size={size}
        variant={variant}
      />
    </S.WishlistContainer>
  );
};

export default Wishlist;
