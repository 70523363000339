import { FC } from 'react';

import * as S from '@components/web/src/atoms/Wishlist/WishlistAnimation/styles';
import WishlistIcon, {
  TWishlistIconSizes,
  TWishlistVariant,
} from '@components/web/src/atoms/Wishlist/WishlistIcon/WishlistIcon';

interface IProps {
  isActive: boolean;
  isAnimationEnded?: boolean;
  isAnimationNotStarted?: boolean;
  size?: TWishlistIconSizes;
  variant?: TWishlistVariant;
}

const WishlistAnimation: FC<IProps> = ({
  isActive,
  variant = 'default',
  size = 'small',
  isAnimationEnded = false,
  isAnimationNotStarted = false,
}) => {
  return (
    <S.WishlistAnimationContainer $size={size} align="center" justify="center">
      {isAnimationNotStarted && (
        <S.RotateScale $isActive={false}>
          <WishlistIcon size={size} status="start" variant={variant} />
        </S.RotateScale>
      )}
      {isActive && (
        <>
          <S.RotateScale $isActive={isActive}>
            <WishlistIcon size={size} status="start" variant={variant} />
          </S.RotateScale>

          <S.FadeIn $isActive={isActive}>
            <WishlistIcon size={size} status="end" variant={variant} />
          </S.FadeIn>

          <S.ScaleAndSplash $isActive={isActive}>
            <WishlistIcon iconVariant="splash" size={size} variant={variant} />
          </S.ScaleAndSplash>
        </>
      )}
      {isAnimationEnded && (
        <S.FadeIn $isActive={false}>
          <WishlistIcon size={size} status="end" variant={variant} />
        </S.FadeIn>
      )}
    </S.WishlistAnimationContainer>
  );
};

export default WishlistAnimation;
