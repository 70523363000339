import { FC, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';

import { TParsedProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';

import { DESKTOP_RESOLUTION_MIN_VALUE } from '@components/web/src/foundations/consts';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type ICatalogProps = {
  isProductsRequestLoading: boolean;
  itemsCount: number;
  products: TParsedProductInstance[];
  storeType: TRetailerLocationStoreType;
  discoveryQuiz: IDiscoveryQuiz;
  handleProductsRequest: ({ isPagination }: { isPagination?: boolean }) => void;
};

const AppProductList: FC<ICatalogProps> = ({
  isProductsRequestLoading,
  itemsCount,
  products,
  storeType,
  discoveryQuiz,
  handleProductsRequest,
}) => {
  const isDesktopResolution = useMediaQuery({ minWidth: DESKTOP_RESOLUTION_MIN_VALUE });
  return (
    <InfiniteScroll
      className="app-product-list-container"
      dataLength={products.length}
      hasMore={!itemsCount || products.length < itemsCount}
      next={isProductsRequestLoading && !products.length ? null : () => handleProductsRequest({ isPagination: true })}
      scrollableTarget={isDesktopResolution ? 'root-scroll-element' : null}
      loader={
        isProductsRequestLoading ? (
          <div className="catalog-loading">
            <ProductCard key={1} isLoading={isProductsRequestLoading} storeType={storeType} />
            <ProductCard key={2} isLoading={isProductsRequestLoading} storeType={storeType} />
          </div>
        ) : null
      }
    >
      {products?.map((parsedProductInstanceData, index) => {
        return (
          <Fragment key={parsedProductInstanceData.productInstanceId}>
            <ProductCard
              isResponsive
              discoveryQuiz={discoveryQuiz}
              isLoading={false}
              mixpanelIndex={index}
              mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
              parsedProductInstanceData={parsedProductInstanceData}
              storeType={storeType}
              variant="badges"
            />
          </Fragment>
        );
      })}
    </InfiniteScroll>
  );
};

export default AppProductList;
