import { routerReducer as routing } from 'react-router-redux';

import articleCatalog from '@lib/core/articles/slices/articleCatalogSlice';
import banners from '@lib/core/banners/slices/banners';
import characters from '@lib/core/characters/slices';
import comments from '@lib/core/comments/slices/comments';
import { essenceGroups } from '@lib/core/essences/slices';
import experiences from '@lib/core/experiences/slices/experiences';
import productAttributes from '@lib/core/productAttributes/slices';
import { productsRoot } from '@lib/core/products/slices';
import productFeedback from '@lib/core/products/slices/productFeedback';
import changedNodes from '@lib/core/quizzes/slices/changedNodes';
import quiz from '@lib/core/quizzes/slices/quiz';
import quizView from '@lib/core/quizzes/slices/quizView';
import quizzes from '@lib/core/quizzes/slices/quizzes';
import scoreMatrix from '@lib/core/quizzes/slices/scoreMatrix';
import userQuiz from '@lib/core/quizzes/slices/userQuiz';
import { remoteAccess, retailer, retailerLocation } from '@lib/core/retailers/slices';
import service from '@lib/core/service/slices';
import kioskApp from '@lib/core/service/slices/kioskRootSlice';
import qaParameters from '@lib/core/service/slices/qaParameterSlice';
import { clientIp, externalCrm, technical } from '@lib/core/service/slices/technical';
import scanner from '@lib/core/service/slices/technical/scanner';
import serviceTerms from '@lib/core/serviceTerms/slices';
import { users } from '@lib/core/users/slices';
import fidelityCard from '@lib/core/users/slices/fidelityCard';
import comms from '@lib/tools/comms/slices';
import tracking from '@lib/tools/dat/slices';
import discoveryQuiz from '@lib/tools/discoveryQuiz/slices';
import exploreLocations from '@lib/tools/explore/slices';
import productFilters from '@lib/tools/filterManager/slices/productFilter';
import globalSearch from '@lib/tools/globalSearch/slices';
import modals from '@lib/tools/modals/slice';
import productCatalog from '@lib/tools/productCatalog/slices';
import productsSwiper from '@lib/tools/productsSwiper/slices/index';
import productTip from '@lib/tools/productTip/slices/';
import promotionProducts from '@lib/tools/promotionProducts/slices';
import routes from '@lib/tools/routes/slices/route';
import tastePath from '@lib/tools/tastePathProducts/slices/index';
import toastMessage from '@lib/tools/toastMessage/slices';

const reducers = {
  articleCatalog,
  banners,
  changedNodes,
  characters,
  clientIp,
  comments,
  comms,
  discoveryQuiz,
  essenceGroups,
  experiences,
  exploreLocations,
  externalCrm,
  fidelityCard,
  globalSearch,
  kioskApp,
  modals,
  productAttributes,
  productCatalog,
  productFeedback,
  productFilters,
  productTip,
  productsRoot,
  productsSwiper,
  promotionProducts,
  qaParameters,
  quiz,
  quizView,
  quizzes,
  remoteAccess,
  retailer,
  retailerLocation,
  routes,
  routing,
  scanner,
  scoreMatrix,
  service,
  serviceTerms,
  tastePath,
  technical,
  toastMessage,
  tracking,
  userQuiz,
  users,
};

export default reducers;
