import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

/**
 * Selects the entire taste path products state from the Redux store.
 */
const selectTastePath = (state: RootState) => state.tastePath;

/**
 * Selects the taste path data, containing product information for different taste path categories.
 */
export const selectTastePathData = createSelector([selectTastePath], state => state.tastePathData);

/**
 * Selects the loading state for taste path products.
 */
export const selectIsTastePathProductsLoading = createSelector(
  [selectTastePath],
  state => state.isTastePathProductsLoading,
);

/**
 * Selects the ID of the current taste path category.
 */
export const selectCurrentTastePathCategoryId = createSelector(
  [selectTastePath],
  state => state.currentTastePathCategoryId,
);

/**
 * Selects the ID of the next taste path category.
 */
export const selectNextTastePathCategoryId = createSelector([selectTastePath], state => state.nextTastePathCategoryId);
