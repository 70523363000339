import styled, { css } from 'styled-components';

export const WishlistContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: none;
  width: 48px;
  height: 48px;

  ${({ theme }) =>
    theme.isKioskRootElementType &&
    css`
      gap: 2px;
      padding-bottom: 2px;

      span {
        margin-bottom: 2px;
      }
    `}

  &:active {
    color: transparent !important;
    background-color: transparent !important;
    border: none !important;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent !important;
    border: none !important;
  }
`;
