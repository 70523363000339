import { keyframes } from 'styled-components';

export const skeletonLoadingKeyframes = keyframes`
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;

export const skeletonLoadingAnimation = '1s linear infinite alternate';
