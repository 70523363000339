import { useEffect, useState } from 'react';

import { useComments } from '@lib/core/comments/hooks/useComments';
import { actionCreateComment, actionGetComments, actionUpdateComment } from '@lib/core/comments/slices/comments';
import { ICommentHandleSubmit } from '@lib/core/comments/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_COMMENT_STATUS, MP_COMMENT_VISIBILITY } from '@lib/tools/dat/mixpanel/consts';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useCommentsHandler = () => {
  const dispatch = useTypedDispatch();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { isCommentCreating, isCommentUpdating, commentsList, isCommentsLoading } = useComments();

  const isCommentsDataLoading = isCommentsLoading || isCommentUpdating || isCommentCreating;

  const isCommentUpdateLoading = isCommentUpdating || isCommentCreating;

  const [isNewCommentsDataFetching, setIsNewCommentsDataFetching] = useState(false);
  const handleComment = async ({ comment, isProducerFeedback, parsedProductInstanceData }: ICommentHandleSubmit) => {
    const { isCommentInList, commentFromList, productId } = parsedProductInstanceData || {};

    try {
      if (isCommentInList && !isProducerFeedback) {
        await dispatch(actionUpdateComment({ identifier: commentFromList?.identifier, updateComment: comment }));

        MixpanelTracker.events.productComment(
          parsedProductInstanceData,
          MP_COMMENT_VISIBILITY.PRIVATE,
          MP_COMMENT_STATUS.EDITED,
        );
      } else {
        await dispatch(actionCreateComment({ isProducerFeedback, newComment: comment, productIdentifier: productId }));

        MixpanelTracker.events.productComment(
          parsedProductInstanceData,
          isProducerFeedback ? MP_COMMENT_VISIBILITY.PRODUCER : MP_COMMENT_VISIBILITY.PRIVATE,
          MP_COMMENT_STATUS.NEW,
        );
      }
      setIsNewCommentsDataFetching(true);
    } catch (error) {
      console.error('Comment update failed:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (isNewCommentsDataFetching && (!isCommentUpdating || !isCommentCreating)) {
      dispatch(actionGetComments());
    }
  }, [isCommentCreating, isNewCommentsDataFetching, isCommentUpdating]);

  return {
    commentsList,
    handleComment,
    isCommentUpdateLoading,
    isCommentsDataLoading,
    isDesignSetVinhoodApp,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
  };
};
