import * as S from '@components/web/src/organisms/Skeleton/CharacterDetailsSkeleton/style';
import { Skeleton } from '@components/web/src/organisms/Skeleton/Skeleton';

export const CharacterDetailsSkeleton = ({ productCategory }) => {
  return (
    <S.SkeletonCharacterDetails
      isFullWidth
      $productCategory={productCategory}
      direction="column"
      gap={4}
      padding="16px 16px 32px"
    >
      <Skeleton minHeight="16px" width="90%" />
      <Skeleton minHeight="36px" width="45%" />
      <Skeleton minHeight="18px" width="70%" />
      <Skeleton margin="12px 0" minHeight="72px" width="100%" />
      <Skeleton margin="0 0 0 auto" minHeight="18px" width="30%" />
    </S.SkeletonCharacterDetails>
  );
};
