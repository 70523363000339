import { useNavigate } from 'react-router-dom';

import CharacterMapScrollerContainer from '@app/web/src/containers/CharacterMapScrollerContainer';
import useTasteIdData from '@app/web/src/hooks/useTasteIdData';

import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks/useUser';

import { CharacterDetailsCommon } from '@components/web/src/organisms/CharacterDetailsCommon/CharacterDetailsCommon';
import RedirectFooter from '@components/web/src/templates/TasteId/TasteIdPage/RedirectFooter/RedirectFooter';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/styles';

export const CharacterDetailsContainer = () => {
  const {
    characterAdjectiveItemsData,
    userBestMatchCharacterByProductCategory,
    characterAdjectiveModalData,
    characterInfoCardsData,
    characterDetailCardsData,
  } = useTasteIdData();
  const { isUserAuthenticated } = useUser();
  const { productCategory } = useApp();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNavigateClick = (link: string) => {
    navigate(prependBasename(link));
  };
  const characterName = userBestMatchCharacterByProductCategory?.[productCategory]?.characterName;
  return (
    <S.TasteIdPageContainer>
      <S.CharacterDetailsBackButton handleClick={handleGoBack} />
      <CharacterDetailsCommon
        characterAdjectiveItemsData={characterAdjectiveItemsData?.[productCategory]}
        characterAdjectiveModalData={characterAdjectiveModalData?.[productCategory]}
        characterData={userBestMatchCharacterByProductCategory?.[productCategory]}
        characterDetailCardsData={characterDetailCardsData?.[productCategory]}
        characterInfoCardsData={characterInfoCardsData?.[productCategory]}
        isUserAuthenticated={isUserAuthenticated}
        productCategory={productCategory}
      />
      <CharacterMapScrollerContainer characterName={characterName} productCategory={productCategory} />
      {isUserAuthenticated && <RedirectFooter handleNavigateClick={handleNavigateClick} />}
    </S.TasteIdPageContainer>
  );
};
