import { useCharacters } from '@lib/core/characters/hooks';
import { useApp } from '@lib/core/service/hooks';

import CharactersMapScroller from '@components/web/src/organisms/CharactersMapScroller/CharactersMapScroller';

const CharacterMapScrollerContainer = ({ productCategory, characterName }) => {
  const {
    charactersByCharacterType: { [productCategory]: charactersByCharacterTypeForCurrentProductCategory },
  } = useCharacters();
  const { locale } = useApp();
  const charactersCount = Object.values(charactersByCharacterTypeForCurrentProductCategory).flat().length;

  return (
    <CharactersMapScroller
      charactersByCharacterTypeForCurrentProductCategory={charactersByCharacterTypeForCurrentProductCategory}
      charactersCount={charactersCount}
      groupCount={Object.keys(charactersByCharacterTypeForCurrentProductCategory).length}
      locale={locale}
      productCategory={productCategory}
      userCharacterName={characterName}
    />
  );
};

export default CharacterMapScrollerContainer;
