import cn from 'classnames';
import { FC, useEffect, useState } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  DISCOVER_QUIZ_AROMAS_ATTRIBUTE,
  DISCOVER_QUIZ_STEP_ANSWER_AROMA,
  DISCOVER_QUIZ_STEP_ANSWER_TASTE,
  DISCOVER_QUIZ_STEP_START_QUIZ,
  DISCOVER_QUIZ_TASTE_ATTRIBUTE,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import chevronRight from '@components/web/src/assets/legacy/icons/chevron_right.svg';
import headerIcon from '@components/web/src/assets/legacy/icons/tongue_icon.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import { IDiscoveryQuizAnswer } from '@components/web/src/atoms/DiscoveryQuizAnswersItem/DiscoveryQuizAnswersItem';
import DiscoveryQuizAnswers from '@components/web/src/organisms/DiscoveryQuizAnswers/DiscoveryQuizAnswers';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

export interface IDiscoveryQuizData {
  answers: IDiscoveryQuizAnswer[];
  btnText: string;
  quizTitle: string;
  quizType: string;
}

export interface IDiscoveryQuiz {
  discoveryQuizData: IDiscoveryQuizData[];
  parsedDiscoveryQuizProductInstance: TParsedProductInstance;
}

interface IProps {
  isModalOpen: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  hideModal: () => void;
}

const DiscoveryQuizModal: FC<IProps> = ({ isModalOpen, discoveryQuiz, hideModal }) => {
  const { discoveryQuizModal, productCategories } = localeCommon;

  const { discoveryQuizData, parsedDiscoveryQuizProductInstance } = discoveryQuiz;

  const [quizStep, setQuizStep] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isSelectedCorrectAnswer, setIsSelectedCorrectAnswer] = useState(null);

  const { productCategory, productName, productImage, productProducerName } = parsedDiscoveryQuizProductInstance;

  const {
    quizTitle = discoveryQuizModal.initiateQuizHeaderTitle,
    quizType = '',
    answers = [],
    btnText = discoveryQuizModal.initiateQuizBtn,
  } = discoveryQuizData[quizStep - 1] || {};

  const isInitiateQuizView = !quizStep && !selectedAnswer;
  const isSelectedAnswerQuizVew = quizStep && selectedAnswer;

  const handleCloseModal = () => {
    MixpanelTracker.events.discoverQuizSkip();

    setQuizStep(0);
    setSelectedAnswer(null);
    setIsSelectedCorrectAnswer(null);
    hideModal();
  };

  const handleAnswerClick = selectedAnswerId => {
    const correctAnswer = answers.find(answer => answer.id === selectedAnswerId)?.isCorrectAnswer;

    setSelectedAnswer(selectedAnswerId);
    setIsSelectedCorrectAnswer(correctAnswer);

    const quizAttribute =
      quizStep === DISCOVER_QUIZ_STEP_ANSWER_AROMA ? DISCOVER_QUIZ_AROMAS_ATTRIBUTE : DISCOVER_QUIZ_TASTE_ATTRIBUTE;

    MixpanelTracker.events.discoverQuizSubmit(quizAttribute, correctAnswer);
  };

  const handleButtonClick = () => {
    setQuizStep(quizStep + 1);
    setSelectedAnswer(null);
    setIsSelectedCorrectAnswer(null);

    if (quizStep === DISCOVER_QUIZ_STEP_START_QUIZ) {
      MixpanelTracker.events.discoverQuizStart();
    } else if (quizStep === DISCOVER_QUIZ_STEP_ANSWER_TASTE) {
      setQuizStep(0);
      hideModal();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSelectedAnswerQuizVew) {
        handleButtonClick();
      }

      // Total: 6000ms
      // 2000ms is delay of displaying Loading bar
      // 4000ms is animation of Loading bar
    }, 6000);

    return () => clearTimeout(timer);
  }, [isSelectedAnswerQuizVew]);

  return (
    <ModalWrapper
      maxContentHeight
      hideModal={() => handleCloseModal()}
      isOpen={isModalOpen}
      modalClassName="discovery-quiz-modal-wrapper"
    >
      <div className="discovery-quiz-modal-container">
        <div className="skip-btn-wrapper">
          <button className="skip-btn" type="button" onClick={() => handleCloseModal()}>
            <span>
              <LocaleFragment message={discoveryQuizModal.skipBtn} />
            </span>
            <img alt="button icon" src={chevronRight} />
          </button>
        </div>

        {isSelectedCorrectAnswer && <Confetti />}
        <div className="header-section">
          <p>
            <LocaleFragment message={quizTitle} />
          </p>
          <img alt="test icon" src={headerIcon} />
        </div>
        <div className="content-section">
          {quizStep ? (
            <div className="quiz-content">
              <p className="product-name">{productName}</p>
              <p className="producer-name">{productProducerName}</p>
              <div className={cn('animated-wrapper', { isAnimated: quizStep && !selectedAnswer })}>
                <p className="quiz-type">
                  <LocaleFragment message={quizType} />
                </p>
                <DiscoveryQuizAnswers
                  answers={answers}
                  handleAnswerClick={handleAnswerClick}
                  selectedAnswer={selectedAnswer}
                />
              </div>
              <p className={cn('cta-text', { isVisible: selectedAnswer })}>
                {isSelectedCorrectAnswer === true ? (
                  <LocaleFragment message={discoveryQuizModal.yesRight} />
                ) : (
                  isSelectedCorrectAnswer === false && <LocaleFragment message={discoveryQuizModal.ohNo} />
                )}
              </p>
            </div>
          ) : (
            <div className="initiate-quiz-content">
              <div className="image-wrapper">
                <img alt={productName} src={productImage} />
              </div>
              <p className="title">
                <LocaleFragment message={discoveryQuizModal.initiateQuizContentTitle} />
              </p>
              <p className="subtitle">
                <LocaleFragment
                  message={discoveryQuizModal.initiateQuizContentSubtitle}
                  options={{
                    productCategory,
                  }}
                  variables={{
                    productCategoryText: productCategories[productCategory],
                  }}
                />
              </p>
            </div>
          )}
          {(isInitiateQuizView || isSelectedAnswerQuizVew) && (
            <Button
              className={cn('cta-btn', { isVisible: selectedAnswer })}
              handleClick={handleButtonClick}
              size="sm"
              text={btnText}
              variant={VH_VARIANTS.PRIMARY}
            />
          )}
          {!!isSelectedAnswerQuizVew && (
            <div className="loading-bar-container">
              <div className="loading-bar" />
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DiscoveryQuizModal;
