import { FC } from 'react';

import { IParsedServiceTerm, IRegisterPageServiceTerms } from '@lib/core/serviceTerms/types';

import Checkbox from '@components/web/src/atoms/Checkboxes&Radio/Checkbox/Checkbox';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import * as S from '@components/web/src/organisms/Authentication/RegisterPageServiceTermsCheckboxes/styles';

interface IProps {
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  registerPageServiceTerms: IRegisterPageServiceTerms;
}

export const RegisterPageServiceTermsCheckboxes: FC<IProps> = ({
  handleOnServiceTermToggle,
  registerPageServiceTerms,
}) => {
  const { marketing, policy } = registerPageServiceTerms;

  return (
    <Flexbox isFullWidth align="flex-start" direction="column" gap={16} justify="flex-start">
      <Checkbox
        id="policy"
        isSelected={policy?.is_selected || false}
        label={<S.StyledText color={STATIC_COLORS.gray['700']} size="body1" text={policy?.text} weight="medium" />}
        onChange={() => handleOnServiceTermToggle(policy)}
      />
      <Checkbox
        id="marketing"
        isDisabled={!policy?.is_selected}
        isSelected={marketing?.is_selected || false}
        label={<S.StyledText color={STATIC_COLORS.gray['700']} size="body1" text={marketing?.text} weight="medium" />}
        onChange={() => handleOnServiceTermToggle(marketing)}
      />
    </Flexbox>
  );
};
