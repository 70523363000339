import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const ProductFeedbackListItemContainer = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: start;
  cursor: pointer;
`;

export const ProductRateIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: ${STATIC_COLORS.teal[25]};
  border-radius: 50%;
  box-shadow: ${STATIC_SHADOWS.md};
`;
