import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { useApp } from '@lib/core/service/hooks';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import tipIcon from '@components/web/src/assets/legacy/b2c/icons/icon_tip.svg';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { PRODUCT_CATEGORY_QUERY_KEY, URL_PARAMS_HINT_TYPE } from '@components/web/src/foundations/consts';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  bannerText?: ILocaleText;
  hideModal?: () => void;
  isModalOpen?: any;
  image?: string;
  params?: string;
  userBestMatchCharacterByProductCategory?: Record<string, IParsedCharacter>;
  isUserHasAnyCharacter?: boolean;
}

const HintModal: FC<IProps> = ({
  isUserHasAnyCharacter,
  userBestMatchCharacterByProductCategory,
  bannerText,
  hideModal,
  isModalOpen,
  image,
  params,
}) => {
  const { locale } = useApp();
  const productCategory = new URLSearchParams(params).get(PRODUCT_CATEGORY_QUERY_KEY) || '';
  const hintType = new URLSearchParams(params).get(URL_PARAMS_HINT_TYPE) || '';

  const attributes = userBestMatchCharacterByProductCategory[productCategory]?.characterAttributes;

  if (!isUserHasAnyCharacter || !attributes) {
    return null;
  }
  const headerDescription = attributes[`${hintType}_description_${locale}`] || '';
  const footerDescription = attributes[`${hintType}_footer_${locale}`] || '';

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="hint-modal-container">
      <div className="hint-modal-wrapper">
        <div className="hint-modal-header">
          <CloseButton handleClick={hideModal} />
          <img alt="banner icon" src={image} />
          <p className="header-title">
            <LocaleFragment message={bannerText} />
          </p>
          <span className="header-description">
            <LocaleFragment message={headerDescription} />
          </span>
        </div>
        <div className="hint-modal-footer">
          <img alt="banner icon" src={tipIcon} />
          <span className="footer-description">
            <LocaleFragment message={footerDescription} />
          </span>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default HintModal;
