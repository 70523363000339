import { styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const SkeletonCharacterDetails = styled(Flexbox)<{ $productCategory: TProductCategory }>`
  margin-bottom: -16px;
  ${({ $productCategory }) => `background-color: ${STATIC_COLORS.productColors[$productCategory][500]};`};
`;
