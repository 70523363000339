import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Question from '@components/web/src/atoms/Quiz/Question/Question';
import AnswerItem from '@components/web/src/atoms/TestAnswer/TestAnswerItem/TestAnswer';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import * as S from '@components/web/src/templates/TestFlowsPages/FoodHabitsPage/styles';

type Props = {
  answers: {
    text: ILocaleText;
    image: string;
    isDisabled: boolean;
    isSelected: boolean;
    isVisible: boolean;
    slug: string;
  }[];
  isDisabledBtn: boolean;
  isDesignSetVinhoodExperience: boolean;
  isApplicationKiosk: boolean;
  isDevToolsEnabled: boolean;
  handleUserPreferences: () => void;
  onAnswerSelectHandler: (id: string) => void;
  goBack?: () => void;
  customColors: {
    customQuizBackgroundColor: string;
    customTextColor: string;
    customSecondaryColor: string;
  };
};

const FoodHabits: FC<Props> = ({
  answers,
  isDisabledBtn,
  isDesignSetVinhoodExperience,
  isApplicationKiosk,
  isDevToolsEnabled,
  onAnswerSelectHandler,
  handleUserPreferences,
  goBack,
  customColors,
}) => {
  const { foodHabitsPage } = localeCommon;

  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = customColors;
  const customButtonArrowColor =
    isDesignSetVinhoodExperience && customSecondaryColor ? customSecondaryColor : STATIC_COLORS.teal['500'];

  return (
    <S.FoodHabitsContainer $background={customQuizBackgroundColor} direction="column" gap={24}>
      {isApplicationKiosk && <KioskCirclesBackground />}
      <Question
        customTextColor={customTextColor}
        isApplicationKiosk={isApplicationKiosk}
        title={foodHabitsPage.titleQuestionText}
      />
      <S.AnswersContainer align="center" justify="center">
        {answers.map(answer => {
          const { slug, image, text, isDisabled, isSelected, isVisible } = answer;
          return (
            isVisible && (
              <AnswerItem
                key={slug}
                isFoodHabitsQuiz
                customTextColor={customTextColor}
                id={slug}
                image={image}
                isApplicationKiosk={isApplicationKiosk}
                isDevToolsEnabled={isDevToolsEnabled}
                isDisabled={isDisabled}
                isSelected={isSelected}
                text={text}
                onSelect={id => onAnswerSelectHandler(id)}
              />
            )
          );
        })}
      </S.AnswersContainer>
      <S.StyledButton
        disabled={isDisabledBtn}
        size={isApplicationKiosk ? 'lg' : 'sm'}
        text={foodHabitsPage.ctaBtn}
        variant={VH_VARIANTS.PRIMARY}
        onClick={handleUserPreferences}
      />
      <S.GoBackButton
        customArrowColor={customButtonArrowColor}
        handleClick={goBack}
        style={{ color: customTextColor }}
        variant="long"
      />
    </S.FoodHabitsContainer>
  );
};

export default FoodHabits;
