import cn from 'classnames';
import { FC } from 'react';

import DiscoveryQuizAnswersItem, {
  IDiscoveryQuizAnswer,
} from '@components/web/src/atoms/DiscoveryQuizAnswersItem/DiscoveryQuizAnswersItem';

interface IProps {
  selectedAnswer: null | number;
  handleAnswerClick: (selectedAnswerId: number) => void;
  answers: IDiscoveryQuizAnswer[];
}

const DiscoveryQuizAnswers: FC<IProps> = ({ answers, selectedAnswer = null, handleAnswerClick }) => (
  <div className={cn('discovery-quiz-answers-container', { isSelectedAnswer: selectedAnswer })}>
    {answers.map(({ id, isCorrectAnswer, text }, index) => (
      <DiscoveryQuizAnswersItem
        key={id}
        id={id}
        index={index}
        isCorrectAnswer={isCorrectAnswer}
        isSelectedAnswer={selectedAnswer && selectedAnswer === id}
        text={text}
        handleItemClick={(event, selectedAnswerId: number) => {
          event.stopPropagation();
          if (!selectedAnswer) {
            handleAnswerClick(selectedAnswerId);
          }
        }}
      />
    ))}
  </div>
);

export default DiscoveryQuizAnswers;
