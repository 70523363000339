import { TProductInstance } from '@lib/core/products/types';
import { TQuizAnswerTag } from '@lib/core/quizzes/types';
import { getRandomItemFromArray } from '@lib/core/service/utils';
import { isQuizAnswerTagContextBlendBase, isQuizAnswerTagUserSegment } from '@lib/tools/shared/pmi/quizzes/filters';

export const shortRecommender = (gprls: TProductInstance[], answerTags: TQuizAnswerTag[]): TProductInstance[] => {
  const blendProductList = {};

  /**
   * Group Products by blend bases. Products may belong to more than one blend-base.
   * Retain the order of the products (by character correlation) in these groups.
   */
  gprls.forEach(gprl => {
    const rawProductQuizAnswerTags = gprl.product.attributes?.quiz_answer_tag_association;
    if (rawProductQuizAnswerTags.length) {
      const productQuizAnswerTags = JSON.parse(rawProductQuizAnswerTags.replaceAll("'", '"'));
      const productBlends = productQuizAnswerTags.filter((tag: string) => tag.includes('blend-base-'));

      productBlends.forEach((blend: string) => {
        const blendName = blend.replace('blend-base-', '');
        if (!blendProductList[blendName]) blendProductList[blendName] = [];
        blendProductList[blendName].push(gprl);
      });
    }
  });

  const isLAUPath = !answerTags.some(isQuizAnswerTagUserSegment);

  const selectedBlends = answerTags.filter(isQuizAnswerTagContextBlendBase);
  const userSegmentTag = answerTags.filter(isQuizAnswerTagUserSegment)?.[0];

  const selectedBlend = selectedBlends[0]?.name;

  const blendBaseTobacco = 'tobacco';
  const blendBaseMenthol = 'menthol';
  const blendBaseAromatic = 'aromatic';
  const blendBaseNegation = 'no';

  let blendsForFilter = [];
  let blendsFilteredArray = [];
  let blendsProducts = [];

  switch (selectedBlend) {
    case blendBaseTobacco:
      blendsForFilter = [blendBaseTobacco];
      blendsFilteredArray = blendProductList[blendsForFilter[0]];
      break;
    case blendBaseMenthol:
      blendsForFilter = [blendBaseMenthol];
      blendsFilteredArray = blendProductList[blendsForFilter[0]];

      break;
    case blendBaseAromatic:
      blendsForFilter = [`${blendBaseTobacco}-${blendBaseAromatic}`, `${blendBaseMenthol}-${blendBaseAromatic}`];
      blendsFilteredArray = blendProductList[blendsForFilter[0]].concat(blendProductList[blendsForFilter[1]]);
      break;
    case `${blendBaseTobacco}-${blendBaseAromatic}`:
      blendsForFilter = [`${blendBaseTobacco}-${blendBaseAromatic}`];
      blendsFilteredArray = blendProductList[blendsForFilter[0]];
      break;
    case `${blendBaseMenthol}-${blendBaseAromatic}`:
      blendsForFilter = [`${blendBaseMenthol}-${blendBaseAromatic}`];
      blendsFilteredArray = blendProductList[blendsForFilter[0]];
      break;
    case `${blendBaseNegation}-${blendBaseAromatic}`:
      blendsForFilter = [blendBaseTobacco, blendBaseMenthol];
      blendsFilteredArray = blendProductList[blendsForFilter[0]].concat(blendProductList[blendsForFilter[1]]);
      break;
    case `${blendBaseNegation}-${blendBaseTobacco}`:
      blendsForFilter = [blendBaseMenthol, `${blendBaseMenthol}-${blendBaseAromatic}`];
      blendsFilteredArray = blendProductList[blendsForFilter[0]].concat(blendProductList[blendsForFilter[1]]);
      break;
    case `${blendBaseNegation}-${blendBaseMenthol}`:
      blendsForFilter = [blendBaseTobacco, `${blendBaseTobacco}-${blendBaseAromatic}`];
      blendsFilteredArray = blendProductList[blendsForFilter[0]].concat(blendProductList[blendsForFilter[1]]);
      break;
    default:
      break;
  }

  const blendOneProducts = blendProductList[blendsForFilter[0]];
  const blendTwoProducts = blendProductList[blendsForFilter[1]];
  const isProductsInList = (gprl: TProductInstance) =>
    gprl.identifier === blendsProducts?.[0]?.identifier || gprl.identifier === blendsProducts?.[1]?.identifier;

  const filterBlendsProducts = (products: TProductInstance[]) => products.filter(gprl => !isProductsInList(gprl));

  const getSortedArray = (productsArray: TProductInstance[]) => {
    return productsArray.sort(
      (a, b) =>
        b?.tags?.[userSegmentTag?.name] ?? Number.MIN_VALUE - a?.tags?.[userSegmentTag?.name] ?? Number.MIN_VALUE,
    );
  };

  if (selectedBlends.length) {
    if (isLAUPath) {
      if (blendsForFilter?.length === 1) {
        if (blendOneProducts?.length === 1) {
          [blendsProducts[0]] = blendOneProducts;
          blendsProducts[1] = filterBlendsProducts(gprls)?.[0];
        } else if (blendOneProducts?.length >= 2) {
          [blendsProducts[0], blendsProducts[1]] = blendOneProducts;
        } else {
          [blendsProducts[0], blendsProducts[1]] = gprls;
        }
      }
      if (blendsForFilter?.length === 2) {
        blendsProducts[0] = blendOneProducts?.length ? blendOneProducts?.[0] : gprls.slice(0, 1);
        blendsProducts[1] = blendTwoProducts?.length ? blendTwoProducts?.[0] : gprls.slice(1);
      }
    } else if (blendsFilteredArray?.length >= 2) {
      blendsProducts = getSortedArray(blendsFilteredArray).slice(0, 2);
    } else if (blendsFilteredArray?.length === 1) {
      [blendsProducts[0]] = blendsFilteredArray;
      blendsProducts[1] = getSortedArray(gprls.filter(isProductsInList))?.[0];
    } else {
      blendsProducts = getSortedArray(gprls).slice(0, 2);
    }

    const filteredBlends = isLAUPath
      ? filterBlendsProducts(blendOneProducts)
      : filterBlendsProducts(blendsFilteredArray);

    const filterPromotions = (products: TProductInstance[], slug: 'promoted' | 'new') =>
      products?.filter(gprl => !isProductsInList(gprl) && gprl.promotions?.some(promotion => promotion.slug === slug));

    const promotedPromotionProductsArray = filterPromotions(gprls, 'promoted');

    const newPromotionProductsArray = filterPromotions(isLAUPath ? blendOneProducts : blendsFilteredArray, 'new');

    if (promotedPromotionProductsArray?.length) {
      blendsProducts[2] = getRandomItemFromArray(promotedPromotionProductsArray);
    } else if (newPromotionProductsArray?.length) {
      blendsProducts[2] = getRandomItemFromArray(newPromotionProductsArray);
    } else if (filteredBlends?.length) {
      blendsProducts[2] = getRandomItemFromArray(filteredBlends);
    } else {
      blendsProducts[2] = filterBlendsProducts(gprls)?.[0];
    }
  } else {
    blendsProducts = isLAUPath ? gprls.slice(0, 3) : getSortedArray(gprls).slice(0, 3);
  }

  return blendsProducts;
};
