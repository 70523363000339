import { FC } from 'react';

import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';

import fidelityCardIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/detach_fidelity_icon.svg';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/kiosk/FidelityDetachButton/styles';

type Props = {
  onClick: () => void;
};
export const FidelityDetachButton: FC<Props> = ({ onClick }) => {
  const { fidelityDetachButton } = localeKiosk;
  return (
    <S.DetachFidelityCardButton onClick={onClick}>
      <Image alt="detach icon" height="16px" maxWidth="16px" minWidth="16px" src={fidelityCardIcon} />
      <Text size="body2" text={fidelityDetachButton.fidelityCardLabel} weight="medium" />
    </S.DetachFidelityCardButton>
  );
};
