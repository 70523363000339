import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  title: ILocaleText;
  subtitle?: ILocaleText;
  className?: string;
}

export const SectionHeader: FC<IProps> = ({ title, subtitle, className }) => (
  <Flexbox className={className} direction="column" gap={4}>
    <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={title} weight="semibold" />
    {subtitle && (
      <Text
        color={STATIC_COLORS.base.black}
        size="body2"
        strongColor={STATIC_COLORS.green[600]}
        strongWeight="semibold"
        text={subtitle}
      />
    )}
  </Flexbox>
);
