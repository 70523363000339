import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { fontFamilies, generateTypographyStyles } from '@components/web/src/foundations/Text/Text.styles';
import {
  TFontFamilies,
  TFontStyle,
  TStyleWeight,
  TTextDecoration,
  TTextTransform,
  TTypographyVariants,
} from '@components/web/src/foundations/Text/types';

export const StyledTextArea = styled.textarea<{
  $size?: TTypographyVariants;
  $weight?: TStyleWeight;
  $color?: string;
  $fontFamily?: TFontFamilies;
  $fontStyle?: TFontStyle;
  $textDecoration?: TTextDecoration;
  $textTransform?: TTextTransform;
  $minHeight?: string;
}>`
  display: flex;
  width: 100%;
  align-self: stretch;
  padding: 10px 14px;
  min-height: ${({ $minHeight = '90px' }) => $minHeight};
  max-height: 240px;
  font-style: ${({ $fontStyle = 'normal' }) => $fontStyle};
  text-decoration: ${({ $textDecoration = 'none' }) => $textDecoration};
  text-transform: ${({ $textTransform = 'none' }) => $textTransform};
  border: 1px solid ${STATIC_COLORS.gray[500]};
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.xs};

  ${({ $size, $weight, $fontFamily, theme }) =>
    generateTypographyStyles($size, $fontFamily || fontFamilies.Montserrat, $weight, theme?.isKioskRootElementType)}
`;
