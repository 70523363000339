import styled from 'styled-components';

import { ReactComponent as GreenArrowIcon } from '@components/web/src/assets/icons/banner_card_arrow.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FoodPairingPageContainer = styled(Flexbox)`
  flex: 1;
  background-color: var(--color-secondary-100);
`;

export const SearchHeaderWrapper = styled(Flexbox)`
  width: 600px;
  margin: 0 auto;
`;

export const BannerWrapper = styled(Flexbox)`
  border-radius: 8px;
  background: ${STATIC_COLORS.base.white};
`;

export const GreenArrowIconStyled = styled(GreenArrowIcon)`
  margin-left: auto;
`;
