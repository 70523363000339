import { FC } from 'react';

import { HARDCODED_PRODUCT_RATE_DATA } from '@lib/core/service/consts';
import { TProductRateValue } from '@lib/core/users/slices/productRate';

import * as S from '@components/web/src/atoms/ProductRating/ProductRatingListItem/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IItem {
  productRate: TProductRateValue;
  isActive: boolean;
}

interface IProps extends IItem {
  onHandleClick: () => void;
}

export const ProductRateListItem: FC<IProps> = ({ productRate, isActive, onHandleClick }) => (
  <S.ProductRateListItemContainer onClick={onHandleClick}>
    <S.ProductRateIcon $isActive={isActive}>
      <Image
        alt="feedback"
        maxHeight="20px"
        maxWidth="20px"
        src={HARDCODED_PRODUCT_RATE_DATA[productRate].icon.activeIcon}
      />
    </S.ProductRateIcon>
    <Text
      color={STATIC_COLORS.teal[isActive ? 900 : 600]}
      size="body1"
      text={HARDCODED_PRODUCT_RATE_DATA[productRate].text}
      weight="medium"
    />
  </S.ProductRateListItemContainer>
);
