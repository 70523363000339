import React, { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';

import dialogBallonIcon from '@components/web/src/assets/icons/dialog_ballon_icon.svg';
import mascotPointingIcon from '@components/web/src/assets/icons/mascot/mascot_pointing_icon.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/ThirdPartyConsentPage/styles';

interface IProps {
  contentRef: React.MutableRefObject<HTMLDivElement>;
}

export const TastePathThirdPartyContent: FC<IProps> = ({ contentRef }) => {
  const { tastePathTitle, tastePathSubtitle, tastePathPreTitle, tastePathDialog } = localeWidget.thirdPartyConsentPage;

  return (
    <Flexbox ref={contentRef} isFullWidth direction="column" gap={16} padding="32px 16px">
      <Flexbox direction="column" gap={8}>
        <Text
          color={STATIC_COLORS.base.white}
          size="body1"
          text={tastePathPreTitle}
          textAlign="center"
          weight="medium"
        />
        <Text
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          size="h4"
          text={tastePathTitle}
          textAlign="center"
          weight="semibold"
        />
        <Text
          color={STATIC_COLORS.base.white}
          size="body1"
          text={tastePathSubtitle}
          textAlign="center"
          weight="medium"
        />
      </Flexbox>
      <S.Ballon isFullWidth align="center" direction="column" margin="0 0 20px" padding="16px">
        <Text color={STATIC_COLORS.base.white} size="body1" text={tastePathDialog} textAlign="center" />
        <img alt="dialog ballon icon" src={dialogBallonIcon} />
      </S.Ballon>
      <S.MascotIcon alt="mascot icon" src={mascotPointingIcon} />
    </Flexbox>
  );
};
