import { FC, SyntheticEvent } from 'react';

import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { TParsedProductInstance } from '@lib/core/products/types';
import { promotionsFilter } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  PROMOTION_LABEL_SLUG,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import addIcon from '@components/web/src/assets/icons/add_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { RatingButton } from '@components/web/src/atoms/ProductRating/ProductRating/RatingButton';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/ProductCard/ProductCard/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  discoveryQuiz?: IDiscoveryQuiz;
  variant?: 'compact' | 'full';
  tasteBoostStatus?: 'default' | 'disabled' | 'enabled';
  isCardClickDisabled?: boolean;
  shouldHideWishlist?: boolean;
  shouldHideRatingButton?: boolean;
  handlePeekButtonClick?: () => void;
}

export const ProductCard: FC<IProps> = ({
  parsedProductInstanceData,
  discoveryQuiz,
  variant = 'compact',
  tasteBoostStatus = 'default',
  isCardClickDisabled = false,
  shouldHideWishlist = false,
  shouldHideRatingButton = false,
  handlePeekButtonClick,
}) => {
  const { commonMessages, productCard } = localeCommon;

  const {
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productProducerName,
    productEcommerceId,
    productLocationDescription,
    productLocationMap,
    productId,
    promotions,
    showedProductOriginalPrice,
    showedProductDiscountPrice,
    productImage,
    isProductInstanceInWishlist,
    isFindProductFunctionalityEnable,
    isFindProductButtonEnable,
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    isWishlistProductListLoading,
    handleUpdateWishlistProductList,
    productInstanceData,
  } = parsedProductInstanceData || {};

  const isShowPromoLabel = !!promotionsFilter(promotions, [PROMOTION_LABEL_SLUG]).length;
  const { handleToggleOpenedFeedbackModal } = useProductFeedback();
  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  const characterTitle = `${productCharacterName && `**#${productCharacterName}** - `} ${productCharacterTechnicalCaption}`;
  const isFullVariant = variant === 'full';

  const handleAreaClick = (e: SyntheticEvent) => e.stopPropagation();

  const handleCardClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (handleToggleOpenedFeedbackModal) handleToggleOpenedFeedbackModal({ productInstanceData });
  };

  return (
    <S.ProductCardWrapper
      $isCardClickDisabled={isCardClickDisabled}
      $isTasteBoostStatusEnabled={tasteBoostStatus === 'enabled'}
      $productCategory={productCategory}
      direction="column"
      onClick={e => handleCardClick(e)}
    >
      {isShowPromoLabel && (
        <S.PromoLabel>
          <Text
            color={STATIC_COLORS.base.white}
            size="body3"
            text={commonMessages.promo}
            textTransform="uppercase"
            weight="semibold"
          />
        </S.PromoLabel>
      )}
      <S.ProductImageWrapper justify="center">
        <S.ProductImage
          $isTasteBoostStatusDisabled={tasteBoostStatus === 'disabled'}
          alt="product image"
          height="92px"
          objectFit="contain"
          src={productImage}
          width="92px"
        />
        {tasteBoostStatus === 'disabled' && <S.TasteBoostIcon alt="icon" src={addIcon} />}
        <S.ProductCtaContainer align="center" direction="column" gap={0} justify="flex-start" onClick={handleAreaClick}>
          {!shouldHideWishlist && (
            <Wishlist
              handleUpdateWishlist={() => handleUpdateWishlistProductList(productId, productName)}
              isItemInWishlist={isProductInstanceInWishlist}
              isItemListLoading={isWishlistProductListLoading}
            />
          )}
          {!shouldHideRatingButton && (
            <RatingButton discoveryQuiz={discoveryQuiz} parsedProductInstanceData={parsedProductInstanceData} />
          )}
        </S.ProductCtaContainer>
      </S.ProductImageWrapper>
      <Flexbox direction="column" gap={8} onClick={handleAreaClick}>
        {isFullVariant && <Text linesLimit={2} size="body2" text={productProducerName} weight="semibold" />}
        <Text fontFamily="Fraunces" linesLimit={2} size="subtitle1" text={productName} weight="bold" />
        {productCategory && <S.ProductCardCharacter $productCategory={productCategory} text={characterTitle} />}
        {isFullVariant && (
          <>
            <Flexbox gap={8} justify="flex-end">
              <S.StyledProductDashedPriceText size="subtitle1" text={showedProductOriginalPrice} />
              <S.StyledProductPriceText size="subtitle1" text={showedProductDiscountPrice} weight="semibold" />
            </Flexbox>
            {!!promotionBadges?.length && <PromotionBadges promotionsData={promotionBadges} variant="small" />}
          </>
        )}
        <S.ProductCardCTA
          fontSize="body3"
          text={productCard.givePeekBtn}
          textWeight="semibold"
          variant="link"
          onClick={handlePeekButtonClick}
        />
        {isFindProductFunctionalityEnable && (
          <S.FindProductWrapper role="button" tabIndex={0} onClick={handleAreaClick} onKeyDown={handleAreaClick}>
            {isFindProductButtonEnable && (
              <FindProductPopup
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                productEcommerceId={productEcommerceId}
                productLocationDescription={productLocationDescription}
                productLocationMap={productLocationMap}
              >
                <Button size="sm" text={productCard.findMeBtn} variant={VH_VARIANTS.PRIMARY} />
              </FindProductPopup>
            )}
          </S.FindProductWrapper>
        )}
      </Flexbox>
    </S.ProductCardWrapper>
  );
};
