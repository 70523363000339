import { FC, useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { IProductRatingRawApiDataWithFullProductData } from '@lib/core/users/slices/productRate';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import infoIcon from '@components/web/src/assets/legacy/b2b/icons/info_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import TastePathEnd from '@components/web/src/organisms/TastePath/TastePathEnd/TastePathEnd';
import TastePathProductItem from '@components/web/src/organisms/TastePath/TastePathProductItem/TastePathProductItem';
import {
  TJourneyStep,
  TastePathProgress,
} from '@components/web/src/organisms/TastePath/TastePathProgress/TastePathProgress';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/templates/Catalogs/Web/TastePathPage/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  producerName?: string;
  parsedProductInstanceData: TParsedProductInstance;
  retailerName?: string;
  productCategory: TProductCategory;
  isTastePathProductsLoading: boolean;
  isTastePathDone: boolean;
  productRateData: IProductRatingRawApiDataWithFullProductData[];
  tasteJourneyData: TJourneyStep[];
  nextTastePathCategory: string;
  lastUpdatedProductRatingId: string;
  discoveryQuiz: IDiscoveryQuiz;
  shouldDisplaySkipCategoryBtn: boolean;
  shouldHideWishlist: boolean;
  shouldHideRatingButton: boolean;
  isShowThirdPartyAcceptance: boolean;
  shouldShowDownloadAppCard?: boolean;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
  handleThirdPartyAcceptance: (value: boolean) => void;
  handleShowTastePathOnboarding: () => void;
  handleSkipTastePathCategory: () => void;
}

export const TastePathPage: FC<IProps> = ({
  parsedProductInstanceData,
  retailerName,
  productCategory,
  isTastePathProductsLoading,
  isTastePathDone,
  tasteJourneyData = [],
  nextTastePathCategory,
  productRateData,
  lastUpdatedProductRatingId,
  shouldDisplaySkipCategoryBtn = false,
  discoveryQuiz,
  isShowThirdPartyAcceptance,
  shouldShowDownloadAppCard,
  thirdPartyNewsletterTexts,
  shouldHideRatingButton,
  shouldHideWishlist,
  handleThirdPartyAcceptance,
  handleShowTastePathOnboarding,
  handleSkipTastePathCategory,
}) => {
  const { productCharacterTechnicalCaption, productCharacterTypeIdentifier } = parsedProductInstanceData || {};

  const {
    tastePathPage,
    tastePathPage: { btnText, alreadyTasted },
  } = localeWidget;

  const { publishedTerms } = LocaleUtils;
  const buttonText = `${publishedTerms[btnText.id]} ${nextTastePathCategory}`;

  const randomDescriptionText = useMemo(() => {
    if (productCharacterTypeIdentifier && productCategory && productCategory !== PRODUCT_CATEGORY_COFFEE) {
      const randomIndex = Math.floor(Math.random() * 3) + 1;
      return publishedTerms[
        tastePathPage[`description${productCharacterTypeIdentifier}${productCategory}${randomIndex}`]?.id
      ];
    }
    return '';
  }, [productCharacterTypeIdentifier, parsedProductInstanceData, productCategory]);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (nextTastePathCategory) {
      setAnimate(true);
    }
  }, [nextTastePathCategory]);

  const cardAnimation = useSpring({
    config: {
      friction: 30,
      tension: 300,
    },
    onRest: () => setAnimate(false),
    opacity: animate ? 0 : 1,
    transform: `translateY(${animate ? 35 : 0}px)`,
  });

  // without delaying displaying the product we break animation
  const [delayedProduct, setDelayedProduct] = useState(parsedProductInstanceData);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (parsedProductInstanceData) {
        setDelayedProduct(parsedProductInstanceData);
      }
    }, 400);

    return () => clearTimeout(timer);
  }, [parsedProductInstanceData]);

  return (
    <S.TastePathPageContainer direction="column" gap={32}>
      {isTastePathDone ? (
        <TastePathEnd />
      ) : (
        <Flexbox isFullWidth direction="column" gap={16} padding="8px 16px 0">
          <Flexbox direction="column" gap={8}>
            <Flexbox align="center" justify="space-between">
              <Text
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={tastePathPage.title}
                weight="semibold"
              />
              <S.StyledButton onClick={handleShowTastePathOnboarding}>
                <Image alt="info icon" src={infoIcon} />
              </S.StyledButton>
            </Flexbox>
            {!!tasteJourneyData.length && <TastePathProgress steps={tasteJourneyData} />}
          </Flexbox>
          <Flexbox direction="column" gap={0}>
            <Text color={STATIC_COLORS.base.black} size="body1" text={randomDescriptionText} />
            <Text
              color={STATIC_COLORS.productColors[productCategory][500]}
              size="body1"
              text={productCharacterTechnicalCaption}
              weight="medium"
            />
          </Flexbox>
          <animated.div style={cardAnimation}>
            <S.ProductCardSection>
              <ProductCard
                isResponsive
                discoveryQuiz={discoveryQuiz}
                isLoading={isTastePathProductsLoading}
                mixpanelPositionContext={MP_POSITION_CONTEXT.TASTE_PATH_PAGE}
                parsedProductInstanceData={delayedProduct}
                shouldHideRatingButton={shouldHideRatingButton}
                shouldHideWishlist={shouldHideWishlist}
                storeType="events"
              />
            </S.ProductCardSection>
          </animated.div>
        </Flexbox>
      )}
      {shouldDisplaySkipCategoryBtn && (
        <Flexbox padding="0 16px">
          <Button
            isFullWidth
            size="sm"
            text={buttonText}
            variant={VH_VARIANTS.LIGHT}
            handleClick={() => {
              MixpanelTracker.events.skipCharacterType(productCharacterTypeIdentifier);
              handleSkipTastePathCategory();
            }}
          />
        </Flexbox>
      )}
      {isShowThirdPartyAcceptance && (
        <ThirdPartyNewsletter
          handleThirdPartyAcceptance={handleThirdPartyAcceptance}
          retailerName={retailerName}
          thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
          withMarginTop={shouldShowDownloadAppCard}
        />
      )}
      {!!productRateData.length && (
        <Flexbox direction="column" gap={12} padding="16px">
          <Text color={STATIC_COLORS.green['600']} size="body1" text={alreadyTasted} weight="semibold" />
          {productRateData.map(({ product }, idx) => (
            <TastePathProductItem
              key={idx}
              isFeedbackModalDisabled={isTastePathDone}
              isUpdatedItem={product.productId === lastUpdatedProductRatingId}
              parsedProductInstanceData={product}
            />
          ))}
        </Flexbox>
      )}
    </S.TastePathPageContainer>
  );
};
