import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { PAGES } from '@lib/tools/views/urls';

import InactiveCatalogIcon from '@components/web/src/assets/icons/footer/icon_footer_cube.svg';
import ActiveCatalogIcon from '@components/web/src/assets/icons/footer/icon_footer_cube_active.svg';
import InactiveTrainingsIcon from '@components/web/src/assets/icons/footer/icon_footer_flag.svg';
import ActiveTrainingsIcon from '@components/web/src/assets/icons/footer/icon_footer_flag_active.svg';
import InactiveHomeIcon from '@components/web/src/assets/icons/footer/icon_footer_home.svg';
import ActiveHomeIcon from '@components/web/src/assets/icons/footer/icon_footer_home_active.svg';
import InactiveExperiencesIcon from '@components/web/src/assets/icons/footer/icon_footer_rocket.svg';
import ActiveExperiencesIcon from '@components/web/src/assets/icons/footer/icon_footer_rocket_active.svg';
import ActiveUserProfileIcon from '@components/web/src/assets/icons/footer/icon_footer_user_active.svg';
import InactiveUserProfileIcon from '@components/web/src/assets/icons/footer/icon_footer_user_inactive.svg';
import * as S from '@components/web/src/atoms/Footers/FooterAppLogged/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export interface IFooterAppLoggedProps {
  profileImageUrl?: string;
  isFooterDisabled?: boolean;
}

type IFooterItem = {
  icon: string;
  urlKey: string;
  text: ILocaleText;
};

export const FooterAppLogged: FC<IFooterAppLoggedProps> = ({ profileImageUrl, isFooterDisabled = false }) => {
  const { homeText, productsText, trainingsText, experiencesText, tasteIdText } = localeApp.footerApp;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onIconClick = (urlKey: string) => {
    switch (urlKey) {
      case PAGES.vinhood.home:
        navigate(prependBasename(PAGES.vinhood.home));
        break;
      case PAGES.vinhood.catalog:
        navigate(prependBasename(PAGES.vinhood.catalog), { state: { from: pathname } });
        break;
      case PAGES.vinhood.edutainment:
        navigate(prependBasename(PAGES.vinhood.edutainment));
        break;
      case PAGES.vinhood.experiences:
        navigate(prependBasename(PAGES.vinhood.experiences), { state: { from: pathname } });
        break;

      case PAGES.vinhood.tasteId.tasteId:
        navigate(prependBasename(PAGES.vinhood.tasteId.tasteId));

        break;
      default:
        navigate(prependBasename(PAGES.vinhood.home));
    }
  };

  const getCurrentIcon = (urlKey: string): string => {
    let currentIcon;

    switch (urlKey) {
      case PAGES.vinhood.home:
        currentIcon = pathname.includes(urlKey) ? ActiveHomeIcon : InactiveHomeIcon;
        break;
      case PAGES.vinhood.catalog:
        currentIcon = pathname.includes(urlKey) ? ActiveCatalogIcon : InactiveCatalogIcon;
        break;
      case PAGES.vinhood.edutainment:
        currentIcon = pathname.includes(urlKey) ? ActiveTrainingsIcon : InactiveTrainingsIcon;
        break;
      case PAGES.vinhood.experiences:
        currentIcon = pathname.includes(urlKey) ? ActiveExperiencesIcon : InactiveExperiencesIcon;
        break;
      case PAGES.vinhood.tasteId.tasteId:
        currentIcon = profileImageUrl || (pathname.includes(urlKey) ? ActiveUserProfileIcon : InactiveUserProfileIcon);
        break;
      default:
    }

    return currentIcon;
  };

  const footerItemArray: IFooterItem[] = useMemo(
    () => [
      {
        icon: getCurrentIcon(PAGES.vinhood.home),
        text: homeText,
        urlKey: PAGES.vinhood.home,
      },
      {
        icon: getCurrentIcon(PAGES.vinhood.catalog),
        text: productsText,
        urlKey: PAGES.vinhood.catalog,
      },
      {
        icon: getCurrentIcon(PAGES.vinhood.edutainment),
        text: trainingsText,
        urlKey: PAGES.vinhood.edutainment,
      },
      {
        icon: getCurrentIcon(PAGES.vinhood.experiences),
        text: experiencesText,
        urlKey: PAGES.vinhood.experiences,
      },
      {
        icon: getCurrentIcon(PAGES.vinhood.tasteId.tasteId),
        text: tasteIdText,
        urlKey: PAGES.vinhood.tasteId.tasteId,
      },
    ],
    [pathname, profileImageUrl, homeText, productsText, experiencesText, trainingsText, tasteIdText],
  );

  return (
    <S.FooterAppLoggedContainer $isFooterDisabled={isFooterDisabled}>
      <S.FooterButtonsWrapper>
        {footerItemArray.map(item => {
          const { urlKey, text, icon } = item;
          const isActive = pathname.includes(urlKey);
          return (
            <S.FooterButton
              key={urlKey}
              align="center"
              direction="column"
              gap={2}
              role="button"
              tabIndex={0}
              onClick={() => onIconClick(urlKey)}
              onKeyDown={() => onIconClick(urlKey)}
            >
              <Image
                alt={urlKey}
                borderRadius={urlKey === PAGES.vinhood.tasteId.tasteId ? '50%' : ''}
                height="24px"
                objectFit={urlKey === PAGES.vinhood.tasteId.tasteId ? 'cover' : 'none'}
                src={icon}
                width="24px"
              />

              <Text
                color={isActive ? STATIC_COLORS.base.white : STATIC_COLORS.green[200]}
                text={text}
                weight={isActive ? 'semibold' : 'medium'}
              />
            </S.FooterButton>
          );
        })}
      </S.FooterButtonsWrapper>
    </S.FooterAppLoggedContainer>
  );
};
