import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import testedIcon from '@components/web/src/assets/icons/icon_tested.svg';
import * as S from '@components/web/src/atoms/ProductCardElements/TestedBanner/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  productCategory: TProductCategory;
  characterImage: string;
}

export const TestedBanner: FC<IProps> = ({ productCategory, characterImage }) => {
  const { titleText, subtitleText } = localeWidget.testedBanner;

  return (
    <S.TestedBannerContainer $productCategory={productCategory}>
      <S.ContentWrapper>
        <S.CharacterImageWrapper align="center" gap={0} justify="center">
          <div />
          <img alt="character icon" src={characterImage} />
        </S.CharacterImageWrapper>
        <Flexbox align="flex-end" direction="column" gap={6}>
          <Flexbox align="center" gap={4}>
            <Text color={STATIC_COLORS.base.white} size="body1" text={titleText} weight="bold" />
            <img alt="icon" src={testedIcon} />
          </Flexbox>
          <Text color={STATIC_COLORS.base.white} size="body2" text={subtitleText} textDecoration="underline" />
        </Flexbox>
      </S.ContentWrapper>
    </S.TestedBannerContainer>
  );
};
