import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';

import {
  RecipeItem,
  TRecipe,
} from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/components/RecipeItem';
import * as S from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/components/styles';

interface IProps {
  recipes: TRecipe[];
  isRecipesLoading: boolean;
  productCategory: TProductCategory;
  isDesignSetVinhoodApp?: boolean;
  isApplicationKiosk?: boolean;
}

export const RecipesResult: FC<IProps> = ({
  recipes,
  isRecipesLoading,
  productCategory,
  isDesignSetVinhoodApp = false,
  isApplicationKiosk = false,
}) => {
  return (
    <S.RecipesResultContainer isFullWidth direction="column" gap={8}>
      {isRecipesLoading
        ? Array.from({ length: isApplicationKiosk ? 12 : 5 }).map((_, idx) => (
            <RecipeItem
              key={idx}
              isLoading
              isApplicationKiosk={isApplicationKiosk}
              isDesignSetVinhoodApp={isDesignSetVinhoodApp}
              productCategory={productCategory}
            />
          ))
        : recipes?.map((recipe, idx) => (
            <RecipeItem
              key={idx}
              data={recipe}
              isApplicationKiosk={isApplicationKiosk}
              isDesignSetVinhoodApp={isDesignSetVinhoodApp}
              productCategory={productCategory}
            />
          ))}
    </S.RecipesResultContainer>
  );
};
