import React, { FC, useState } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TextArea } from '@components/web/src/foundations/TextArea/TextArea';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import * as S from '@components/web/src/templates/Modals/ProducerFeedbackModal/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  retailerLogo: string;
  isModalOpened: boolean;
  handleCloseModal: () => void;
}

export const ProducerFeedbackModal: FC<IProps> = ({
  parsedProductInstanceData,
  retailerLogo,
  isModalOpened,
  handleCloseModal,
}) => {
  const { handleSubmitComment } = parsedProductInstanceData;

  const [comment, setComment] = useState('');
  const [isSubmittedComment, setIsSubmittedComment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    handleSubmitComment({ comment, isProducerFeedback: true, parsedProductInstanceData }).then(() => {
      setIsSubmittedComment(true);
      setIsSubmitting(false);
    });
  };

  const { producerModalTitle, producerModalSubmittedTitle, send, cancel, placeholderText } = localeCommon.comment;
  const { publishedTerms } = LocaleUtils;
  const textAreaPlaceholderText = publishedTerms[placeholderText?.id];

  return (
    <ModalWrapper maxContentHeight hideModal={handleCloseModal} isOpen={isModalOpened}>
      <S.ProducerFeedbackModalContainer isFullWidth direction="column" gap={16} padding="16px">
        <S.CloseIcon handleClick={handleCloseModal} variant="dark" />
        {isSubmittedComment ? (
          <Flexbox align="center" direction="column" gap={8}>
            {!!retailerLogo && <S.RetailerLogo alt="retailer logo" src={retailerLogo} />}
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h6"
              text={producerModalSubmittedTitle}
              textAlign="center"
              weight="medium"
            />
          </Flexbox>
        ) : (
          <>
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h6"
              text={producerModalTitle}
              weight="medium"
            />
            <S.CommentForm onSubmit={handleSubmit}>
              <TextArea
                placeholder={textAreaPlaceholderText}
                value={comment}
                onChange={event => setComment(event.target.value)}
              />
              <Flexbox isFullWidth direction="column" gap={8}>
                <Button disabled={!comment || isSubmitting} fontSize="subtitle2" size="md" text={send} type="submit" />
                <Button fontSize="subtitle2" size="md" text={cancel} variant="link" onClick={handleCloseModal} />
              </Flexbox>
            </S.CommentForm>
          </>
        )}
      </S.ProducerFeedbackModalContainer>
    </ModalWrapper>
  );
};
