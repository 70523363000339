import { FC, SyntheticEvent, useState } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { HARDCODED_PRODUCT_RATE_DATA } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import startIconDefault from '@components/web/src/assets/icons/productRate/rating_start_icon.svg';
import startIconLight from '@components/web/src/assets/icons/productRate/rating_start_icon_light.svg';
import { RatingTooltip } from '@components/web/src/atoms/ProductRating/ProductRating/RatingTooltip';
import * as S from '@components/web/src/atoms/ProductRating/ProductRating/styles';
import DiscoveryQuizModal, {
  IDiscoveryQuiz,
} from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import { ProductRateModal } from '@components/web/src/templates/Modals/ProductRateModal/ProductRateModal';

export type TFeedbackIconSizes = 'small' | 'medium' | 'large';

interface IProps {
  size?: TFeedbackIconSizes;
  variant?: 'default' | 'light';
  parsedProductInstanceData?: TParsedProductInstance;
  discoveryQuiz?: IDiscoveryQuiz;
  isFeedbackModalDisabled?: boolean;
  shouldShowFeedbackTooltip?: boolean;
  handleRedirectToRegistrationPage?: () => void;
}

export const RatingButton: FC<IProps> = ({
  size = 'small',
  variant = 'default',
  parsedProductInstanceData,
  discoveryQuiz,
  isFeedbackModalDisabled = false,
  shouldShowFeedbackTooltip,
}) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isDiscoveryQuizModalOpen, setIsDiscoveryQuizModalOpen] = useState(false);

  const { productRate } = parsedProductInstanceData || {};

  const { tasteId } = localeCommon;
  const isLightVariant = variant === 'light';
  const startIcon = isLightVariant ? startIconLight : startIconDefault;
  const icon =
    productRate?.image || productRate?.rateValue
      ? HARDCODED_PRODUCT_RATE_DATA[productRate?.rateValue]?.icon.activeIcon
      : startIcon;

  const handleButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsFeedbackModalOpen(true);
  };

  const isShowFeedbackTooltip = shouldShowFeedbackTooltip && !productRate;

  return (
    <>
      <S.RatingButton $isLightVariant={isLightVariant} onClick={handleButtonClick}>
        <S.RatingImage $size={size} alt="rate icon" src={icon} />
        <RatingTooltip isAnimated infoText={tasteId.tasteIdTooltipText} isShowTooltip={isShowFeedbackTooltip} />
      </S.RatingButton>

      {isFeedbackModalOpen && parsedProductInstanceData && !isFeedbackModalDisabled && (
        <ProductRateModal
          handleOpenDiscoveryQuizModal={() => setIsDiscoveryQuizModalOpen(true)}
          hideModal={() => setIsFeedbackModalOpen(false)}
          isModalOpen={isFeedbackModalOpen}
          parsedProductInstanceData={parsedProductInstanceData}
        />
      )}
      {isDiscoveryQuizModalOpen && discoveryQuiz && (
        <DiscoveryQuizModal
          discoveryQuiz={discoveryQuiz}
          hideModal={() => setIsDiscoveryQuizModalOpen(false)}
          isModalOpen={isDiscoveryQuizModalOpen}
        />
      )}
    </>
  );
};
