import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import checkCircle from '@components/web/src/assets/icons/icon_check_circle_green.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/LegalCard/style';

export type ILegalCardProps = {
  buttonText: ILocaleText;
  cardDescription: ILocaleText;
  cardTitle: ILocaleText;
  onBtnClickHandler?: () => void;
};

export const LegalCard: FC<ILegalCardProps> = ({ buttonText, cardDescription, cardTitle, onBtnClickHandler }) => (
  <S.LegalCardContainer isFullWidth direction="column" gap={16} padding="16px">
    <Flexbox gap={16}>
      <Image alt="icon" height="40px" maxWidth="40px" minWidth="40px" src={checkCircle} />
      <Flexbox direction="column" gap={8}>
        <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h6" text={cardTitle} weight="semibold" />
        <Text color={STATIC_COLORS.base.black} size="body1" text={cardDescription} />
      </Flexbox>
    </Flexbox>
    <Button size="sm" text={buttonText} onClick={onBtnClickHandler} />
  </S.LegalCardContainer>
);
