import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TProductCategory } from '@lib/core/products/types';
import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';
import useTastePathData from '@lib/core/service/hooks/useTastePathData';
import { useTastePathJourneyData } from '@lib/core/service/hooks/useTastePathJourneyData';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setIsTastePathOnboardingShown } from '@lib/core/service/slices';
import { useProductRate, useUser } from '@lib/core/users/hooks';
import {
  IProductRatingRawApiDataWithFullProductData,
  TProductRate,
  actionGetProductRateData,
} from '@lib/core/users/slices/productRate';
import { isFeedbackByProductCategory } from '@lib/core/users/utils/filters';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { setNextTastePathCategory } from '@lib/tools/tastePathProducts/slices/index';
import { useAddons } from '@lib/tools/views/hooks';

import { TastePathPage } from '@components/web/src/templates/Catalogs/Web/TastePathPage/TastePathPage';

const TastePathContainer: FC = () => {
  const dispatch = useDispatch();

  const { isEnableAppBanner } = useAddons();

  const { userSessionId, isUserDataLoading, isProfileLoading, isUserAuthenticated } = useUser();
  const { productCategory, shouldHideDownloadAppCard } = useApp();
  const {
    isTastePathProductsLoading,
    tastePathData,
    currentTastePathProduct,
    nextTastePathCategory,
    shouldChangeTastePathCategory,
    isTastePathDone,
    shouldDisplaySkipCategoryBtn,
  } = useTastePathData();
  const { tastePathJourneyData } = useTastePathJourneyData();

  const { isShowThirdPartyAcceptance, thirdPartyNewsletterTexts, handleThirdPartyAcceptance } = useThirdParty();
  const { isRetailerLocationLoading } = useRetailerLocation();
  const { isRetailerLoading, retailerName } = useRetailer();
  const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner;
  const { productRateData, lastUpdatedProductRatingId } = useProductRate();

  const { discoveryQuiz } = useDiscoveryQuizData();
  const { parseProduct } = useParsedProducts();
  // ToDO optimize flow starts with this function
  const feedbackDataFullProductData = (convertData: TProductRate[]): IProductRatingRawApiDataWithFullProductData[] => {
    const fullFeedbacksData = [];
    convertData.forEach(singleFeedbackData => {
      const { rate, product } = singleFeedbackData;
      const fullGprlData = parseProduct(product);
      const fullFeedbackData: IProductRatingRawApiDataWithFullProductData = {
        product: fullGprlData,
        productRate: rate.rateValue,
      };
      fullFeedbacksData.push(fullFeedbackData);
    });

    return fullFeedbacksData;
  };

  const filteredByCategoryRateData = useMemo(
    () =>
      feedbackDataFullProductData(
        productRateData.filter(productRate => isFeedbackByProductCategory(productRate, productCategory)),
      ),
    [productRateData, productCategory],
  );

  useEffect(() => {
    if (shouldChangeTastePathCategory && tastePathData) {
      dispatch(setNextTastePathCategory());
    }
  }, [shouldChangeTastePathCategory, tastePathData]);

  useEffect(() => {
    dispatch(actionGetProductRateData());
  }, [userSessionId]);

  const isLoading =
    isUserDataLoading ||
    isProfileLoading ||
    isRetailerLoading ||
    isRetailerLocationLoading ||
    isTastePathProductsLoading;

  return (
    <TastePathPage
      discoveryQuiz={discoveryQuiz}
      handleShowTastePathOnboarding={() => dispatch(setIsTastePathOnboardingShown(false))}
      handleSkipTastePathCategory={() => tastePathData && dispatch(setNextTastePathCategory())}
      handleThirdPartyAcceptance={handleThirdPartyAcceptance}
      isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
      isTastePathDone={isTastePathDone}
      isTastePathProductsLoading={isLoading}
      lastUpdatedProductRatingId={lastUpdatedProductRatingId}
      nextTastePathCategory={nextTastePathCategory}
      parsedProductInstanceData={parseProduct(currentTastePathProduct)}
      productCategory={productCategory as TProductCategory}
      productRateData={filteredByCategoryRateData}
      retailerName={retailerName}
      shouldDisplaySkipCategoryBtn={shouldDisplaySkipCategoryBtn}
      shouldHideRatingButton={false}
      shouldHideWishlist={!isUserAuthenticated}
      shouldShowDownloadAppCard={shouldShowDownloadAppCard}
      tasteJourneyData={tastePathJourneyData}
      thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
    />
  );
};

export default TastePathContainer;
