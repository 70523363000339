import { FC, useState } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { AUTH_BANNERS, TAuthBanner } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as SwiperIcon } from '@components/web/src/assets/legacy/icons/chevron_right.svg';
import { STATIC_COLORS } from '@components/web/src/foundations/index';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/styles';

export type TSwiperData = {
  title: string;
  description: string;
  image: string;
  productCategory: TProductCategory;
  name?: string;
  identifier?: string;
};

interface IProps {
  swiperData: TSwiperData[];
  variant?: TAuthBanner;
  withRoundedCorners?: boolean;
}

const RegisterExtraInfoSwiper: FC<IProps> = ({
  swiperData,
  variant = AUTH_BANNERS.character,
  withRoundedCorners = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (increment: number) => {
    setCurrentIndex(prevIndex => (prevIndex + increment + swiperData.length) % swiperData.length);
  };

  const visibleCharacters = swiperData?.slice(currentIndex, currentIndex + 1);

  return (
    <S.RegisterExtraInfoContainer>
      {visibleCharacters?.map((character, index) => {
        const { title, description, image, productCategory, name } = character;

        return (
          <S.InfoCard
            key={currentIndex + index}
            $productCategory={productCategory}
            $withRoundedCorners={withRoundedCorners}
          >
            <S.TextWrapper>
              <Text
                color={STATIC_COLORS.base.white}
                fontFamily="Fraunces"
                size="h4"
                text={variant === 'character' ? `#${title}` : title}
                weight="semibold"
              />
              {name && (
                <S.LowercaseText
                  color={STATIC_COLORS.base.white}
                  fontFamily="Fraunces"
                  size="h4"
                  text={name}
                  weight="semibold"
                />
              )}
            </S.TextWrapper>
            <S.ImageWrapper variant={variant}>
              <S.SwiperButton orientation="Left" role="presentation" onClick={() => handleClick(-1)}>
                <SwiperIcon />
              </S.SwiperButton>
              {variant === 'character' && <S.Background $productCategory={productCategory} />}
              <img alt="" src={image} />
              <S.SwiperButton orientation="Right" role="presentation" onClick={() => handleClick(1)}>
                <SwiperIcon />
              </S.SwiperButton>
            </S.ImageWrapper>
            <S.DescriptionText
              color={STATIC_COLORS.base.white}
              fontFamily="Montserrat"
              size="body1"
              text={description}
              variant={variant}
              weight="medium"
            />
          </S.InfoCard>
        );
      })}
    </S.RegisterExtraInfoContainer>
  );
};

export default RegisterExtraInfoSwiper;
