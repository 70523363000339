import { css, styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FooterAppLoggedContainer = styled.div<{ $isFooterDisabled?: boolean }>`
  position: sticky;
  max-height: fit-content;
  background: ${STATIC_COLORS.green[600]};

  ${({ $isFooterDisabled }) =>
    $isFooterDisabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.85;
    `}
`;

export const FooterButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 375px;
  padding: 9px 0 7px;
  margin: 0 auto;
`;

export const FooterButton = styled(Flexbox)`
  background: transparent;
  border: none;
`;
