import { useSelector } from 'react-redux';

import {
  selectExperiencesData,
  selectExperiencesError,
  selectIsExperiencesLoading,
} from '@lib/core/experiences/selectors/experiences';

export const useExperiences = () => {
  const experiencesData = useSelector(selectExperiencesData);
  const isExperiencesLoading = useSelector(selectIsExperiencesLoading);
  const experiencesError = useSelector(selectExperiencesError);

  return {
    experiencesData,
    experiencesError,
    isExperiencesLoading,
  };
};
