import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  actionGetRetailerCharacterDetails,
  actionGetRetailerCharacterEssences,
  actionGetRetailerCharacterFoodPairing,
  actionGetRetailerCharacters,
} from '@lib/core/characters/slices';
import { actionGetEssenceGroups } from '@lib/core/essences/slices/groups';
import { actionGetRecycling } from '@lib/core/productAttributes/slices/recycling';
import { actionGetWellness } from '@lib/core/productAttributes/slices/wellness';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { actionGetRetailerDetail, actionGetRetailerLocations } from '@lib/core/retailers/slices';
import { PRODUCT_CATEGORY_NONE, isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useRetailerDesignSet } from '@lib/core/service/hooks/useRetailerDesignSet';
import { actionGetServiceInstanceRetailerData } from '@lib/core/service/slices/serviceInstanceSlice';
import { actionGetServiceTermsOptions } from '@lib/core/serviceTerms/slices';
import { useUser } from '@lib/core/users/hooks';
import { actionGetDietaryPreferenceOptionsData } from '@lib/core/users/slices/dietaryPreference';
import { actionGetLocationList } from '@lib/core/users/slices/locationList';
import { actionGetProductRateOptions } from '@lib/core/users/slices/productRate';
import { actionGetProfileOptionsData } from '@lib/core/users/slices/profile';
import { actionGetExploreLocations } from '@lib/tools/explore/slices';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const DataProvider = ({ children }) => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();

  const { retailerSlug } = useRetailer();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { retailerLocationId, retailerLocationProducerId } = useRetailerLocation();
  const {
    isUserAuthenticated,
    userBestMatchCharacterForCurrentServiceProductCategory,
    userBestMatchCharacterByProductCategoryIdentifiers,
    userBestMatchCharacterByProductCategory,
  } = useUser();
  const { locale, productCategory } = useApp();

  useEffectSkipFirst(() => {
    if (!retailerSlug || !retailerLocationId) return;
    dispatch(actionGetRetailerDetail({ retailerSlug }));
    dispatch(
      actionGetRetailerLocations({
        retailerLocationId,
        retailerSlug,
      }),
    );
  }, [locale]);

  useEffect(() => {
    // Can be removed, but also should be just modified when we go to multi-product again
    const groupedDispatch = ({ characterId }: { characterId: string }) => {
      dispatch(actionGetRetailerCharacterEssences({ characterId }));
      dispatch(actionGetRetailerCharacterDetails({ characterId }));
      dispatch(actionGetRetailerCharacterFoodPairing({ characterId }));
    };

    if (isDesignSetVinhoodApp) {
      Object.values(userBestMatchCharacterByProductCategory).forEach(character => {
        groupedDispatch({ characterId: character.characterId });
      });
    } else if (userBestMatchCharacterForCurrentServiceProductCategory?.characterId) {
      groupedDispatch({ characterId: userBestMatchCharacterForCurrentServiceProductCategory?.characterId });
    }
  }, [
    userBestMatchCharacterByProductCategoryIdentifiers,
    userBestMatchCharacterForCurrentServiceProductCategory?.characterId,
    locale,
  ]);

  useEffect(() => {
    if (!retailerSlug || (productCategory === PRODUCT_CATEGORY_NONE && !isDesignSetVinhoodApp)) return;
    dispatch(actionGetRetailerCharacters());
    dispatch(actionGetEssenceGroups());
    dispatch(actionGetWellness());
    dispatch(actionGetRecycling());
    dispatch(actionGetDietaryPreferenceOptionsData());

    if (!isApplicationKiosk) {
      dispatch(actionGetProductRateOptions());
      dispatch(actionGetServiceTermsOptions());
      dispatch(actionGetProfileOptionsData());
    }
    /** Service Instance:
     * The feature works for App
     * Catch and set data for the Service Instance feature */
    const serviceInstanceProductCategory = searchParams.get('serviceInstanceProductCategory');
    const serviceInstanceRetailerLocationId = searchParams.get('serviceInstanceRetailerLocationId');
    const serviceInstanceRetailerSlug = searchParams.get('serviceInstanceRetailerSlug');
    const serviceInstanceRetailerLocationSlug = searchParams.get('serviceInstanceRetailerLocationSlug');

    if (
      isDesignSetVinhoodApp &&
      serviceInstanceProductCategory &&
      serviceInstanceRetailerLocationId &&
      serviceInstanceRetailerSlug
    ) {
      dispatch(
        actionGetServiceInstanceRetailerData({
          serviceInstanceProductCategory,
          serviceInstanceRetailerLocationId,
          serviceInstanceRetailerLocationSlug,
          serviceInstanceRetailerSlug,
        }),
      );
    }
  }, [locale, productCategory]);

  useEffect(() => {
    if (isUserAuthenticated && !isApplicationKiosk) {
      dispatch(actionGetLocationList());
      dispatch(actionGetExploreLocations({ producerId: retailerLocationProducerId, retailerSlug }));
    }
  }, [isUserAuthenticated, locale]);

  return children;
};
