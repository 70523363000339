import { styled } from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';

export const ProductFeedbackQuestionPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 16px 32px;
  background: ${STATIC_GRADIENTS.secondaryGradient};
  min-height: 100%;
`;

export const ProductFeedbackQuestionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const StyledDot = styled.div<{ $isCurrentDotStyle: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ $isCurrentDotStyle }) => ($isCurrentDotStyle ? STATIC_COLORS.teal[25] : STATIC_COLORS.gray[900])};
  ${({ $isCurrentDotStyle }) => $isCurrentDotStyle && `border: 1px solid ${STATIC_COLORS.teal[300]};`}
`;

export const QuestionHeaderWrapper = styled.div`
  width: 100%;
`;

export const StyledBlockOne = styled.div`
  display: flex;
  height: 10px;
  margin: 0 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid ${STATIC_COLORS.teal[25]};
  background: ${STATIC_COLORS.teal[100]};
  border-bottom: none;
`;

export const StyledBlockTwo = styled.div`
  display: flex;
  height: 12px;
  margin: 0 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid ${STATIC_COLORS.teal[25]};
  background: ${STATIC_COLORS.teal[100]};
  border-bottom: none;
`;

export const StyledCloseButton = styled(CloseButton)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 2px solid #fff;
`;

export const StyledBackButton = styled(BackButton)`
  position: absolute;
  z-index: 199999999;
  background-color: ${STATIC_COLORS.base.white};
  box-sizing: border-box;
  width: 100%;
  padding: 14px;
`;
