import { FC, useState } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import mascotExploringIcon from '@components/web/src/assets/icons/mascot/mascot_exploring_icon.svg';
import mascotWiseIcon from '@components/web/src/assets/icons/mascot/mascot_wise_icon.svg';
import rowDownIcon from '@components/web/src/assets/icons/tutorials/row_down_icon.svg';
import rowIcon from '@components/web/src/assets/icons/tutorials/row_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { RatingButton } from '@components/web/src/atoms/ProductRating/ProductRating/RatingButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import {
  TJourneyStep,
  TastePathProgress,
} from '@components/web/src/organisms/TastePath/TastePathProgress/TastePathProgress';
import * as S from '@components/web/src/templates/Tutorials/TastePathOnboarding/styles';

enum PageView {
  START = 'START',
  RATE = 'RATE',
}

interface IProps {
  tastePathJourneyData: TJourneyStep[];
  productCategory: TProductCategory;
  handleClose: () => void;
}

export const TastePathOnboarding: FC<IProps> = ({ tastePathJourneyData = [], productCategory, handleClose }) => {
  const { productCategoriesPlural } = localeCommon;
  const { startTitleText, rateTitleText, startBtnText, gotItBtnText } = localeWidget.tastePathOnboarding;

  const { publishedTerms } = LocaleUtils;
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];

  const [view, setView] = useState<PageView>(PageView.START);
  const isRateView = view === PageView.RATE;

  const handleClick = () => {
    setView(PageView.RATE);

    if (isRateView) {
      handleClose();
    }
  };

  const btnText = isRateView ? startBtnText : gotItBtnText;

  return (
    <S.TastePathOnboardingContainer isFullWidth direction="column" gap={0} justify="center">
      {isRateView ? (
        <S.RateViewWrapper direction="column" gap={16}>
          <Text
            color={STATIC_COLORS.base.white}
            localeIndex={{ productCategory }}
            localeOptions={{ productCategoryPluralText }}
            size="body1"
            text={rateTitleText}
            textAlign="center"
            weight="bold"
          />
          <img alt="row down" className="icon" src={rowDownIcon} />
          <S.FeedbackWrapper>
            <RatingButton />
          </S.FeedbackWrapper>
          <S.MascotIcon $isRateView={isRateView} alt="mascot icon" src={mascotExploringIcon} />
        </S.RateViewWrapper>
      ) : (
        <S.StartViewWrapper direction="column" gap={0}>
          <Text
            color={STATIC_COLORS.base.white}
            localeIndex={{ productCategory }}
            localeOptions={{ productCategoryPluralText }}
            size="body1"
            text={startTitleText}
            textAlign="center"
            weight="bold"
          />
          <img alt="row" className="icon" src={rowIcon} />
          {!!tastePathJourneyData.length && (
            <S.TastePathProgressWrapper>
              <TastePathProgress steps={tastePathJourneyData} />
            </S.TastePathProgressWrapper>
          )}
          <S.MascotIcon $isRateView={isRateView} alt="mascot icon" src={mascotWiseIcon} />
        </S.StartViewWrapper>
      )}

      <Flexbox align="center" direction="column" gap={0} justify="space-between" margin="auto 0 0" padding=" 16px">
        <Button
          isFullWidth
          fontSize="subtitle2"
          handleClick={handleClick}
          size="md"
          text={btnText}
          variant={VH_VARIANTS.LIGHT}
        />
      </Flexbox>
    </S.TastePathOnboardingContainer>
  );
};
