import styled, { keyframes } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const fadeInAnimation = () => keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const TastePathOnboardingContainer = styled(Flexbox)`
  position: absolute;
  z-index: 99999;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  animation: ${fadeInAnimation} 0.3s ease-out;
`;

export const StartViewWrapper = styled(Flexbox)`
  position: relative;
  margin: 160px 8px 0;
  animation: ${fadeInAnimation} 0.3s ease-out;

  .icon {
    padding: 16px 50px;
  }
`;

export const RateViewWrapper = styled(Flexbox)`
  position: relative;
  margin: 276px 30px 0;
  animation: ${fadeInAnimation} 0.3s ease-out;

  .icon {
    padding: 16px 16px 0;
    margin-left: auto;
  }
`;

export const TastePathProgressWrapper = styled.div`
  margin-top: 3px;
  padding: 8px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  animation: ${fadeInAnimation} 0.3s ease-out;
`;

export const FeedbackWrapper = styled.div`
  margin-left: auto;
  background: ${STATIC_COLORS.base.white};
  border-radius: 4px;
`;

export const MascotIcon = styled.img<{ $isRateView: boolean }>`
  position: absolute;
  left: 50%;
  top: ${({ $isRateView }) => ($isRateView ? '90px' : '200px')};
  width: 200px;
  height: 200px;
  padding: 8px 0;
  transform: translate(-50%, 0) ${({ $isRateView }) => $isRateView && 'scaleX(-1)'};
`;
