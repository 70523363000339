import { FC, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import LoginContainer from '@lib/tools/shared/auths/views/containers/LoginContainer';
import ResetPasswordContainer from '@lib/tools/shared/auths/views/containers/ResetPasswordContainer';
import ConfirmResetPasswordContainer from '@lib/tools/shared/auths/views/containers/SetPasswordContainer';
import LegalTabsContainer from '@lib/tools/shared/legals/views/containers/LegalTabsContainer';
import FoodHabitsContainer from '@lib/tools/shared/quizzes/views/containers/FoodHabitsContainer';
import QuizContainer from '@lib/tools/shared/quizzes/views/containers/QuizContainer';
import { FoodPairingContainer } from '@lib/tools/shared/recipes/views/containers/FoodPairingContainer';
import { PAGES } from '@lib/tools/views/urls';

import AgeAndGenderContainer from 'containers/AgeAndGenderContainer';
import { AuthenticationContainer } from 'containers/AuthenticationContainer';
import { CharacterDetailsContainer } from 'containers/CharacterDetailsContainer';
import { ExploreContainer } from 'containers/ExploreContainer';
import { ExploreListContainer } from 'containers/ExploreListContainer';
import CookiePolicyContainer from 'containers/Legal/CookiePolicyContainer';
import GeneralServiceTermsContainer from 'containers/Legal/GeneralServiceTermsContainer';
import PrivacyPolicyContainer from 'containers/Legal/PrivacyPolicyContainer';
import TermsConditionsContainer from 'containers/Legal/TermsConditionsContainer';
import ProductCatalogContainer from 'containers/ProductCatalogContainer';
import ProductPageContainer from 'containers/ProductContainer';
import QuizChooseExpertiseContainer from 'containers/QuizChooseExpertiseContainer';
import RedirectContainer from 'containers/RedirectContainer';
import JournalPlacesContainer from 'containers/TasteId/Journal/JournalPlacesContainer';
import JournalProductsContainer from 'containers/TasteId/Journal/JournalProductsContainer';
import TasteIdContainer from 'containers/TasteId/TasteIdContainer';
import TasteIdLegalPageContainer from 'containers/TasteId/TasteIdLegalPageContainer';
import TasteIdSettingsPageContainer from 'containers/TasteId/TasteIdSettingsPageContainer';
import TasteIdTagsPageContainer from 'containers/TasteId/TasteIdTagsPageContainer';
import { ThirdPartyConsentContainer } from 'containers/ThirdPartyConsentContainer';
import HomeContainer from 'containers/widget/HomeContainer';
import {
  AuthRoutesAccess,
  CatalogRouteAccess,
  HomeRouteAccess,
  LegacyQuizRouteAccess,
  RedirectAnonymousAccess,
} from 'routes/AccessWeb';

const Router: FC = () => {
  const RouteGroup = (c, ...paths) => {
    return paths.map(p => <Route key={p} Component={c} path={p} />);
  };

  const retailerBasename = '/:lang/:retailer/loc/:location/p/:product';
  const retailerUrl = url => retailerBasename + url;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={retailerBasename}>
          <Route Component={HomeRouteAccess}>
            <Route key="urlHome" Component={HomeContainer} path={retailerUrl(PAGES.vinhood.home)} />
          </Route>

          <Route
            key="urlTasteOrExpertSelection"
            Component={QuizChooseExpertiseContainer}
            path={retailerUrl(PAGES.vinhood.quiz.chooseExpert)}
          />

          <Route
            key="urlAgeAndGender"
            Component={AgeAndGenderContainer}
            path={retailerUrl(PAGES.vinhood.ageAndGender)}
          />

          <Route
            key="urlFoodHabits"
            Component={FoodHabitsContainer}
            path={retailerUrl(PAGES.vinhood.quiz.chooseTaste)}
          />

          {RouteGroup(QuizContainer, retailerUrl(`/quiz/:quizType/`))}

          <Route key="urlFoodPairing" Component={FoodPairingContainer} path={retailerUrl(PAGES.vinhood.recipeSearch)} />

          <Route Component={CatalogRouteAccess}>
            <Route key="urlCatalog" Component={ProductCatalogContainer} path={retailerUrl(PAGES.vinhood.catalog)} />
          </Route>

          <Route
            key="urlCharacterDetails"
            Component={CharacterDetailsContainer}
            path={retailerUrl(PAGES.vinhood.characterDetails)}
          />

          <Route key="urlExplore" Component={ExploreContainer} path={retailerUrl(PAGES.vinhood.explore)} />

          <Route key="urlExploreList" Component={ExploreListContainer} path={retailerUrl(PAGES.vinhood.exploreList)} />

          <Route Component={RedirectAnonymousAccess}>
            <Route key="urlTasteId" Component={TasteIdContainer} path={retailerUrl(PAGES.vinhood.tasteId.tasteId)} />
            <Route
              key="urlTasteIdProductsJournal"
              Component={JournalProductsContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdProductsJournal)}
            />
            <Route
              key="urlTasteIdPlacesJournal"
              Component={JournalPlacesContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdPlacesJournal)}
            />
            <Route
              key="urlTasteIdLegal"
              Component={TasteIdLegalPageContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdLegal)}
            />
            <Route
              key="urlTasteIdTags"
              Component={TasteIdTagsPageContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdTags)}
            />
            <Route
              key="urlTasteIdSettings"
              Component={TasteIdSettingsPageContainer}
              path={retailerUrl(PAGES.vinhood.tasteId.tasteIdSettings)}
            />
          </Route>

          <Route key="urlProduct" Component={ProductPageContainer} path={retailerUrl(PAGES.vinhood.product)} />

          {/* User */}
          <Route Component={AuthRoutesAccess}>
            <Route
              key="urlRegistration"
              Component={AuthenticationContainer}
              path={retailerUrl(PAGES.vinhood.registration)}
            />

            <Route key="urlLogin" Component={LoginContainer} path={retailerUrl(PAGES.vinhood.signIn)} />

            <Route
              key="urlResetPassword"
              Component={ResetPasswordContainer}
              path={retailerUrl(PAGES.vinhood.resetPassword)}
            />

            <Route
              key="urlConfirmResetPassword"
              Component={ConfirmResetPasswordContainer}
              path={retailerUrl(PAGES.vinhood.confirmPasswordReset)}
            />

            <Route
              key="urlThirdPartyConsent"
              Component={ThirdPartyConsentContainer}
              path={retailerUrl(PAGES.vinhood.thirdParty)}
            />
          </Route>

          <Route
            key="urlTastePathStartPart"
            Component={ThirdPartyConsentContainer}
            path={retailerUrl(PAGES.vinhood.tastePathStartPart)}
          />

          {/* Misc */}
          <Route key="urlLegal" Component={LegalTabsContainer} path={retailerUrl(PAGES.vinhood.legal.tabs)} />
          <Route key="urlRedirect" Component={RedirectContainer} path={retailerUrl(PAGES.vinhood.redirect)} />
          <Route
            key="urlGeneralServiceTermsPart"
            Component={GeneralServiceTermsContainer}
            path={retailerUrl(PAGES.vinhood.legal.generalServiceTerms)}
          />
          <Route
            key="urlPrivacyPolicyPart"
            Component={PrivacyPolicyContainer}
            path={retailerUrl(PAGES.vinhood.legal.privacy)}
          />
          <Route
            key="urlCookiePolicyPart"
            Component={CookiePolicyContainer}
            path={retailerUrl(PAGES.vinhood.legal.cookies)}
          />
          <Route
            key="urlTermsAndConditionsPart"
            Component={TermsConditionsContainer}
            path={retailerUrl(PAGES.vinhood.legal.termsConditions)}
          />
          <Route Component={LegacyQuizRouteAccess}>
            <Route element={<Navigate replace to={prependBasename(PAGES.vinhood.home)} />} path={retailerUrl('/*')} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
