import { createSelector } from 'reselect';

import { IProductFeedbackSlice } from '@lib/core/products/slices/productFeedback';
import { selectRetailerLocationId } from '@lib/core/retailers/selectors/retailerLocation';
import { RootState } from '@lib/core/service/types/appStateType';
import RouteUtils from '@lib/tools/routes';

/**
 * @returns the product feedback slice state
 */
export const selectProductFeedbackState = (state): IProductFeedbackSlice => state.productFeedback;

/**
 @returns all product feedback data
 */
export const selectProductFeedbackQuestionsData = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.data,
);

/**
 @returns if product feedback modal open
 */
export const selectOpenedFeedbackModalProductInstanceData = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.productInstanceData,
);

/**
 @returns if product feedback modal open for current RTL and product category 
 */
export const selectProductFeedbackModalProductIsOpenedForCurrentRtl = createSelector(
  [
    selectProductFeedbackState,
    selectRetailerLocationId,
    (state: RootState) => state?.service?.productCategory || RouteUtils.getProductCategory(),
  ],
  (feedbackState, retailerLocationId, serviceProductCategory) =>
    (feedbackState.productInstanceData &&
      feedbackState.retailerLocationId === retailerLocationId &&
      feedbackState.serviceProductCategory === serviceProductCategory) ||
    feedbackState.tutorialProduct,
);

/**
 @returns current type of product feedback flow screens
 */
export const selectProductFeedbackModalType = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.productFeedbackModalStep,
);

/**
 @returns available product feedback questions
 */
export const selectProductFeedbackQuestions = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.data?.choices?.questions || [],
);

/**
 @returns current product feedback question
 */
export const selectProductFeedbackCurrentQuestion = createSelector(
  [selectProductFeedbackQuestions],
  productFeedbackQuestions => productFeedbackQuestions.find(question => !question.isFinished),
);

/**
 @returns current product feedback question
 */
export const selectProductFeedbackCurrentQuestionAllAnswersAreLoaded = createSelector(
  [selectProductFeedbackCurrentQuestion],
  productFeedbackQuestion =>
    productFeedbackQuestion?.choices?.length &&
    productFeedbackQuestion?.choices?.length ===
      productFeedbackQuestion?.answers?.filter(answer => !('urlToBeLoaded' in answer) || 'isRequestFailed' in answer)
        .length,
);

/**
 @returns all product feedbacks from DB by product Id
 */
export const selectAllFeedbacksByProductId = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.answeredProductsFeedbacksByProductsId,
);

/**
 @returns cached product feedbacks answers by url
 */
export const selectProductFeedbackCachedAnswersByUrl = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.cachedAnswersByUrl,
);

/**
 @returns if required product feedbacks data is loading
 */
export const selectIsProductFeedbackDataOrOptionsLoading = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.isDataLoading || feedbackState.isQuestionsLoading,
);

/**
 @returns if feedback answer submit request is loading
 */
export const selectIsProductFeedbackAnswersSubmitLoading = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.isSubmitProductFeedbackAnswersLoading,
);

/**
 @returns locale of feedback data
 */
export const selectProductFeedbackDataLocale = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.locale,
);

/**
 @returns product to show inside tutorial modal
 */
export const selectProductFeedbackTutorialProduct = createSelector(
  [selectProductFeedbackState],
  feedbackState => feedbackState.tutorialProduct,
);

/**
 @returns if any product has finished feedback flow
 */
export const selectIsAnyProductFeedbackCompleted = createSelector([selectProductFeedbackState], feedbackState =>
  Object.values(feedbackState.answeredProductsFeedbacksByProductsId).some(answersArray => answersArray.length === 3),
);
