import { FC } from 'react';
import { styled } from 'styled-components';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { skeletonLoadingAnimation, skeletonLoadingKeyframes } from '@components/web/src/organisms/Skeleton/styles';

interface IStyleProps {
  $minHeight?: string;
  $width?: string;
  $margin?: string;
  $borderRadius?: string;
}

export const SkeletonContainer = styled.div<IStyleProps>`
  margin: ${({ $margin = '0' }) => $margin};
  min-height: ${({ $minHeight = '40px' }) => $minHeight};
  width: ${({ $width = '100%' }) => $width};
  border-radius: ${({ $borderRadius = '4px' }) => $borderRadius};
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
`;

interface IProps {
  minHeight?: string;
  width?: string;
  margin?: string;
  borderRadius?: string;
}

export const Skeleton: FC<IProps> = ({ margin, minHeight, width, borderRadius, ...rest }) => {
  return (
    <SkeletonContainer $borderRadius={borderRadius} $margin={margin} $minHeight={minHeight} $width={width} {...rest} />
  );
};
