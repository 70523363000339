import { styled } from 'styled-components';

import { ReactComponent as WavyImage } from '@components/web/src/assets/legacy/b2c/images/footer_waves.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FooterAppAnonymousContainer = styled.div`
  width: 100%;
  background-color: ${STATIC_COLORS.gray[25]};
`;

export const WavyImageContainer = styled.div`
  position: relative;
  z-index: 20;
  background-color: ${STATIC_COLORS.gray[25]};
`;

export const WavyImageElement = styled(WavyImage)`
  width: 100%;
  height: 63px;
`;

export const FooterContent = styled(Flexbox)`
  position: relative;
  z-index: 20;
  margin-top: -22px;
  background-color: ${STATIC_COLORS.green[600]};
`;

export const LinkCta = styled.button`
  padding: 0;
  text-align: center;
  background: transparent;
  border: none;
`;
