import React, { useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

const ErrorFallback = ({ error, onError }: FallbackProps & { onError: () => void }) => {
  useEffect(() => {
    console.warn('RUNTIME ERROR:', error);
    onError();
  }, [error, onError]);

  return null;
};

export const SafeComponent = ({ children, onError }: { children: React.ReactNode; onError: () => void }) => (
  <ErrorBoundary FallbackComponent={props => <ErrorFallback {...props} onError={onError} />}>{children}</ErrorBoundary>
);
