import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ZodType, z } from 'zod';

import { TProfileCountryOption, TProfileGenderOption } from '@lib/core/users/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { birthYearValidation } from '@lib/tools/shared/helpers/utils';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import InputField from '@components/web/src/organisms/Input/InputFields';
import SelectionInput from '@components/web/src/templates/TasteId/TasteIdSettingsPage/SelectionInput/SelectionInput';
import * as S from '@components/web/src/templates/TasteId/TasteIdSettingsPage/styles';

export interface IFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
  gender?: string;
  birthYear?: string;
}

interface IProps {
  profileCountryOptions: TProfileCountryOption[];
  profileGenderOptions: TProfileGenderOption[];
  formData: IFormData;
  handleFormSubmit: (data: IFormData) => void;
}

const UserInfoForm: FC<IProps> = ({ profileCountryOptions, profileGenderOptions, formData, handleFormSubmit }) => {
  const {
    saveBtnText,
    cancelBtnText,
    nameFieldTitle,
    lastNameFieldTitle,
    emailFieldTitle,
    countryFieldTitle,
    nameValidationText,
    emailValidationText,
    birthYearText,
    genderText,
    selectText,
  } = localeApp.userInfoForm;
  const { ageError } = localeWidget.ageAndGenderPages;

  const { publishedTerms } = LocaleUtils;

  const localeNameValidationText = publishedTerms[nameValidationText?.id];
  const localeEmailValidationText = publishedTerms[emailValidationText.id];
  const selectPlaceholderText = publishedTerms[selectText.id];
  const localeBirthYearValidationText = publishedTerms[ageError.id]?.replace(
    '{ maxValidBirthYear }',
    birthYearValidation().maxValidBirthYear,
  );

  const validationSchema: ZodType<IFormData> = useMemo(
    () =>
      z.object({
        birthYear: z.string().refine(val => birthYearValidation(val).isValidBirthYear, {
          message: localeBirthYearValidationText,
        }),
        country: z.string(),
        email: z.string().nonempty(localeEmailValidationText).email({ message: localeEmailValidationText }),
        firstName: z.string().min(2, { message: localeNameValidationText }),
        gender: z.string().nonempty(),
        lastName: z.string(),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormData>({
    defaultValues: formData,
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
  });

  const submitHandler = (data: IFormData) => handleFormSubmit(data);

  const cancelHandler = () => reset(formData);

  return (
    <S.ProfileForm noValidate autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
      <InputField label={nameFieldTitle} {...register('firstName')} errors={errors} />
      <InputField label={lastNameFieldTitle} {...register('lastName')} errors={errors} />
      <InputField label={emailFieldTitle} {...register('email')} errors={errors} />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <SelectionInput
            isSearchable
            errors={errors}
            labelTitle={countryFieldTitle}
            name={field.name}
            options={profileCountryOptions}
            placeholderText={selectPlaceholderText}
            value={field.value}
            onChange={value => field.onChange(value)}
          />
        )}
      />
      <InputField label={birthYearText} {...register('birthYear')} errors={errors} placeholder="YYYY" />
      <Controller
        control={control}
        name="gender"
        render={({ field }) => (
          <SelectionInput
            errors={errors}
            isSearchable={false}
            labelTitle={genderText}
            name={field.name}
            options={profileGenderOptions}
            placeholderText={selectPlaceholderText}
            value={field.value}
            onChange={value => field.onChange(value)}
          />
        )}
      />
      <Flexbox align="flex-end" gap={16} justify="flex-end" padding="8px 0">
        <S.FormActionsButton size="sm" text={cancelBtnText} variant={VH_VARIANTS.LIGHT} onClick={cancelHandler} />
        <S.FormActionsButton size="sm" text={saveBtnText} type="submit" />
      </Flexbox>
    </S.ProfileForm>
  );
};

export default UserInfoForm;
