import { FC } from 'react';

import Badge from '@components/web/src/atoms/Badge/Badge';
import { BadgesTooltip } from '@components/web/src/organisms/CardFooter/BadgesTooltip';
import * as S from '@components/web/src/organisms/CardFooter/style';

interface IProps {
  badges?: string[];
}

export const CardFooter: FC<IProps> = ({ badges }) => {
  let badgeCount = 0;
  if (badges?.length > 0) {
    badgeCount = badges.length - 1;
  }
  const onCardFooterClick = (e: any) => e.stopPropagation();

  return (
    <S.CardFooterContainer
      isFullWidth
      align="center"
      gap={0}
      justify="center"
      padding="6px 16px"
      role="button"
      tabIndex={0}
      onClick={onCardFooterClick}
      onKeyDown={onCardFooterClick}
    >
      <S.CardFooterWrapper align="center" gap={4} justify="flex-start">
        {badges?.length > 0 && <Badge text={badges[0]} />}
        {badgeCount > 0 && (
          <BadgesTooltip content={badges}>
            <Badge text={`+${badgeCount}`} />
          </BadgesTooltip>
        )}
      </S.CardFooterWrapper>
    </S.CardFooterContainer>
  );
};
