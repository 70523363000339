import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef } from 'react';

import {
  TFontFamilies,
  TFontStyle,
  TStyleWeight,
  TTextDecoration,
  TTextTransform,
  TTypographyVariants,
} from '@components/web/src/foundations/Text/types';
import { StyledTextArea } from '@components/web/src/foundations/TextArea/styles';

export interface ITextAreaProps extends DetailedHTMLProps<HTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  value?: string;
  placeholder?: string;
  color?: string;
  size?: TTypographyVariants;
  weight?: TStyleWeight;
  fontFamily?: TFontFamilies;
  textDecoration?: TTextDecoration;
  fontStyle?: TFontStyle;
  children?: ReactNode;
  textTransform?: TTextTransform;
  minHeight?: string;
  onChange?: (e) => void;
}

export const TextArea = ({
  value,
  placeholder,
  fontFamily = 'Montserrat',
  size = 'body2',
  weight = 'normal',
  color,
  fontStyle = 'normal',
  textDecoration = 'none',
  children,
  textTransform = 'none',
  onChange,
  minHeight = '90px',
  ...rest
}: ITextAreaProps) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      if (textAreaRef.current.scrollHeight < 240) {
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      } else {
        textAreaRef.current.style.height = '240px';
      }
    }
  }, [value]);

  return (
    <StyledTextArea
      ref={textAreaRef}
      $color={color}
      $fontFamily={fontFamily}
      $fontStyle={fontStyle}
      $minHeight={minHeight}
      $size={size}
      $textDecoration={textDecoration}
      $textTransform={textTransform}
      $weight={weight}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {children}
    </StyledTextArea>
  );
};
