import { FC, ReactElement, useRef, useState } from 'react';

import useOutsideClick from '@lib/tools/views/hooks/useOutsideClick';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/CardFooter/style';

type Props = {
  content: string[];
  children: ReactElement<any, any>;
};

export const BadgesTooltip: FC<Props> = ({ children, content }) => {
  const ref = useRef();

  const [isToolTipActive, setIsToolTipActive] = useState(false);

  const hideTip = () => setIsToolTipActive(false);
  const toggleTip = () => setIsToolTipActive(current => !current);

  useOutsideClick(ref, hideTip);

  return (
    <S.TagsTooltipContainer ref={ref} role="button" tabIndex={0} onClick={toggleTip} onKeyDown={toggleTip}>
      {children}
      {isToolTipActive && (
        <S.TagsToolTip>
          <S.ToolTipContent>
            {content.map((item, idx) => (
              <Text key={`${item}${idx}`} color={STATIC_COLORS.warmGray[600]} size="body2" text={item} />
            ))}
          </S.ToolTipContent>
        </S.TagsToolTip>
      )}
    </S.TagsTooltipContainer>
  );
};
