import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const CharacterMapCardContainer = styled(Flexbox)`
  min-width: 260px;
  max-width: 260px;
  min-height: 292px;
  padding-top: 36px;
`;

export const CharacterMapCardImageContainer = styled(Flexbox)`
  width: 200px;
  height: 200px;
`;

export const CharacterMapCardInfoContainer = styled(Flexbox)`
  padding: 0 0 0 60px;
  justify-content: flex-end;
  min-height: 64px;
`;

export const CharacterNameText = styled(Text)`
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// CHARACTER MAP STYLES
export const CharacterMapContainer = styled(Flexbox)`
  align-items: stretch;
  min-height: 400px;
`;

export const CharactersWrapper = styled(Flexbox)`
  flex-flow: row nowrap;
  flex-grow: 1;
  max-width: fit-content;
  border-top: 2px solid var(--color-primary-200);
  padding-top: 10px;
`;
