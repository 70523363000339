import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TProductInstance } from '@lib/core/products/types';
import { getLimitedProductsListData, getProductsListData } from '@lib/core/products/utils';
import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setShowTastePathStartPage } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useProductRate, useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_THIRD_PARTY_OPTION_SELECTED } from '@lib/tools/dat/mixpanel/consts';
import { TMPThirdPartyChoiceClick } from '@lib/tools/dat/mixpanel/types';
import { setStaticMatchedProducts } from '@lib/tools/productCatalog/slices';
import { LIMITED_CATALOG, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { ThirdPartyConsentVariants } from '@components/web/src/templates/ThirdPartyConsentPage/components/ThirdPartyConnector';
import { ThirdPartyConsentPage } from '@components/web/src/templates/ThirdPartyConsentPage/ThirdPartyConsentPage';

export const ThirdPartyConsentContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productCategory } = useApp();
  const { isEnableTastePathAddon } = useAddons();
  const { userCharactersForServiceProductCategoryArray, isUserDataLoading, isUserCharactersLoading } = useUser();
  const { associatedRetailerLocation, retailerLocationCatalogType, isRetailerLocationLoading } = useRetailerLocation();
  const { retailerName, isRetailerLoading } = useRetailer();
  const { isProductRateDataLoading } = useProductRate();
  const { parseProduct } = useParsedProducts();
  const {
    thirdPartyData,
    isShowThirdPartyAcceptance,
    isTermsInitialDataLoading,
    serviceTermsListUpdateIsLoading,
    handleThirdPartyAcceptance,
  } = useThirdParty();

  const [productInstanceData, setProductInstanceData] = useState<TProductInstance>(undefined);
  const [isProductInstanceDataLoading, setIsProductInstanceDataLoading] = useState(false);

  useEffect(() => {
    const fetchProductInstanceData = async (retryIndex = 1) => {
      setIsProductInstanceDataLoading(true);

      const isLimitedCatalogType = retailerLocationCatalogType === LIMITED_CATALOG;

      let productInstanceDataResponse: TProductInstance = null;

      try {
        const userCharacterId = userCharactersForServiceProductCategoryArray.at(-retryIndex)?.characterId;

        const params = {
          [GPRL_CHARACTER_QUERY]: userCharacterId,
          [PRODUCT_CATEGORY_QUERY]: productCategory,
          ...(isLimitedCatalogType && { [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRetailerLocation?.slug }),
          limit: 1,
          offset: 0,
        };

        if (isLimitedCatalogType) {
          const res = await getLimitedProductsListData(params);
          productInstanceDataResponse =
            res?.first_priority?.[0] || res?.second_priority?.[0] || res?.third_priority?.[0];
        } else {
          const res = await getProductsListData(params);
          productInstanceDataResponse = res.results?.[0];
        }

        if (!productInstanceDataResponse && retryIndex < userCharactersForServiceProductCategoryArray.length) {
          return await fetchProductInstanceData(retryIndex + 1);
        }

        setProductInstanceData(productInstanceDataResponse);
        dispatch(setStaticMatchedProducts([productInstanceDataResponse]));
        return productInstanceDataResponse;
      } finally {
        setIsProductInstanceDataLoading(false);
      }
    };

    if (!isProductInstanceDataLoading && !productInstanceData && !isEnableTastePathAddon) {
      fetchProductInstanceData();
    }

    if (isEnableTastePathAddon) dispatch(setShowTastePathStartPage(false));
  }, []);

  const navigateToCatalog = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const handleThirdPartyClick = ({ optionSelected }: TMPThirdPartyChoiceClick) => {
    MixpanelTracker.events.thirdPartyChoiceClick({ optionSelected });

    if (optionSelected !== MP_THIRD_PARTY_OPTION_SELECTED.LATER) {
      handleThirdPartyAcceptance(optionSelected === MP_THIRD_PARTY_OPTION_SELECTED.YES);
    }

    navigateToCatalog();
  };

  const isLoading =
    isProductInstanceDataLoading ||
    isUserDataLoading ||
    isRetailerLocationLoading ||
    isRetailerLoading ||
    isTermsInitialDataLoading ||
    serviceTermsListUpdateIsLoading ||
    isProductRateDataLoading ||
    isUserCharactersLoading;

  return isLoading ? (
    <LoadingSpinner variant="spinner" />
  ) : (
    <ThirdPartyConsentPage
      handleThirdPartyClick={handleThirdPartyClick}
      isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
      navigateToCatalog={navigateToCatalog}
      parsedProductInstanceData={parseProduct(productInstanceData)}
      productCategory={productCategory}
      retailerName={retailerName}
      thirdPartyData={thirdPartyData}
      variant={isEnableTastePathAddon ? ThirdPartyConsentVariants.TastePath : ThirdPartyConsentVariants.Default}
    />
  );
};
