import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { selectIsAnonymousRetailerAccess } from '@lib/core/retailers/selectors/retailer';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import RouteUtils from '@lib/tools/routes';
import { CHARACTERS_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

export const HomeRouteAccess = () => {
  const { isDisableHomeAddon } = useAddons();
  const { isUserAuthenticated } = useUser();
  const redirectToQuizPage = prependBasename(PAGES.vinhood.quiz.chooseExpert);
  const redirectToCatalogPage = prependBasename(PAGES.vinhood.catalog);
  if (isDisableHomeAddon) {
    if (isUserAuthenticated) {
      return <Navigate replace to={redirectToCatalogPage} />;
    }
    return <Navigate replace to={redirectToQuizPage} />;
  }
  return <Outlet />;
};

export const AuthRoutesAccess = () => {
  const { isDisableHomeAddon } = useAddons();
  const { isUserHasCharacterForCurrentPC } = useUser();
  const isAnonymousWidgetAccess = useSelector(selectIsAnonymousRetailerAccess);

  const redirectToCatalogPage = prependBasename(PAGES.vinhood.catalog);
  const redirectToHomePage = prependBasename(PAGES.vinhood.home);

  // For anonymous widget we have to skip auth routes
  if (isAnonymousWidgetAccess) {
    return (
      <Navigate
        replace
        to={isUserHasCharacterForCurrentPC || isDisableHomeAddon ? redirectToCatalogPage : redirectToHomePage}
      />
    );
  }

  return <Outlet />;
};

export const CatalogRouteAccess = () => {
  const { search } = useLocation();

  const { isEnableTastePathAddon, isExternalCharacterPage, isTasteIdResultPageAddon, isDisableHomeAddon } = useAddons();
  const {
    isUserCharactersLoading,
    isUserHasCharacterForCurrentPC,
    userBestMatchCharacterForCurrentServiceProductCategory,
  } = useUser();
  const { shouldShowTastePathStartPage } = useApp();

  const searchParams = new URLSearchParams(search);
  const characterFromUrl = searchParams.get(CHARACTERS_URL_PARAM);

  const redirectToTastePathStartPage = prependBasename(PAGES.vinhood.tastePathStartPart);
  const redirectToQuizSelectionPage = prependBasename(PAGES.vinhood.quiz.chooseExpert);
  const redirectToHomePage = prependBasename(PAGES.vinhood.home);
  const redirectToTasteIdPage = prependBasename(PAGES.vinhood.tasteId.tasteId);

  if (isTasteIdResultPageAddon) {
    return <Navigate replace to={redirectToTasteIdPage} />;
  }

  if (isExternalCharacterPage) {
    if (userBestMatchCharacterForCurrentServiceProductCategory) {
      if (userBestMatchCharacterForCurrentServiceProductCategory?.characterRedirectUrl?.length > 3) {
        RouteUtils.redirectExternalCharacterPage(
          userBestMatchCharacterForCurrentServiceProductCategory.characterRedirectUrl,
        );
      }

      return <Navigate replace to={isDisableHomeAddon ? redirectToTasteIdPage : redirectToHomePage} />;
    }
    return <Navigate replace to={redirectToQuizSelectionPage} />;
  }

  if (isUserCharactersLoading) {
    return <LoadingSpinner />;
  }

  if (isEnableTastePathAddon && !isUserHasCharacterForCurrentPC && !isUserCharactersLoading && !characterFromUrl) {
    return <Navigate replace to={redirectToQuizSelectionPage} />;
  }

  if (isEnableTastePathAddon && isUserHasCharacterForCurrentPC && shouldShowTastePathStartPage && !characterFromUrl) {
    return <Navigate replace to={redirectToTastePathStartPage} />;
  }

  return <Outlet />;
};

/**
 * ! Handles old quiz URLs distributed prior to v0.7.0
 */
export const LegacyQuizRouteAccess = () => {
  const page = RouteUtils.getPage();

  const legacyRouteTasteQuiz = '/landing_test/';
  const legacyRouteRecipeQuiz = '/recipe_test/';
  const legacyRouteExpertQuiz = '/expert_test/';

  if (page.includes(legacyRouteTasteQuiz)) {
    return <Navigate replace to={prependBasename(PAGES.vinhood.quiz.chooseTaste)} />;
  }
  if (page.includes(legacyRouteRecipeQuiz)) {
    return <Navigate replace to={prependBasename(PAGES.vinhood.quiz.recipe)} />;
  }
  if (page.includes(legacyRouteExpertQuiz)) {
    return <Navigate replace to={prependBasename(PAGES.vinhood.quiz.expert)} />;
  }

  return <Outlet />;
};

// Custom fix to redirect user to homepage after logging out
export const RedirectAnonymousAccess = () => {
  const { isUserAuthenticated } = useUser();
  const redirectToHomePage = prependBasename(PAGES.vinhood.home);
  if (!isUserAuthenticated) {
    return <Navigate replace to={redirectToHomePage} />;
  }
  return <Outlet />;
};
