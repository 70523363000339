import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { actionGetComments } from '@lib/core/comments/slices/comments';
import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { actionResetUserQuiz } from '@lib/core/quizzes/slices';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { setFidelityCardIdFromKiosk, setIsTasteIdGuideShown } from '@lib/core/service/slices';
import { isAppInIframe } from '@lib/core/service/utils';
import { actionGetServiceTermsList } from '@lib/core/serviceTerms/slices';
import { useLocationLog, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { useUserKiosk } from '@lib/core/users/hooks/useUserKiosk';
import { actionGetUserCharacters } from '@lib/core/users/slices/characters';
import { actionGetDietaryPreferenceListData } from '@lib/core/users/slices/dietaryPreference';
import { actionGetLocationLog, actionUpdateLocationLog } from '@lib/core/users/slices/locationLog';
import { actionGetProductList } from '@lib/core/users/slices/productList';
import { actionGetProductRateData } from '@lib/core/users/slices/productRate';
import { actionGetProfileData } from '@lib/core/users/slices/profile';
import { actionGenerateUserSession, actionGetUserData } from '@lib/core/users/slices/user';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import RouteUtils from '@lib/tools/routes';
import { FIDELITY_ID_URL_PARAM, PRODUCT_LIST_WISHLIST } from '@lib/tools/shared/helpers/consts';
import { resetTastePathData } from '@lib/tools/tastePathProducts/slices';
import { useAddons, useEffectSkipFirst } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

const UserProvider = ({ children }) => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();

  const { handleResetUserData, isUserAuthenticated, userSessionId } = useUser();
  const { isUserKioskAdmin } = useUserKiosk();
  const { getAllSubmittedProductFeedbacks } = useProductFeedback();

  const { locale, fidelityCardIdFromKiosk } = useApp();
  const { isDisableLocationTrackAddon, isInteractiveTastingAddon } = useAddons();
  const { retailerLocationId, retailerLocationSlug } = useRetailerLocation();
  const { isLocationLogDataLoaded, isLocationLogLoading } = useLocationLog();
  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const { fidelityCardId } = useFidelityCard();

  const hideTasteIdGuide = () => dispatch(setIsTasteIdGuideShown(true));

  // this function is used to fetch the user related data
  const fetchUserRelatedData = () => {
    if (!isApplicationKiosk) {
      dispatch(actionGetServiceTermsList());
    }

    /** Fetch user locationLog */
    dispatch(actionGetLocationLog());

    /** Fetch profile data */
    dispatch(actionGetProfileData());

    /** Fetch user dietary preference data */
    dispatch(actionGetDietaryPreferenceListData());

    /** Fetch user characters */
    dispatch(actionGetUserCharacters());
  };

  // this function is used to fetch the x-profile user related data
  const fetchProfileRelatedData = () => {
    if (!isApplicationKiosk) {
      dispatch(actionGetProductList({ listName: PRODUCT_LIST_WISHLIST }))
        .unwrap()
        .then(res => res?.listData?.length > 0 && hideTasteIdGuide());

      /** We use Feedback feature for:
       *  Taste Path application - for all users.
       *  Widget and App applications - for all users */
      dispatch(actionGetProductRateData())
        .unwrap()
        .then(res => res?.length && hideTasteIdGuide());

      /** We use Comments feature for:
       *  Widget - for all users */
      dispatch(actionGetComments());

      if (isInteractiveTastingAddon) {
        /** Fetch user product feedbacks */
        getAllSubmittedProductFeedbacks();
      }
    }
  };

  useEffect(() => {
    if (isApplicationKiosk && fidelityCardId && retailerLocationId && locale) {
      dispatch(actionGetProductList({ listName: PRODUCT_LIST_WISHLIST }));
    }
  }, [retailerLocationId, locale, fidelityCardId]);

  // * this use Effect runs only once after the first render of the component
  // * it is used to fetch the user and all the related data connected to the profile id
  useEffect(() => {
    if (!isApplicationKiosk) {
      if (isUserAuthenticated) {
        dispatch(actionGetUserData()).then(() => fetchUserRelatedData());
      }

      // * this is needed for promo qr code in the catalog page
      // * to show the modal only if we have the fidelity card id
      // * because we can't set the fidelity card for now because of mixpanel management
      const fidelityCardIdFromUrl = searchParams.get(FIDELITY_ID_URL_PARAM);
      if (fidelityCardIdFromUrl && !fidelityCardIdFromKiosk) {
        dispatch(setFidelityCardIdFromKiosk(fidelityCardIdFromUrl));
      }
    }

    if (!userSessionId) {
      dispatch(actionGenerateUserSession());
    }
  }, []);

  useEffect(() => {
    fetchProfileRelatedData();
  }, [isUserAuthenticated]);

  // * this use Effect runs only once after the first render of the component
  // * and skip the first render to avoid to make conflict with above useEffect
  // * it is used to associate character with user's account once he make a login or create an account
  useEffectSkipFirst(() => {
    if (isUserAuthenticated && !isApplicationKiosk) {
      dispatch(actionGetUserData()).then(() => {
        MixpanelTracker.events.userLogin(false, false);

        dispatch(resetTastePathData());

        fetchUserRelatedData();

        if (isServiceInstanceFeatureEnabled) {
          RouteUtils.navigateToWidgetServiceInstance(PAGES.vinhood.catalog);
        }
      });
    } else {
      handleResetUserData();
      dispatch(actionResetUserQuiz());
    }
  }, [isUserAuthenticated]);

  useEffectSkipFirst(() => {
    dispatch(actionGenerateUserSession());
  }, [fidelityCardId]);

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(resetTastePathData());

      const isNonKioskUserOverKioskEnv = isApplicationKiosk && !isUserKioskAdmin;
      const isKioskUserOverNonKioskEnv = !isApplicationKiosk && isUserKioskAdmin;
      if ((isUserAuthenticated && isNonKioskUserOverKioskEnv) || isKioskUserOverNonKioskEnv) {
        handleResetUserData();
      }
    }
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (
      isUserAuthenticated &&
      !isAppInIframe &&
      !isDisableLocationTrackAddon &&
      retailerLocationSlug &&
      isLocationLogDataLoaded &&
      !isLocationLogLoading
    ) {
      dispatch(actionUpdateLocationLog(retailerLocationSlug));
    }
  }, [isUserAuthenticated, isAppInIframe, isDisableLocationTrackAddon, isLocationLogDataLoaded, retailerLocationSlug]);

  return children;
};

export default UserProvider;
