import { Dispatch, FC, SetStateAction } from 'react';
import { animated, useSpring } from 'react-spring';

import { IProductFeedbackAnswer } from '@lib/core/products/slices/productFeedback';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { useHeight } from '@lib/tools/views/hooks';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ProductFeedback/ProductFeedbackAnswerCard/styles';

interface IProps {
  answer: IProductFeedbackAnswer;
  isOpenedAnswer?: boolean;
  handleSubmitAnswer?: () => void;
  setSelectedAnswerIdOrSlug?: Dispatch<SetStateAction<string>>;
  isProductFeedbackAnswersSubmitLoading?: boolean;
}

export const ProductFeedbackAnswerCard: FC<IProps> = ({
  answer,
  isOpenedAnswer = false,
  handleSubmitAnswer,
  setSelectedAnswerIdOrSlug,
  isProductFeedbackAnswersSubmitLoading,
}) => {
  const { identifier, name, color, description, image, slug } = answer;
  const { continueBtn } = localeWidget.productFeedback;

  const [heightRef, height] = useHeight<HTMLDivElement>();
  const slideInStyles = useSpring({
    config: { duration: 200 },
    from: { height: 0, opacity: 0 },
    to: {
      height: isOpenedAnswer ? height : 0,
      opacity: isOpenedAnswer ? 1 : 0,
    },
  });

  return (
    <S.ProductFeedbackAnswerCardContainer
      $withGap={isOpenedAnswer}
      onClick={() => {
        if (setSelectedAnswerIdOrSlug) {
          setSelectedAnswerIdOrSlug(identifier || slug);
        }
      }}
    >
      <Flexbox align="center" gap={16} justify="space-between">
        <Text color={STATIC_COLORS.base.black} size="body1" text={name} weight="medium" />
        {!!image && <S.AnswerImage $image={image} />}
        {!!color && <S.AnswerColor $hexColor={color} />}
      </Flexbox>
      <animated.div style={isOpenedAnswer ? undefined : { ...slideInStyles, overflow: 'hidden' }}>
        <Flexbox ref={heightRef} direction="column" gap={8}>
          <Text color={STATIC_COLORS.base.black} size="body2" text={description} />
          {handleSubmitAnswer && (
            <Button
              disabled={isProductFeedbackAnswersSubmitLoading}
              handleClick={handleSubmitAnswer}
              text={continueBtn}
              variant="light"
            />
          )}
        </Flexbox>
      </animated.div>
    </S.ProductFeedbackAnswerCardContainer>
  );
};
