import { NUM_SEARCH_SKELETONS } from '@components/web/src/foundations/consts';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Skeleton } from '@components/web/src/organisms/Skeleton/Skeleton';

export const GlobalSearchSkeleton = () => (
  <Flexbox isFullWidth align="flex-start" direction="column" gap={24} padding="0 16px">
    <Flexbox isFullWidth direction="column" padding="8px 0">
      <Skeleton />
    </Flexbox>
    {Array.from({ length: NUM_SEARCH_SKELETONS })
      .fill(0)
      .map(index => (
        <Skeleton key={`search-skeleton-${index}`} minHeight="100px" />
      ))}
  </Flexbox>
);
