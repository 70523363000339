import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

export const selectBanners = (state: RootState) => state?.banners;

export const selectBannersData = createSelector([selectBanners], banners => banners.data);

export const selectIsBannersLoading = createSelector([selectBanners], banners => banners.isLoading);

export const selectBannersError = createSelector([selectBanners], banners => banners.error);
