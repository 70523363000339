import { styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const TestedBannerContainer = styled.div<{ $productCategory: TProductCategory }>`
  position: relative;
  width: 100%;
  height: 64px;
  background: ${({ $productCategory }) => `${STATIC_GRADIENTS.productGradients[$productCategory]};`};
  overflow: hidden;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 24px 8px 16px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0) 11.5%,
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0.15) 100%
  );
`;

export const CharacterImageWrapper = styled(Flexbox)`
  position: absolute;
  top: 5px;
  left: 16px;

  img {
    position: absolute;
    width: 200px;
    height: auto;
  }

  div {
    width: 119px;
    height: 119px;
    border-radius: 50%;
    background: ${STATIC_COLORS.base.white};
    opacity: 0.2;
  }
`;
