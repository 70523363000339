import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  title: ILocaleText;
  description: ILocaleText;
}

export const ProfileSectionHeader: FC<IProps> = ({ title, description }) => (
  <Flexbox direction="column" gap={4}>
    <Text color={STATIC_COLORS.green[600]} size="h6" text={title} weight="semibold" />
    <Text color={STATIC_COLORS.base.black} size="body1" text={description} />
  </Flexbox>
);
