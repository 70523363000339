import React, { FC, Fragment } from 'react';

import { TLanguage } from '@lib/core/retailers/types';
import {
  IUpdateDietaryPreferenceParams,
  TDietaryPreferenceOption,
  TProfileCountryOption,
  TProfileGenderOption,
} from '@lib/core/users/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { languages } from '@lib/tools/locale/utils/consts';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { ProfileHintHeader } from '@components/web/src/atoms/Headers/ProfileHintHeader/ProfileHintHeader';
import { ProfileSectionHeader } from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProfilePictureSelection from '@components/web/src/templates/TasteId/TasteIdSettingsPage/ProfilePictureSelection/ProfilePictureSelection';
import * as S from '@components/web/src/templates/TasteId/TasteIdSettingsPage/styles';
import UserInfoForm, { IFormData } from '@components/web/src/templates/TasteId/TasteIdSettingsPage/UserInfoForm';

interface IProps {
  currentLanguage: string;
  togglePreferenceOptionsData: (TDietaryPreferenceOption & { isEnabled: boolean })[];
  formData: IFormData;
  userImage: string;
  userImageUpdateError: string;
  supportedLanguages: TLanguage[];
  shouldShowManageSection?: boolean;
  profileCountryOptions: TProfileCountryOption[];
  profileGenderOptions: TProfileGenderOption[];
  handleUserImageUpdateError: React.Dispatch<React.SetStateAction<string>>;
  handleUpdateToggleDietaryPreferenceOptionsData: ({
    slugToUpdate,
    exposureToUpdate,
  }: IUpdateDietaryPreferenceParams) => void;
  onExitBtnClick: () => void;
  onManageBtnClick: () => void;
  onPreferencesHintBtnClick: () => void;
  handleLanguageChange: (IB2CSupportedLanguages) => void;
  handleFormSubmit: (data: IFormData) => void;
  handleUserImageUpdate: (base64ImageData: string) => void;
  handleBackBtnClick?: () => void;
}

const TasteIdSettingsPage: FC<IProps> = ({
  currentLanguage = languages.ENGLISH,
  togglePreferenceOptionsData,
  formData,
  userImage,
  userImageUpdateError = '',
  supportedLanguages,
  shouldShowManageSection = false,
  profileGenderOptions,
  profileCountryOptions,
  handleUpdateToggleDietaryPreferenceOptionsData,
  onExitBtnClick,
  onManageBtnClick,
  onPreferencesHintBtnClick,
  handleLanguageChange,
  handleFormSubmit,
  handleUserImageUpdateError,
  handleUserImageUpdate,
  handleBackBtnClick,
}) => {
  const {
    settings: {
      settingsTitle,
      settingsSubTitle,
      foodPreferencesTitle,
      foodPreferencesSubTitle,
      accountTitle,
      accountSubTitle,
      manageTextDescription,
      manageBtnText,
      exitBtnText,
    },
    languageBtnGroup: { languageTitle },
  } = localeApp;

  return (
    <Flexbox isFullWidth direction="column" gap={0}>
      <BackButton handleClick={handleBackBtnClick} />
      <Flexbox isFullWidth direction="column" gap={16} padding="0 16px 32px">
        <ProfileSectionHeader description={settingsSubTitle} title={settingsTitle} />
        <S.Divider $isInvisible />
        <ProfilePictureSelection
          handleUserImageUpdate={handleUserImageUpdate}
          handleUserImageUpdateError={handleUserImageUpdateError}
          userImage={userImage}
          userImageUpdateError={userImageUpdateError}
        />
        <S.Divider />
        <UserInfoForm
          formData={formData}
          handleFormSubmit={handleFormSubmit}
          profileCountryOptions={profileCountryOptions}
          profileGenderOptions={profileGenderOptions}
        />
        <S.Divider />
        {supportedLanguages?.length >= 2 && (
          <>
            <S.Section>
              <Text color={STATIC_COLORS.gray[700]} size="body1" text={languageTitle} weight="medium" />
              <Flexbox gap={0} padding="8px 0">
                {supportedLanguages.map(supportedLang => {
                  const isActive = currentLanguage === supportedLang.code;
                  return (
                    <S.LanguageButton
                      key={supportedLang.code}
                      $isActive={isActive}
                      onClick={() => handleLanguageChange(supportedLang.code)}
                    >
                      <Text
                        color={isActive ? STATIC_COLORS.teal[900] : STATIC_COLORS.teal[700]}
                        size="body1"
                        text={supportedLang.language}
                        weight={isActive ? 'medium' : 'normal'}
                      />
                    </S.LanguageButton>
                  );
                })}
              </Flexbox>
            </S.Section>
            <S.Divider />
          </>
        )}
        <S.Section>
          <ProfileHintHeader
            subTitle={foodPreferencesSubTitle}
            title={foodPreferencesTitle}
            onHintBtnClick={onPreferencesHintBtnClick}
          />
          <Flexbox direction="column" gap={20} margin="16px 0 0">
            {togglePreferenceOptionsData.map(({ isEnabled, name, slug, exposure }, index) => {
              const shouldDisplayDivider = togglePreferenceOptionsData.length === index + 1;
              return (
                <Fragment key={index}>
                  {shouldDisplayDivider && <S.Divider $isInvisible />}
                  <ToggleButton
                    isToggled={isEnabled}
                    text={name}
                    onToggle={() =>
                      handleUpdateToggleDietaryPreferenceOptionsData({
                        exposureToUpdate: exposure,
                        slugToUpdate: slug,
                      })
                    }
                  />
                </Fragment>
              );
            })}
          </Flexbox>
        </S.Section>
        <S.Divider />
        <S.ExitSectionWrapper direction="column" gap={16}>
          <>
            <ProfileHintHeader subTitle={accountSubTitle} title={accountTitle} />
            <S.ProfileButton size="sm" text={exitBtnText} onClick={onExitBtnClick} />
          </>

          {shouldShowManageSection && (
            <>
              <ProfileHintHeader subTitle={manageTextDescription} />
              <S.ProfileButton size="sm" text={manageBtnText} onClick={onManageBtnClick} />
            </>
          )}
        </S.ExitSectionWrapper>
      </Flexbox>
    </Flexbox>
  );
};

export default TasteIdSettingsPage;
