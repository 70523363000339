import OnBoardingCard from '@components/web/src/app/Cards/OnBoardingCard/OnBoardingCard';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';

const OnBoardingPage = ({ handleCloseClick, onBoardingInfos }) => {
  return (
    <div className="onboarding-page-container">
      <div className="on-boarding-swiper-container">
        <SwiperHOC slidesPerView={1}>
          {onBoardingInfos?.map((onBoardingItem, idx) => (
            <OnBoardingCard
              key={idx}
              {...onBoardingItem}
              handleCloseClick={() =>
                idx === onBoardingInfos.length - 1 ? handleCloseClick(true) : handleCloseClick(false)
              }
            />
          ))}
        </SwiperHOC>
      </div>
    </div>
  );
};

export default OnBoardingPage;
