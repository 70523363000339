import { TokenResponse } from '@react-oauth/google';
import { FC, useLayoutEffect, useState } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { IParsedServiceTerm, IRegisterPageServiceTerms } from '@lib/core/serviceTerms/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

import LockIcon from '@components/web/src/assets/icons/lock-icon.svg';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import AuthFormServerError from '@components/web/src/organisms/Authentication/AuthFormServerError/AuthFormServerError';
import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import { AuthOptions } from '@components/web/src/templates/Login&Registration/AuthenticationPage/components/AuthOptions';
import * as S from '@components/web/src/templates/Login&Registration/AuthenticationPage/styles';

interface IProps {
  productCategory: TProductCategory;
  userCharacterData: IParsedCharacter;
  authError: string;
  isEmailAuth: boolean;
  isSocialAuthTesting?: boolean;
  isEnableTastePathAddon: boolean;
  isShowSkipAndGoToProductsAddon: boolean;
  registerPageServiceTerms: IRegisterPageServiceTerms;
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  handleFormSubmit: (data: RegistrationFormInputObject) => void;
  handleEmailAuthButtonClick: () => void;
  responseGoogle: (response: TokenResponse) => void;
  responseFacebook: (response: ISocialLoginFacebook) => void;
  navigateToCatalog: () => void;
  navigateToLoginPage: () => void;
}

export const AuthenticationPage: FC<IProps> = ({
  productCategory,
  userCharacterData,
  authError,
  isEmailAuth,
  isSocialAuthTesting,
  isEnableTastePathAddon,
  isShowSkipAndGoToProductsAddon,
  registerPageServiceTerms,
  handleOnServiceTermToggle,
  handleEmailAuthButtonClick,
  handleFormSubmit,
  responseGoogle,
  responseFacebook,
  navigateToCatalog,
  navigateToLoginPage,
}) => {
  const { productCategories } = localeCommon;
  const { title, discoverTitle, prefectCategoryForYou, skipDefaultBtn, skipTastePathBtn } =
    localeWidget.authenticationPage;
  const productCategoryText = LocaleUtils.publishedTerms[productCategories[productCategory]?.id];
  const { characterImage, characterName, characterTechnicalCaption } = userCharacterData || {};
  const skipBtnText = isEnableTastePathAddon ? skipTastePathBtn : skipDefaultBtn;

  const [startAnimation, setStartAnimation] = useState(true);

  useLayoutEffect(() => {
    // to hide confetti animation
    const timer = setTimeout(() => setStartAnimation(null), 6500);

    return () => {
      clearTimeout(timer);
      setStartAnimation(null);
    };
  }, [startAnimation]);

  return (
    <S.AuthenticationPageContainer isFullWidth direction="column" gap={0}>
      <Flexbox align="center" direction="column" gap={8} padding="30px 16px 136px">
        {startAnimation && <Confetti />}
        <S.LockIcon alt="lock icon" src={LockIcon} />
        <Text
          color={STATIC_COLORS.base.black}
          size="body1"
          text={title}
          textAlign="center"
          localeOptions={{
            productCategory,
            productCategoryText,
          }}
        />
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h3"
          text={`#${characterName}`}
          textAlign="center"
          weight="semibold"
        />
        <Text
          color={STATIC_COLORS.base.black}
          size="body1"
          text={characterTechnicalCaption}
          textAlign="center"
          weight="medium"
        />
      </Flexbox>
      <S.ContentWrapper>
        <S.Circle $zIndex={1} />
        <S.Circle $color={STATIC_COLORS.warmGray[50]} $sideOffset={-145} $top={-65} $width={400} />
        <S.Circle $side="right" $zIndex={1} />
        <S.Circle $color={STATIC_COLORS.warmGray[50]} $side="right" $sideOffset={-145} $top={-65} $width={400} />
        <S.Content isFullWidth direction="column" gap={16} margin="-120px 0 0" padding="0 16px 21px">
          <S.CharacterIcon>
            <img alt="icon" src={characterImage} />
            <S.CharacterBackground $backgroundColor={STATIC_COLORS.productColors[productCategory][50]} />
          </S.CharacterIcon>
          <Text
            color={STATIC_COLORS.base.black}
            size="body1"
            text={prefectCategoryForYou}
            textAlign="center"
            localeOptions={{
              productCategory,
              productCategoryText,
            }}
          />
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h4"
            text={discoverTitle}
            textAlign="center"
            weight="semibold"
          />
          <AuthOptions
            handleEmailAuthButtonClick={handleEmailAuthButtonClick}
            handleFormSubmit={handleFormSubmit}
            handleOnServiceTermToggle={handleOnServiceTermToggle}
            isEmailAuth={isEmailAuth}
            isSocialAuthTesting={isSocialAuthTesting}
            navigateToLoginPage={navigateToLoginPage}
            registerPageServiceTerms={registerPageServiceTerms}
            responseFacebook={responseFacebook}
            responseGoogle={responseGoogle}
          />
          {isShowSkipAndGoToProductsAddon && (
            <S.LinkButton
              handleClick={navigateToCatalog}
              size="sm"
              text={skipBtnText}
              textWeight="medium"
              variant={VH_VARIANTS.LINK}
              localeOptions={{
                productCategory,
                productCategoryText,
              }}
            />
          )}
          <AuthFormServerError authErrors={authError} />
        </S.Content>
      </S.ContentWrapper>
    </S.AuthenticationPageContainer>
  );
};
