import { useMemo } from 'react';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { createTasteJourneyData } from '@lib/core/service/utils';
import { useProductRate } from '@lib/core/users/hooks';
import { useTastePathProducts } from '@lib/tools/tastePathProducts/hooks/useTastePathProducts';

export const useTastePathJourneyData = () => {
  const { locale, productCategory } = useApp();
  const { productRateData } = useProductRate();
  const { retailerMaxLengthTastePathPerCategory } = useRetailer();

  const { tastePathData, currentTastePathCategoryId } = useTastePathProducts();

  const tastePathJourneyData = useMemo(
    () =>
      tastePathData
        ? createTasteJourneyData({
            currentTastePathCategoryId,
            locale,
            productCategory,
            productRateData,
            retailerMaxLengthTastePathPerCategory,
            tastePathData,
          }).filter(Boolean)
        : [],
    [tastePathData, productRateData, currentTastePathCategoryId, productCategory, locale],
  );

  return { tastePathJourneyData };
};
