import { useDispatch, useSelector } from 'react-redux';

import { selectIsThirdPartyConnectModalShown } from '@lib/core/service/selectors';
import { setThirdPartyConnectModalOpenScenario } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import { selectLocationListData, selectLocationListLoading } from '@lib/core/users/selectors/locationList';
import { actionUpdateLocationList } from '@lib/core/users/slices/locationList';
import { IUpdateLocationListParams } from '@lib/core/users/types';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';

export const useLocationList = () => {
  const dispatch = useDispatch();
  const { openModal } = useModals();

  const locationListData = useSelector(selectLocationListData);
  const isLocationListLoading = useSelector(selectLocationListLoading);
  const isThirdPartyConnectModalShown = useSelector(selectIsThirdPartyConnectModalShown);

  const handleUpdateLocationList = ({ retailerLocationSlug }: IUpdateLocationListParams) => {
    if (!locationListData.length && !isThirdPartyConnectModalShown.location) {
      dispatch(
        setThirdPartyConnectModalOpenScenario({
          scenario: ThirdPartyConnectModalScenario.LOCATION,
        }),
      );
      openModal(MODALS.THIRD_PARTY_CONNECT_MODAL);
    }

    dispatch(actionUpdateLocationList({ retailerLocationSlug }));
  };

  return { handleUpdateLocationList, isLocationListLoading, locationListData };
};
